import React, { useState, useEffect } from "react";
import { Radio, message } from "antd";
import styled from "styled-components";
import { useHistory } from "react-router-dom";
import axios from "axios";
import { BASE_URL } from "../../url/baseurl";
const theme = localStorage.getItem('brandColor');
const StyledButton = styled.button`
  background: ${theme};
  width: auto;
  border-radius: 20px;
  height: 35px;
  text-align: center;
  color: white;
  padding: 5px 20px;
  font-size: 18px;
  font-weight: 500;
  border-color: none;
  border: none;
  margin: 5px;
`;

function Cuzphase2_test(props) {
  const history = useHistory();

  const propsdata = props.match.params;

  const [question, setQuestion] = useState([]);
  const [question1, setQuestion1] = useState([]);
  const [count, setCount] = useState(0);
  const [previous, setPrevious] = useState(false);
  const [previous1, setPrevious1] = useState(false);
  const [userResponseData, setUserResponseData] = useState([]);
  const [count1, setCount1] = useState(0);
  const [radio, setRadio] = useState("");
  const [noData, setNoData] = useState("");
  const [quizEnd1, setquizEnd1] = useState(false);

  const [lastQcode, setLastQcode] = useState("");

  const question_initialstep_post = async () => {
    let data_send = {
      quizmessage: propsdata.quizmsg,
      topicid: localStorage.getItem("TopicId"),
      cuztopic: localStorage.getItem("CuzTopic"),
      periodcode: localStorage.getItem("PeriodCode"),
      locationid: localStorage.getItem("LocationId"),
      topiccodes:
        localStorage.getItem("TopicCodes") == "null"
          ? null
          : localStorage.getItem("TopicCodes"),
      userid: localStorage.getItem("UserID"),
      partner_email: localStorage.getItem("original_EmailUser") || null
    };
    let res = await axios.post(BASE_URL + "/api/initialstep", data_send);
    console.log("res for question api", res);
    if (res.data.code == 200) {
      setLastQcode(res.data.lastqcode);
      setUserResponseData(res.data.question);
      setQuestion(res.data.question);
      setQuestion1(res.data.question);

      let result = res.data.question.filter(
        (data) => data.Validqcode == "Valid"
      );
      result?.map((data, i) => {
        if (data.qcode === res.data.lastqcode) {
          setCount1(i);
          console.log("index==>", i);
        }
      });
    } else if (res.data.code == 400) {
      setNoData(res.data.message);
    }

    console.log("Initial res data=>>>", res.data.question);
  };

  // ------------------------- User session data -------------------------
  const [user_session_data, setUser_session_data] = useState([]);

  const user_session_data_quiz_api = async () => {
    let res = await axios.get(
      BASE_URL +
        `/api/user_session_data_quiz?userid=${localStorage.getItem("UserID")}`
    );
    console.log("res for continue quiz===>", res);
    setUser_session_data(res.data.userdata[0]);
    console.log("user res data==>", res.data.userdata[0]);
  };

  useEffect(() => {

    document.body.style.backgroundColor = '#eee';
    user_session_data_quiz_api();
    question_initialstep_post();
    
    return () => {
      document.body.style.backgroundColor = '#fff';
    }
    
  }, [props]);

  // ------------------------- Next button api function -----------------------
  const response_func = async (qcode) => {
    let data = {
      selectresponse: radio,
      qcode: qcode,
      userid: localStorage.getItem("UserID"),
      customerid: user_session_data.customerid,

      topiccodes:
        localStorage.getItem("TopicCodes") == "null"
          ? null
          : localStorage.getItem("TopicCodes"),
      topicid: localStorage.getItem("TopicId"),
      cuztopic: localStorage.getItem("CuzTopic"),
      periodcode: localStorage.getItem("PeriodCode"),
      locationid: localStorage.getItem("LocationId"),
      locationdesc: localStorage.getItem("LocationDesc"),
      countrycode: localStorage.getItem("CountryCode"),
      perioddesc: localStorage.getItem("PeriodDesc"),
      cuzdesc: localStorage.getItem("CuzDesc"),
      partner_email: localStorage.getItem("original_EmailUser") || null
    };
    let res = await axios.post(BASE_URL + "/api/responsequiz", data);
    if (res.data.code == 200) {
      console.log("Next btn click data==>", res.data.question);

      if (count + 1 <= question?.length) {
        if (data.ifparentyes !== 0) {
          setQuestion(res.data.question);
          setReverse_res_ques(res.data.question);
          setUserResponseData(res.data.question);
          setquizEnd1(res.data.quizEnd);
          setCount(count + 1);
          setPrevious(false);
        }
        if (data.ifparentno !== 0) {
          setQuestion(res.data.question);
          setReverse_res_ques(res.data.question);
          setUserResponseData(res.data.question);

          setCount(count + 1);
          setPrevious(false);
        }
        if (data.ifparentyes == 0 && data.ifparentno == 0) {
          setQuestion(res.data.question);
          setReverse_res_ques(res.data.question);
          setUserResponseData(res.data.question);

          setCount(count + 1);
          setPrevious(false);
        }
      } else {
        message.success("You have reached the end of your Topic quiz");
      }

      setRadio("");
    }
  };

  const response_func_cont = async (qcode) => {
    let data = {
      selectresponse: radio,
      qcode: qcode,
      userid: localStorage.getItem("UserID"),
      customerid: user_session_data.customerid,

      topiccodes:
        localStorage.getItem("TopicCodes") == "null"
          ? null
          : localStorage.getItem("TopicCodes"),
      topicid: localStorage.getItem("TopicId"),
      cuztopic: localStorage.getItem("CuzTopic"),
      periodcode: localStorage.getItem("PeriodCode"),
      locationid: localStorage.getItem("LocationId"),
      locationdesc: localStorage.getItem("LocationDesc"),
      countrycode: localStorage.getItem("CountryCode"),
      perioddesc: localStorage.getItem("PeriodDesc"),
      cuzdesc: localStorage.getItem("CuzDesc"),
      partner_email: localStorage.getItem("original_EmailUser") || null
    };
    let res = await axios.post(BASE_URL + "/api/responsequiz", data);
    if (res.data.code == 200) {
      console.log("Next btn click data==>", res.data.question);

      if (count1 + 1 <= question1?.length) {
        if (data.ifparentyes !== 0) {
          setReverse_res_ques(res.data.question);
          setUserResponseData(res.data.question);
          setquizEnd1(res.data.quizEnd);
          setQuestion1(res.data.question);
          setPrevious1(false);
          setCount1(count1 + 1);
        }
        if (data.ifparentno !== 0) {
          setReverse_res_ques(res.data.question);
          setUserResponseData(res.data.question);
          setQuestion1(res.data.question);

          setPrevious1(false);
          setCount1(count1 + 1);
        }
        if (data.ifparentyes == 0 && data.ifparentno == 0) {
          setReverse_res_ques(res.data.question);
          setUserResponseData(res.data.question);
          setQuestion1(res.data.question);

          setPrevious1(false);
          setCount1(count1 + 1);
        }
      } else {
        message.success("You have reached the end of your Topic quiz");
      }

      setRadio("");
    }
  };

  // ------------------------- Previous button api function -----------------------
  const [reverse_res_ques, setReverse_res_ques] = useState([]);

  const previous_button_api_fun = async (qcode) => {
    let data = {
      cuztopic: localStorage.getItem("CuzTopic"),
      topicid: parseInt(localStorage.getItem("TopicId")),
      periodcode: parseInt(localStorage.getItem("PeriodCode")),
      locationid: parseInt(localStorage.getItem("LocationId")),
      topiccodes:
        localStorage.getItem("TopicCodes") == "null"
          ? null
          : localStorage.getItem("TopicCodes"),
      userid: localStorage.getItem("UserID"),
      qcode: qcode,
      partner_email: localStorage.getItem("original_EmailUser") || null
    };
    let res = await axios.post(BASE_URL + "/api/revresponsequiz", data);
    if (res.data.code == 200) {
      setReverse_res_ques(res.data.question);
      setUserResponseData(res.data.question);
      
      setRadio("");
      setCount1(count1 - 1);
      setPrevious1(true);
       setCount(count - 1);
      setPrevious(true);
    }
  };
  

  const First_exit = async () => {
    console.log("First question exit =>");
    let data_send = {
      quizmessage: propsdata.quizmsg,
      topicid: localStorage.getItem("TopicId"),
      cuztopic: localStorage.getItem("CuzTopic"),
      periodcode: localStorage.getItem("PeriodCode"),
      locationid: localStorage.getItem("LocationId"),
      topiccodes:
        localStorage.getItem("TopicCodes") == "null"
          ? null
          : localStorage.getItem("TopicCodes"),
      userid: localStorage.getItem("UserID"),
    };
    let res = await axios.post(BASE_URL + "/api/initial_exit", data_send);
    console.log("res for question api", res);
    if (res.data.code == 200) {
    
    } else if (res.data.code == 400) {
     
    }


  };
  return (
    <>
      <div className="container">
        <div align="center" style={{ marginBottom: 10, marginTop: 10 }}>
          <StyledButton
            onClick={() => {
              console.log("question =>",question);
              console.log("count =>",count);
              console.log("count1 =>",count1);
              // if(count ==0 && count1 == 0 ){
                if(count < 1 && count1 < 1 ){
                First_exit();
              }
              history.push("/edittopic");
            }}
          >
            Exit
          </StyledButton>
        </div>
        {noData == "no data" ? (
          <div align="center" style={{ marginTop: 20 }}>
            <h3>No data available to display.</h3>
          </div>
        ) : (
          ""
        )}

        {propsdata.quizmsg == "contquiz" ? (
          <>
            {question1 &&
              question1
                .filter((data) => data.Validqcode === "Valid")
                ?.map((data, i) => {
                  if (i == count1) {
                    if (data.Validqcode === "Valid") {
                      console.log(
                       
                        "count1===>",
                        count1,
                        "i==>",
                        i,
                        "len===>",
                        question1.filter((data) => data.Validqcode == "Valid")
                          .length - 1,
                        "barr====",
                        (100 * i) /
                          (question1.filter((data) => data.Validqcode == "Valid")
                            .length -
                            1)
                      );
                      return (
                        <>
                          <div
                            className="testBorder bg-white"
                            style={{
                              border: "1px solid #dee2e6",
                              height: "300px",
                              marginBottom: "20px",
                            }}
                          >
                            <div className="question p-3">
                              <div class="progress">
                                <div
                                  class="bar"
                                  style={{
                                    width:
                                      "" +
                                      (100 * i) /
                                        (question1.filter(
                                          (data) => data.Validqcode == "Valid"
                                        ).length -
                                          1) +
                                      "%",
                                  }}
                                >
                                  <p class="percent"></p>
                                </div>
                              </div>
                              <div className="head_div"></div>
                              <div
                                className="d-flex flex-row "
                                style={{
                                  float: "left",
                                  padding: 20,
                                  width: "100%",
                                }}
                              >
                                <h3 style={{ color: theme }}>Q.</h3>
                                <h5 className="mt-1 ml-2">
                                  &nbsp;{data.qdescr}
                                </h5>
                              </div>
                              <br />
                              <br />
                              <div className="ans ml-2">
                                <div
                                  className="d-flex flex-row question-title"
                                  style={{ float: "left" }}
                                >
                                  <h3
                                    style={{
                                      color: theme,
                                      fontSize: "20px",
                                    }}
                                  >
                                    Response :&nbsp;
                                  </h3>

                                  <Radio.Group
                                    onChange={(e) => {
                                      setRadio(e.target.value);
                                      console.log("yes1 ==> ",e.target.value)
                                    }}
                                  >
                                    <Radio value={"Y"}>Yes</Radio>
                                    <Radio value={"N"}>No</Radio>
                                  </Radio.Group>
                                </div>
                                <div
                                  style={{
                                    float: "right",
                                    width: "95%",
                                    marginTop: 15,
                                  }}
                                >
                                  {/* For rendering of prev btn */}
                                  {count1 !== 0 ? (
                                    <button
                                      className="btn btn-danger"
                                      type="button"
                                      onClick={() => {
                                        // setCount1(count1 - 1);
                                        // setPrevious1(true);
                                        console.log("previou  button click");
                                        previous_button_api_fun(data.qcode);
                                        console.log("previou  api end");
                                          
                                      }}
                                    >
                                      <i className="fa fa-angle-left"></i>
                                      &nbsp; Previous
                                    </button>
                                  ) : (
                                    <span>&nbsp;&nbsp;&nbsp;</span>
                                  )}
                                  &nbsp; &nbsp;
                                  {question1.length !== count1 ? (
                                    <button
                                      className="btn btn-success"
                                      type="button"
                                      onClick={() => {
                                        console.log("next button");
                             
                                        console.log("quizEnd1 ==>",quizEnd1);
                                        radio !== ""
                                          ? response_func_cont(data.qcode)
                                          : message.info(
                                              "please select response"
                                            );
                                            
                                      }}
                                    >
                                      Next &nbsp;
                                      <i className="fa fa-angle-right"></i>
                                    </button>
                                  ) : (
                                    <span>&nbsp;&nbsp;&nbsp;</span>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="Guidance-display">
                            <strong
                              style={{
                                width: "100%",
                                float: "left",
                                fontSize: "17px",
                              }}
                            >
                              Guidance
                            </strong>{" "}
                            <span
                              className="Width100 bg-white"
                              style={{
                                border: "1px solid #dee2e6",
                                padding: "10px",
                                width: "50%",
                                float: "left",
                                fontSize: "15px",
                                fontWeight: "bold",
                              }}
                            >
                              {data.Cuzguidedesc || data.Guidancedesc}
                            </span>
                          </div>
                        </>
                      );
                    } else {
                      console.log("count for else part==>", count1);
                 
                      if (previous1) {
                        setCount1(count1 - 1);
                      } else {
                        console.log("next part of else");
                        setCount1(count1 + 1);
                        setPrevious1(false);
                      }
                    }
                  }
                })}
          </>
        ) : (
          <>
            {question &&
              question
                .filter((data) => data.Validqcode == "Valid")
                ?.map((data, i) => {
                  if (i === count) {
                    if (data.Validqcode == "Valid") {
                      console.log(
                        "count===>",
                        count,
                        "i==>",
                        i,
                        "len===>",
                        question.filter((data) => data.Validqcode == "Valid")
                          .length - 1,
                        "barr====",
                        (100 * i) /
                          (question.filter((data) => data.Validqcode == "Valid")
                            .length -
                            1)
                      );
                      return (
                        <>
                          <div
                            className="testBorder bg-white"
                            style={{
                              border: "1px solid #dee2e6",
                              height: "300px",
                              marginBottom: "20px",
                            }}
                          >
                            <div className="question p-3 ">
                              <div class="progress">
                                <div
                                  class="bar"
                                  style={{
                                    width:
                                      "" +
                                      (100 * i) /
                                        (question.filter(
                                          (data) => data.Validqcode == "Valid"
                                        ).length -
                                          1) +
                                      "%",
                                  }}
                                >
                                  <p class="percent"></p>
                                </div>
                              </div>
                              <div
                                className="d-flex flex-row question-title"
                                style={{
                                  float: "left",
                                  padding: 20,
                                  width: "100%",
                                }}
                              >
                                <h3 style={{ color: theme }}>Q.</h3>
                                <h5 className="mt-1 ml-2">
                                  &nbsp;{data.qdescr}
                                </h5>
                              </div>
                              <div className="ans ml-2">
                                <div
                                  className="d-flex flex-row question-title"
                                  style={{ float: "left" }}
                                >
                                  <h3
                                    style={{
                                      color: theme,
                                      fontSize: "20px",
                                    }}
                                  >
                                    Response :&nbsp;
                                  </h3>

                                  <Radio.Group
                                    onChange={(e) => {
                                      setRadio(e.target.value);
                                      // response_func(radio, data.qcode)
                                      console.log(
                                        "respponse user==>",
                                        e.target.value
                                      );
                                    }}
                                  >
                                    <Radio value={"Y"}>Yes</Radio>
                                    <Radio value={"N"}>No</Radio>
                                  </Radio.Group>
                                </div>
                                <div
                                  style={{
                                    float: "right",
                                    width: "95%",
                                    marginTop: 15,
                                  }}
                                >
                                  {/* For rendering of prev btn */}
                                  {count !== 0 ? (
                                    <button
                                      className="btn btn-danger"
                                      type="button"
                                      onClick={() => {
                                        // setCount(count - 1);
                                        // setPrevious(true);
                                        console.log("previou  button click");
                                        previous_button_api_fun(data.qcode);
                                      }}
                                    >
                                      <i className="fa fa-angle-left"></i>
                                      &nbsp; Previous
                                    </button>
                                  ) : (
                                    <span>&nbsp;&nbsp;&nbsp;</span>
                                  )}
                                  &nbsp; &nbsp;
                                  {question.length !== count ? (
                                    <button
                                      className="btn btn-success"
                                      type="button"
                                      onClick={() => {
                                        console.log("next button");
                                        console.log("quizEnd1 ==>",quizEnd1);
                                        radio !== ""
                                          ? response_func(data.qcode)
                                          : message.info(
                                              "please select response"
                                            );
                                      }}
                                    >
                                      Next &nbsp;
                                      <i className="fa fa-angle-right"></i>
                                    </button>
                                  ) : (
                                    <span>&nbsp;&nbsp;&nbsp;</span>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="Guidance-display mb-3">
                            <strong
                              style={{
                                width: "100%",
                                float: "left",
                                fontSize: "17px",
                              }}
                            >
                              Guidance
                            </strong>{" "}
                            <span
                              className="Width100 bg-white"
                              style={{
                                border: "1px solid #dee2e6",
                                padding: "10px",
                                width: "50%",
                                float: "left",
                                fontSize: "15px",
                                fontWeight: "bold",
                              }}
                            >
                              {data.Cuzguidedesc || data.Guidancedesc}
                            </span>
                          </div>
                        </>
                      );
                    } else {
                      console.log("count for else part==>", count);
                      if (previous) {
                        setCount(count - 1);
                      } else {
                        console.log("next part of else");
                        setCount(count + 1);
                        setPrevious(false);
                      }
                    }
                  }
                })}
          </>
        )}

        <div style={{ textAlign: "center", marginBottom: 50 }}>
          {/* {count + 1 === userResponseData?.length && count !== 0 && (
            <>
              <h6 style={{ fontSize: "20px", fontWeight: 600, color: "black" }}>
              {count} &nbsp; You have reached the end of your Topic quiz.
              </h6>
            </>
          )}
          {count1 + 1 === userResponseData?.length && count1 !== 0 && (
            <>
              <h6 style={{ fontSize: "20px", fontWeight: 600, color: "black" }}>
              {count1} &nbsp; You have reached the end of your Topic quiz.
              </h6>
            </>
          )} */}
           {quizEnd1 === true && (
            <>
              <h6 style={{ fontSize: "20px", fontWeight: 600, color: "black" }}>
              You have reached the end of your Topic quiz.
              </h6>
            </>
          )}
        </div>
      </div>

      {/* ---------- Answers of previous questions in a stack view ----------- */}

      <div
        className="container marLeft"
        style={{ float: "left", marginLeft: "6%", marginTop: "2%" }}
      >
        <strong style={{ width: "100%", float: "left", fontSize: "17px" }}>
          Previous Responses
        </strong>
        <div className="d-flex row">
          <div className="col-md-12 col-lg-12">
            {userResponseData &&
              userResponseData?.map((data, i) => {
                if (data.response !== null) {
                  return (
                    <>
                      <div className="mt-2 border1">
                        <div className="question bg-white p-3">
                          <div
                            className="d-flex flex-row question-title"
                            style={{
                              float: "left",
                              padding: 20,
                              width: "100%",
                            }}
                          >
                            <h3 style={{ color: theme }}>Q.</h3> 
                            <h5 className="mt-1 ml-2">&nbsp;{data.qdescr} </h5>
                          </div>
                          <br />
                          <br /> 

                          <div className="ans ml-2">
                            <div
                              className="d-flex flex-row question-title"
                              style={{ float: "left" }}
                            >
                              <h3
                                style={{ color: theme, fontSize: "20px" }}
                              >
                                Response :&nbsp;
                              </h3>

                              <Radio.Group
                                value={
                                  data.response == "Y"
                                    ? "Y"
                                    : data.response == "N"
                                    ? "N"
                                    : ""
                                }
                              >
                                <Radio
                                  value={
                                    data.response == "Y"
                                      ? "Y"
                                      : data.response == "N"
                                      ? "N"
                                      : ""
                                  }
                                  style={{ fontWeight: "bold", fontSize: 16 }}
                                >
                                  {data.response == "Y" ? "Yes" : "No"}
                                </Radio>
                              </Radio.Group>
                            </div>
                            <StyledButton
                              style={{
                                opacity: 0,
                                marginLeft: "83px",
                                marginTop: "12px",
                              }}
                              onClick={() => {
                                //submitSolution(data.qcode);
                              }}
                            >
                              Submit
                            </StyledButton>
                            <br />
                          </div>
                        </div>
                      </div>
                    </>
                  );
                }
              })}
          </div>
        </div>
      </div>
    </>
  );
}

export default Cuzphase2_test;
