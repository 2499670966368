import React, { useState, useEffect } from "react";
import ReactModal from "react-modal";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { message } from "antd";
import { DataGrid, GridColumns, GridRowsProp } from "@mui/x-data-grid";
import { makeStyles } from "@material-ui/styles";
import { Tooltip } from "antd";
import {
  admin_user_loc_data,
  user_session_data_admin_api,
  feature_program_api,
  add_new_loc_list,
  add_admin_user_location,
  delete_admin_user_location,
} from "../../../services/AdminProgramApi";
const theme = localStorage.getItem('brandColor');
const StyledForm = styled.form`
  display: flex;
  justify-content: center;
  //   margin-left: 20%;
  flex-direction: column;
`;

const StyledLabel = styled.label`
  flex: 1;
  margin-right: 10px;
  display: inline;
  width: 30%;
`;

const StyledFormInputDiv = styled.div`
  display: flex;
  margin-top: 4px;
  width: 100%;
`;

const FormDivStyle = {
  position: "relative",
  display: "inline",
  width: "100%",
  textAlign: "left",
};

const StyledButton = styled.button`
  background: ${theme};
  width: 100%;
  border-radius: 30px;
  height: 45px;
  text-align: center;
  color: white;
  font-size: 16px !important;
  font-weight: 500;
  border-color: none;
  border: none;
  margin-top: 25px;
  padding: 5px 10px;
`;

const StyledTable = styled.table`
  width: 100%;
`;

const StyledTableHeader = styled.tr`
  background: ${theme};
  font-weight: bold;
  color: #ffffff;
  font-family: Nunito;
  font-style: normal;
`;

const ScrollTableHeader = styled.tr`
  background: ${theme};
  font-weight: bold;
  color: #ffffff;
  font-family: Nunito;
  font-style: normal;
  position: sticky;
  top: 0;
`;
const StyledTableRow = styled.tr`
  border-bottom: 1px solid #9d9d9d;
`;

const StyledTableHeaderData = styled.th`
  font-weight: bold;
  padding 5px;
  font-family: Nunito;
  font-style: normal;
`;

const StyledButton1 = styled.button`
  background: ${theme};
  width: 250px;
  border-radius: 20px;
  height: 35px;
  text-align: center;
  font-size: 18px !important;

  color: white;
  font-weight: 500;
  border-color: none;
  border: none;
  margin-top: 15px;
  padding: 5px 10px;
`;

const useStyles = makeStyles({
  root: {
    "& .super-app-theme--cell": {
      backgroundColor: "rgba(224, 183, 60, 0.55)",
      color: "#1a3e72",
      fontWeight: "600",
    },
    "& .super-app.negative": {
      backgroundColor: "rgba(157, 255, 118, 0.49)",
      color: "#1a3e72",
      fontWeight: "600",
    },
    "& .super-app.positive": {
      // backgroundColor: '#d47483',
      border: "1px solid #ff8c24",
      color: "#1a3e72",
      fontWeight: "600",
    },
    "& .super-app-theme--header": {
      backgroundColor: "#ff8c24",
      border: "1px solid #ff8c24",
      color: "#FFFFFF",
      fontWeight: "600",
    },
  },
});




const Admin_User_Location = ({ match }) => {
  const history = useHistory();
  const classes = useStyles();
  console.log("propdata", match);

  const [add_admin_user_loc_modal, setAdd_admin_user_loc_modal] =
    useState(false);
  const [delete_admin_user_loc_modal, setDelete_admin_user_loc_modal] =
    useState(false);
  const [userEmail, setUserEmail] = useState("");
  const [location, setLocation] = useState("");
  const [locationId, setLocationId] = useState("");
  const [userAdminLocData, setUserAdminLocData] = useState([]);

  const [addEmailList, setAddEmailList] = useState([]);
  const [addLocationList, setAddLocationList] = useState([]);

  const [formErrors, setFormErrors] = useState([]);
  const [pageSize, setPageSize] = useState(100);

  const customerid = match.params.customerid;

  // ----------------------- Validation -------------------------
  const handleValidation = () => {
    let formErrors = {};
    let formIsValid = true;

    if (!userEmail) {
      formIsValid = false;
      formErrors["userEmail_error"] = "User Email is required.";
    }
    if (!locationId) {
      formIsValid = false;
      formErrors["locationId_error"] = "Location is required.";
    }

    setFormErrors(formErrors);
    return formIsValid;
  };

  // ------- Reset Fields Function --------
  const resetFields = () => {
    setUserEmail("");
    setLocation("");
    setLocationId("");
    setFormErrors([]);
  };

  const DeleteAdminUserButton = ({ data, setUserEmail,setLocationId,  setDelete_admin_user_loc_modal }) => {
    const handleDeleteClick = () => {
      setUserEmail(data.useremail);
      setLocationId(data.locationid);
      setDelete_admin_user_loc_modal(true);
    };
  
    return (
      <a onClick={handleDeleteClick}>
        <i className="fas fa-trash" style={{ color: "red" }} aria-hidden="true"></i>
      </a>
    );
  };

  
  let SNo = 1;
  const rowsWithSerialNumber = userAdminLocData.map((userAdminLocData) => {
    return {
      ...userAdminLocData,
      SNo: SNo++,
    };
  });


  const rows1  = rowsWithSerialNumber;

  const columnsUsers: GridColumns = [

    {
      field: "SNo",
      headerName: "S.No",
      // minwidth: 200,
      flex: 0.3,
     // type: "email",
      align: "center",
      headerAlign: "center",
      headerClassName: "super-app-theme--header",
      // renderCell: (params) => (
      //   <div>{params.value + 1}</div>
      // ),
    },
    
    {
      field: "useremail",
      headerName: "User Email",
      // minwidth: 200,
      flex: 1,
     // type: "email",
      align: "center",
      headerAlign: "center",
      headerClassName: "super-app-theme--header",
      renderCell: (params) => {
        return (
          <Tooltip
           
            title={params.value}
          >
            {/* {params.value?.slice(0, 12)}... */}
            {params.value?.length > 30
                ? `${params.value.substring(0, 30)}...`
                : params.value}
          </Tooltip>
        );
      },
      
    },
    {
      field: "locationdesc",
      headerName: "Location",
      // width: 200,
     // type: "number",
      flex: 1,
      align: "center",
      headerAlign: "center",
      headerClassName: "super-app-theme--header",
      renderCell: (params) => {
        return (
          <Tooltip
           
            title={params.value}
          >
            {/* {params.value?.slice(0, 12)}... */}
            {params.value?.length > 30
                ? `${params.value.substring(0, 30)}...`
                : params.value}
          </Tooltip>
        );
      },
    },
    {
      field: "Action",
      headerName: "Action",
      headerClassName: "super-app-theme--header",
      flex: 0.6,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => (
        <div>
          <DeleteAdminUserButton
            data={params.row}
            setUserEmail={setUserEmail}
            setLocationId={setLocationId}
            setDelete_admin_user_loc_modal={setDelete_admin_user_loc_modal}
          />
        </div>
      ),
    },
    
    
  ];






  const tableData = () => {
    var data1 = {
      userdata: JSON.parse(localStorage.getItem("UTData")),
      customerid: customerid,
    };
    admin_user_loc_data(data1)
      .then((res3) => {
        console.log(res3);
        if (res3.data.code === 200) {
          setUserAdminLocData(res3.data.Eligiblelocs);
        } else {
          //message.error(res3);
          message.error(res3.data.message);
        }
      })
      .catch((err) => {
        console.log("error aud--->>", err);
      });
  };

  // user_session_data_admin api func call
  const [feature_prog_admin_func_data, setFeature_prog_admin_func_data] =
    useState([]);
  const [admn_custId, setAdmn_custId] = useState();

  const user_session_data_admin_func = async () => {
    user_session_data_admin_api(localStorage.getItem("UserID"))
      .then((res) => {
        if (res.data.code == 200) {
          console.log("user data admin res===>", res.data);
          setAdmn_custId(res.data.userdata[0].customerid);

          let data = {
            customerid: res.data.userdata[0].customerid,
            userdata: res.data.userdata,
          };

          tableData();

          feature_program_api(data)
            .then((res2) => {
              if (res2.data.code == 200) {
                console.log("feature res2 data==>", res2.data);
                setFeature_prog_admin_func_data(res2.data.admfunction);

                var Admtypelist = [];

                Admtypelist = res2.data.Admtypelist;
                console.log("=======>", Admtypelist);

                var data2 = {
                  userdata: res.data.userdata,
                  admintypelist: Admtypelist,
                  customerid: customerid,
                };
                add_new_loc_list(data2)
                  .then((res4) => {
                    console.log(res4);
                    if (res4.data.code === 200) {
                      setAddLocationList(res4.data.Activelocs);
                      setAddEmailList(res4.data.Availableusers);

                      console.log(
                        "Add List ==>",
                        addLocationList,
                        addEmailList
                      );
                    } else {
                      message.error(res4);
                    }
                  })
                  .catch((err) => {
                    console.log("error aud--->>", err);
                  });
              } else {
                message.error(res2);
              }
            })
            .catch((err) => {
              console.log("error frd--->>", err);
            });
        } else {
          message.error(res);
        }
      })
      .catch((err) => {
        console.log("error usd===>", err);
      });
  };

  useEffect(() => {
    user_session_data_admin_func();
  }, []);

  //---------------- Add Location API Function -------------------------

  const addAdmin_User_Location = () => {
    for (let i = 0; i < addLocationList?.length; i++) {
      if (addLocationList[i]?.locationid == locationId) {
        setLocation(addLocationList[i]?.locationdesc);
        console.log(addLocationList[i]?.locationdesc);
        console.log(locationId);
      }
    }
    add_admin_user_location({
      selection: "addadminloc",
      customerid: parseInt(customerid),
      useremail: userEmail,
      locationid: parseInt(locationId),
      locationdesc: location,
      partner_email: localStorage.getItem("original_EmailUser") || null
    })
      .then((res) => {
        if (res.data.code === 200) {
          setAdd_admin_user_loc_modal(false);
          resetFields();
          message.success("User/Admin Location added successfully");
          console.log("res User/Admin Add Loc =>", res.data);
          user_session_data_admin_func();
        } else if (res.data.message === "alreadyuserloc") {
          message.error("This user is already set up at this location.");
        } else {
          message.error(res.data.message);
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  //---------------- Delete Location API Function -------------------------

  const deleteAdmin_User_Location = () => {
    delete_admin_user_location({
      selection: "deladminloc",
      customerid: parseInt(customerid),
      useremail: userEmail,
      locationid: parseInt(locationId),
    })
      .then((res) => {
        if (res.data.code === 200) {
          setDelete_admin_user_loc_modal(false);
          message.success("Location deleted successfully");
          console.log("res Delete Loc =>", res.data);
          user_session_data_admin_func();
        } else {
          message.error(res.data.message);
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  return (
    <div className="container-fluid">
      <div className="mt-4 row text-center" align="center">
        <div className="col-6 Width100">
          <StyledButton1
            onClick={() => {
              history.push("/adminfunctionmodal");
            }}
          >
            Return to Admin Menu
          </StyledButton1>
        </div>
        <div className="col-6 Width100">
          <StyledButton1
            onClick={() => {
              setAdd_admin_user_loc_modal(true);
            }}
          >
            Add Admin/User Location
          </StyledButton1>
        </div>
      </div>

      <div>
        {/* --------------- Add Modal ------------ */}
        <ReactModal className="ModalStyle1 Width90" isOpen={add_admin_user_loc_modal}>
          <div>
            {" "}
            <StyledForm>
              <div align="center" className="mb-3">
                <h4>Add Admin/User Location</h4>
              </div>
              <StyledFormInputDiv>
                <div style={FormDivStyle}>
                  <StyledLabel htmlFor="qdesc">User Email</StyledLabel>
                </div>
                <div style={{ width: "100%", alignSelf: "center" }}>
                  <select
                    style={{
                      width: "100%",
                      alignItems: "center",
                      border: "1px solid black",
                    }}
                    value={userEmail}
                    onChange={(e) => {
                      setUserEmail(e.target.value);
                      console.log(e.target.value);
                    }}
                  >
                    <option value="" selected disabled hidden>
                      Select User Email
                    </option>
                    {addEmailList &&
                      addEmailList?.map((data, index) => {
                        return (
                          <option value={data} label={data}>
                            {data}
                          </option>
                        );
                      })}
                  </select>
                </div>
              </StyledFormInputDiv>
              {formErrors.userEmail_error && (
                <div className="offset-6 mt-2">
                  <p
                    style={{
                      color: "red",
                      fontSize: ".8rem",
                      marginTop: "0px",
                    }}
                  >
                    {formErrors.userEmail_error}
                  </p>
                </div>
              )}
              <StyledFormInputDiv>
                <div style={FormDivStyle}>
                  <StyledLabel htmlFor="qdesc">Location</StyledLabel>
                </div>
                <div style={{ width: "100%", alignSelf: "center" }}>
                  <select
                    style={{
                      width: "100%",
                      alignItems: "center",
                      border: "1px solid black",
                    }}
                    value={locationId}
                    onChange={(e) => {
                      setLocationId(e.target.value);
                      console.log(e.target.value);
                    }}
                  >
                    <option value="" selected disabled hidden>
                      Select Location
                    </option>
                    {addLocationList &&
                      addLocationList?.map((data, index) => {
                        return (
                          <option
                            value={data.locationid}
                            label={data.locationdesc}
                          >
                            {data.locationdesc}
                          </option>
                        );
                      })}
                  </select>
                </div>
              </StyledFormInputDiv>
              {formErrors.locationId_error && (
                <div className="offset-6 mt-2">
                  <p
                    style={{
                      color: "red",
                      fontSize: ".8rem",
                      marginTop: "0px",
                    }}
                  >
                    {formErrors.locationId_error}
                  </p>
                </div>
              )}
            </StyledForm>
          </div>
          <div align="center">
            <StyledButton
              style={{
                width: "60%",
                height: 30,
                marginTop: 50,
              }}
              onClick={() => {
                if (handleValidation()) {
                  addAdmin_User_Location();
                } else {
                  message.error("Please complete all data");
                }
              }}
            >
              Save New Admin/User Location
            </StyledButton>
            <StyledButton
              style={{
                width: "30%",
                height: 30,
                backgroundColor: "red",
                marginLeft: 10,
              }}
              onClick={() => {
                setAdd_admin_user_loc_modal(false);
                resetFields();
              }}
            >
              Cancel
            </StyledButton>
          </div>
        </ReactModal>
      </div>

      <div>
        {/* --------------- Delete Details Modal ------------ */}
        <ReactModal className="ModalStyle1 Width90" isOpen={delete_admin_user_loc_modal}>
          <div>
            <div align="center" className="mb-3">
              <h4>
                Are you sure you want to remove this admin/user's location
                privileges?
              </h4>
              <StyledButton
                style={{ width: "30%", height: 30, marginTop: 50 }}
                onClick={() => deleteAdmin_User_Location()}
              >
                Yes
              </StyledButton>
              <StyledButton
                style={{
                  width: "30%",
                  height: 30,
                  backgroundColor: "red",
                  marginLeft: 10,
                }}
                onClick={() => {
                  setDelete_admin_user_loc_modal(false);
                }}
              >
                Cancel
              </StyledButton>
            </div>
          </div>
        </ReactModal>
      </div>



      <div
                  style={{ height: '80vh', width: "100%", marginTop: 15 }}
                  className={classes.root}
                >
      <DataGrid
                    sx={{
                      ".MuiDataGrid-columnHeaders": {
                        backgroundColor: "#ff8c24",
                      },
                      "&.MuiDataGrid-root": {
                        border: "none",
                      },
                    }}
                    rows={rows1}
                    getRowId={(r) => r.id}
                    id="_id"
                    columns={columnsUsers}
                    // initialState={{

                    //   pagination: {
                    //     pageSize: 10,
                    //   },
                    // }}
                    pageSize={pageSize}
                    onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                    rowsPerPageOptions={[ 100]}
                    pagination
                    hideFooterPagination={ rows1.length <= pageSize}
                  //  processRowUpdate={processRowUpdate}
                    experimentalFeatures={{ newEditingApi: true }}
                  />

</div>



      {/* <div className=" mt-5 table-responsive tableFixHeadXLarge">
        <StyledTable>
          <thead>
            <ScrollTableHeader>
              <StyledTableHeaderData>S.No</StyledTableHeaderData>
              <StyledTableHeaderData style={{ paddingLeft: 15 }}>
                User Email
              </StyledTableHeaderData>
              <StyledTableHeaderData style={{ paddingLeft: 15 }}>
                Location
              </StyledTableHeaderData>
              <StyledTableHeaderData>Action</StyledTableHeaderData>
            </ScrollTableHeader>
          </thead>
          <tbody>
            {userAdminLocData &&
              userAdminLocData?.map((data, index) => (
                <StyledTableRow>
                  <td style={{ color: "black" }}>{index + 1}</td>
                  <td style={{ color: "black" }}>{data.useremail}</td>
                  <td style={{ color: "black" }}>{data.locationdesc}</td>
                  <td>
                    <a
                      onClick={() => {
                        setUserEmail(data.useremail);
                        setLocationId(data.locationid);
                        setDelete_admin_user_loc_modal(true);
                      }}
                    >
                      <i
                        class="fas fa-trash"
                        style={{ color: "red" }}
                        aria-hidden="true"
                      ></i>
                    </a>
                  </td>
                </StyledTableRow>
              ))}
          </tbody>
        </StyledTable>
      </div> */}
    </div>
  );
};

export default Admin_User_Location;
