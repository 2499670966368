import React, { useState, useEffect } from "react";
import ReactModal from "react-modal";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { message } from "antd";
import { DataGrid, GridColumns, GridRowsProp } from "@mui/x-data-grid";
import { makeStyles } from "@material-ui/styles";
import { Tooltip } from "antd";
import {
  sectionselect_usertopperm_api,
  user_session_data_admin_api,
  selection_changepriv_api,
  selection_deleteuser_api,
  Add_User_to_LT,
  sectionselect_topiclocs_api,
  selection_newtoploc_api,
} from "../../../services/AdminProgramApi";
const theme = localStorage.getItem('brandColor');
const StyledForm = styled.form`
  display: flex;
  justify-content: center;
  flex-direction: column;
`;

const StyledLabel = styled.label`
  flex: 1;
  margin-right: 10px;
  display: inline;
  width: 30%;
`;

const StyledFormInputDiv = styled.div`
  display: flex;
  margin-top: 4px;
  width: 100%;
`;

const FormDivStyle = {
  position: "relative",
  display: "inline",
  width: "100%",
};

const StyledButton = styled.button`
  background: ${theme};
  width: 100%;
  border-radius: 30px;
  height: 45px;
  text-align: center;
  color: white;
  font-size: 16px !important;
  font-weight: 500;
  border-color: none;
  border: none;
  margin-top: 25px;
  padding: 5px 10px;
`;

const StyledTable = styled.table`
  width: 100%;
`;

const StyledTableHeader = styled.tr`
  background: ${theme};
  font-weight: bold;
  color: #ffffff;
  font-family: Nunito;
  font-style: normal;
`;

const ScrollTableHeader = styled.tr`
  background: ${theme};
  font-weight: bold;
  color: #ffffff;
  font-family: Nunito;
  font-style: normal;
  position: sticky;
  top: 0;
`;
const StyledTableRow = styled.tr`
  border-bottom: 1px solid #9d9d9d;
`;

const StyledTableHeaderData = styled.th`
  font-weight: bold;
  padding 5px;
  font-family: Nunito;
  font-style: normal;
`;

const StyledButton1 = styled.button`
  background: ${theme};
  width: 250px;
  border-radius: 20px;
  height: 35px;
  text-align: center;
  font-size: 18px !important;

  color: white;
  font-weight: 500;
  border-color: none;
  border: none;
  margin-top: 15px;
  padding: 5px 10px;
`;

const useStyles = makeStyles({
  root: {
    "& .super-app-theme--cell": {
      backgroundColor: "rgba(224, 183, 60, 0.55)",
      color: "#1a3e72",
      fontWeight: "600",
    },
    "& .super-app.negative": {
      backgroundColor: "rgba(157, 255, 118, 0.49)",
      color: "#1a3e72",
      fontWeight: "600",
    },
    "& .super-app.positive": {
      // backgroundColor: '#d47483',
      border: "1px solid #ff8c24",
      color: "#1a3e72",
      fontWeight: "600",
    },
    "& .super-app-theme--header": {
      backgroundColor: "#ff8c24",
      border: "1px solid #ff8c24",
      color: "#FFFFFF",
      fontWeight: "600",
    },
  },
});



const User_Topic_Permissions = ({ match }) => {
  const history = useHistory();
  const classes = useStyles();
  console.log("propdata", match);

  const [add_user_modal, setAdd_user_modal] = useState(false);
  const [delete_modal, setDelete_modal] = useState(false);
  const [edit_user_tpc_pmn_modal, setEdit_user_tpc_pmn_modal] = useState(false);
  const [topic, setTopic] = useState("");
  const [location, setLocation] = useState("");
  const [userEmail, setUserEmail] = useState("");
  const [privilege, setPrivilege] = useState("");

  const [formErrors, setFormErrors] = useState([]);

  // --------------------- Sorting Hooks ----------------------
  const [sortBySno, setSortBySno] = useState(true);
  const [sortByTopic, setSortByTopic] = useState(false);
  const [sortByLocation, setSortByLocation] = useState(false);
  const [sortByUserEmail, setSortByUserEmail] = useState(false);
  const [sortRev, setSortRev] = useState(false);

  // -------------------- Filter Hooks-----------------------

  const [data2filter, setData2filter] = useState([]);

  const [topic_id, setTopic_id] = useState("");
  const [filter_topic, setFilter_topic] = useState("");
  const [filter_location, setFilter_location] = useState("");
  const [filter_email, setFilter_email] = useState("");
  const [pageSize, setPageSize] = useState(100);

  // ---------------------------Filter Function-------------------------------

  const filter_Function = () => {
    let filter_data = [];

    if (topic_id !== "") {
      filter_data = [
        ...filter_data,
        ...user_topic_data.filter((item) =>
          String(item.topicid).toLowerCase().includes(topic_id?.toLowerCase())
        ),
      ];
    }

    if (filter_topic !== "") {
      filter_data = [
        ...filter_data,
        ...user_topic_data.filter((item) =>
          String(item.cuzdesc)
            .toLowerCase()
            .includes(filter_topic?.toLowerCase())
        ),
      ];
    }

    if (filter_location !== "") {
      filter_data = [
        ...filter_data,
        ...user_topic_data.filter((item) =>
          String(item.locationdesc)
            .toLowerCase()
            .includes(filter_location?.toLowerCase())
        ),
      ];
    }

    if (filter_email !== "") {
      filter_data = [
        ...filter_data,
        ...user_topic_data.filter((item) =>
          String(item.useremail)
            .toLowerCase()
            .includes(filter_email?.toLowerCase())
        ),
      ];
    }

    setUser_topic_data(filter_data.length != 0 ? filter_data : data2filter);
    console.log(user_topic_data);
  };

  // ----------------------- Validation -------------------------
  const handleValidation = () => {
    let formErrors = {};
    let formIsValid = true;
    if (!topicid) {
      formIsValid = false;
      formErrors["topicid_error"] = "Topic is required.";
    }
    if (!locationid) {
      formIsValid = false;
      formErrors["locationid_error"] = "Location is required.";
    }
    if (!userEmail) {
      formIsValid = false;
      formErrors["userEmail_error"] = "User Email is required.";
    }
    if (!privilege) {
      formIsValid = false;
      formErrors["privilege_error"] = "Privilege is required.";
    }

    setFormErrors(formErrors);
    return formIsValid;
  };

  const resetFields = () => {
    setFormErrors([]);
    setLocationid();
    setTopicid();
    setUserEmail("");
    setPrivilege("");
  };

  const customerid = match.params.customerid;

  const [user_topic_data, setUser_topic_data] = useState([]);

  const user_session_data_admin_api_func = async () => {
    user_session_data_admin_api(localStorage.getItem("UserID"))
      .then((res) => {
        if (res.data.code == 200) {
          let data = {
            customerid: customerid,
            userdata: res.data.userdata,
          };
          sectionselect_usertopperm_api(data)
            .then((res) => {
              if (res.data.code == 200) {
                console.log("sectionselect_usertopperm_api res==>", res.data);
                // setUser_topic_data(res.data.Usertoploc)
                setData2filter(res.data.Usertoploc);
                console.log("Filter array data", res.data.Usertoploc);
              } else {
                message.error("Something went wrong");
              }
              if (sortBySno) {
                setUser_topic_data(
                  sortRev ? res.data.Usertoploc?.reverse() : res.data.Usertoploc
                );
              } else if (sortByTopic) {
                setUser_topic_data(
                  sortRev
                    ? res.data.Usertoploc?.sort((a, b) => {
                      const x = a.cuzdesc.toUpperCase();
                      const y = b.cuzdesc.toUpperCase();
                      return x === y ? 0 : x > y ? 1 : -1;
                    })?.reverse()
                    : res.data.Usertoploc?.sort((a, b) => {
                      const x = a.cuzdesc.toUpperCase();
                      const y = b.cuzdesc.toUpperCase();
                      return x === y ? 0 : x > y ? 1 : -1;
                    })
                );
              } else if (sortByLocation) {
                setUser_topic_data(
                  sortRev
                    ? res.data.Usertoploc?.sort((a, b) => {
                      const x = a.locationdesc.toUpperCase();
                      const y = b.locationdesc.toUpperCase();
                      return x === y ? 0 : x > y ? 1 : -1;
                    })?.reverse()
                    : res.data.Usertoploc?.sort((a, b) => {
                      const x = a.locationdesc.toUpperCase();
                      const y = b.locationdesc.toUpperCase();
                      return x === y ? 0 : x > y ? 1 : -1;
                    })
                );
              } else if (sortByUserEmail) {
                setUser_topic_data(
                  sortRev
                    ? res.data.Usertoploc?.sort((a, b) => {
                      const x = a.useremail.toUpperCase();
                      const y = b.useremail.toUpperCase();
                      return x === y ? 0 : x > y ? 1 : -1;
                    })?.reverse()
                    : res.data.Usertoploc?.sort((a, b) => {
                      const x = a.useremail.toUpperCase();
                      const y = b.useremail.toUpperCase();
                      return x === y ? 0 : x > y ? 1 : -1;
                    })
                );
              } else {
                setUser_topic_data(res.data.Usertoploc);
              }
            })
            .catch((err) => {
              console.log("sectionselect_usertopperm_api error==>", err);
            });
        }
      })
      .catch((err) => {
        console.log("user_session_data_admin_api error==>", err);
      });
  };

  useEffect(() => {
    user_session_data_admin_api_func();
  }, [
    customerid,
    sortBySno,
    sortByTopic,
    sortRev,
    sortByLocation,
    sortByUserEmail,
  ]);

  // -------------------selection_changepriv_api func call-------------------
  const [topicid, setTopicid] = useState();
  const [locationid, setLocationid] = useState();

  const selection_changepriv_api_func = async () => {
    let data = {
      useremail: userEmail,
      topicid: topicid,
      locationid: locationid,
      privilege: privilege,
      partner_email: localStorage.getItem("original_EmailUser") || null
    };
    selection_changepriv_api(data)
      .then((res) => {
        if (res.data.code == 200) {
          user_session_data_admin_api_func();
          setEdit_user_tpc_pmn_modal(false);
          message.success("Updated Successfully!");
          resetFields();
        } else message.error(res.data.message);
      })
      .catch((err) => {
        console.log("selection_changepriv_api error==>", err);
      });
  };
  // ------------------selection_deleteuser_api func call---------------

  const selection_deleteuser_api_func = async () => {
    let data = {
      useremail: userEmail,
      topicid: topicid,
      locationid: locationid,
    };
    selection_deleteuser_api(data)
      .then((res) => {
        if (res.data.code == 200) {
          message.success("Succssesfuly deleted!");
          user_session_data_admin_api_func();
          setDelete_modal(false);
          resetFields();
        } else {
          message.error(res.data.message);
        }
      })
      .catch((err) => {
        console.log("selection_deleteuser_api error==>", err);
      });
  };

  // ----------------------selection_addemail_api fun call---------------------

  const selection_addemail_api_func = async () => {
    let data = {
      useremail: userEmail,
      topicid: parseInt(topicid),
      locationid: parseInt(locationid),
      privilege: privilege,
      customerid: parseInt(customerid),
      partner_email: localStorage.getItem("original_EmailUser") || null
    };
    Add_User_to_LT(data)
      .then((res) => {
        if (res.data.code == 200) {
          message.success("Added Successfully!");
          user_session_data_admin_api_func();
          setAdd_user_modal(false);
          resetFields();
        } else if (
          res.data.code == 400 &&
          res.data.message == "nolocationemail"
        ) {
          message.error(
            `This email must have permission for this location before it can be assigned to this topic. Please see 'Edit Admin/User Locations'.`
          );
        } else if (res.data.code == 400 && res.data.message == "notwithall") {
          message.error(
            `You can not add a user to a specific location and topic when the user has permission for All Locations. Please see 'Edit Admin/User'.`
          );
        } else if (res.data.code == 400 && res.data.message == "Userinfile") {
          message.error(
            "This user is already set up for this location and topic."
          );
        }
        else if(res.data.code == 400 && res.data.message == "notfound"){
          message.error(
            "Customer admin record not found"
          );
        }
        else {
          message.error(
            `This Location and Topic combination is invalid. Please see 'Edit Topic Locations'.`
          );
        }
      })
      .catch((err) => {
        message.error(
          `This Location and Topic combination is invalid. Please see 'Edit Topic Locations'.`
        );
        console.log("selection_addemail_api error===>", err);
      });
  };

  // --------------------sectionselect_topiclocs_api func call------
  const [topiclist, setTopiclist] = useState([]);

  const sectionselect_topiclocs_api_func = async () => {
    sectionselect_topiclocs_api(customerid)
      .then((res) => {
        if (res.data.code == 200) {
          console.log("sectionselect_topiclocs_api res===>", res.data);
          setTopiclist(res.data.Topiclist);
        }
      })
      .catch((err) => {
        console.log("sectionselect_topiclocs_api error==>", err);
      });
  };

  useEffect(() => {
    sectionselect_topiclocs_api_func();
  }, []);

  // ---------------------selection_newtoploc_api func call------------------

  const [eligibletopics, setEligibletopics] = useState([]);
  const [Eligiblelocation, setEligiblelocation] = useState([]);

  const selection_newtoploc_api_func = async () => {
    let data = {
      customerid: customerid,
      Topiclist: topiclist,
      userdata: JSON.parse(localStorage.getItem("UTData"))
    };
    selection_newtoploc_api(data)
      .then((res) => {
        if (res.data.code == 200) {
          console.log("selection_newtoploc_api res==>", res.data);
          setEligiblelocation(res.data.Eligiblelocation);
          setEligibletopics(res.data.Eligibletopics);
        } else {
          message.error("Something went wrong");
        }
      })
      .catch((err) => {
        console.log("selection_newtoploc_api error==>", err);
      });
  };

  function sortTopics(a, b) {
    // a should come before b in the sorted order
    if (a.cuzdesc < b.cuzdesc) {
      return -1;
      // a should come after b in the sorted order
    } else if (a.cuzdesc > b.cuzdesc) {
      return 1;
      // and and b are the same
    } else {
      return 0;
    }
  }

  function sortLocations(a, b) {
    // a should come before b in the sorted order
    if (a.locationdesc < b.locationdesc) {
      return -1;
      // a should come after b in the sorted order
    } else if (a.locationdesc > b.locationdesc) {
      return 1;
      // and and b are the same
    } else {
      return 0;
    }
  }




  const EditTopicButton = ({
    data,
    setTopic,
    setLocation,
    setUserEmail,
    setPrivilege,
    setTopicid,
    setLocationid,
    setEdit_user_tpc_pmn_modal,
  }) => {
    const handleEditClick = () => {
      setTopic(data.cuzdesc);
      setLocation(data.locationdesc);
      setUserEmail(data.useremail);
      setPrivilege(data.privilege);
      setTopicid(data.topicid);
      setLocationid(data.locationid);

      setEdit_user_tpc_pmn_modal(true);
    };

    return (
      <a onClick={handleEditClick}>
        <i class="fas fa-pencil-alt" aria-hidden="true"></i>
      </a>
    );
  };


  const DeleteTopicButton = ({ data, setUserEmail, setTopicid, setLocationid, setPrivilege, setDelete_modal }) => {
    const handleDeleteClick = () => {
      setDelete_modal(true);
      setUserEmail(data.useremail);
      setTopicid(data.topicid);
      setLocationid(data.locationid);
      setPrivilege(data.privilege);
    };

    return (
      <a onClick={handleDeleteClick}>
        <i
          class="fas fa-trash"
          style={{ color: "red" }}
          aria-hidden="true"
        ></i>
      </a>
    );
  };


  let SNo = 1;
  const rowsWithSerialNumber = user_topic_data?.map((user_topic_data) => {
    return {
      ...user_topic_data,
      SNo: SNo++,
    };
  });


  const rows1 = rowsWithSerialNumber;



  const columnsUsers: GridColumns = [

    {
      field: "SNo",
      headerName: "S.No",
      // minwidth: 200,
      flex: 0.2,
      // type: "email",
      align: "center",
      headerAlign: "center",
      headerClassName: "super-app-theme--header",
      // renderCell: (params) => (
      //   <div>{params.value + 1}</div>
      // ),
    },

    {
      field: "locationdesc",
      headerName: "Location",
      // minwidth: 200,
      flex: 0.6,
      // type: "email",
      align: "start",
      headerAlign: "center",
      headerClassName: "super-app-theme--header",
      renderCell: (params) => {
        return (
          <Tooltip
           
            title={params.value}
          >
            <div style={{
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
              width: "100%"
            }}>
              {params.value}
            </div>
            {/* {params.value?.slice(0, 12)}... */}
            {/* {params.value?.length > 30
                ? `${params.value.substring(0, 30)}...`
                : params.value} */}
          </Tooltip>
        );
      },

    },
    {
      field: "cuzdesc",
      headerName: "Topic",
      // width: 200,
      // type: "number",
      flex: 1,
      align: "start",
      headerAlign: "center",
      headerClassName: "super-app-theme--header",
      renderCell: (params) => {
        return (
          <Tooltip
           
            title={params.value}
          >
            <div style={{
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
              width: "100%"
            }}>
              {params.value}
            </div>
            {/* {params.value?.slice(0, 12)}... */}
            {/* {params.value?.length > 30
                ? `${params.value.substring(0, 30)}...`
                : params.value} */}
          </Tooltip>
        );
      },
    },
    {
      field: "useremail",
      headerName: "User Email",
      headerClassName: "super-app-theme--header",
      flex: 0.6,
      align: "start",
      headerAlign: "center",
      renderCell: (params) => {
        return (
          <Tooltip
           
            title={params.value}
          >
            <div style={{
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
              width: "100%"
            }}>
              {params.value}
            </div>
            {/* {params.value?.slice(0, 12)}... */}
            {/* {params.value?.length > 30
                ? `${params.value.substring(0, 30)}...`
                : params.value} */}
          </Tooltip>
        );
      },
    },
    {
      field: "privilege",
      headerName: "Privilege",
      type: "text",
      headerClassName: "super-app-theme--header",
      // width: 200,
      flex: 0.5,

      align: "center",
      headerAlign: "center",
      renderCell: (params) => {
        return (
          <Tooltip
           
            title={params.value}
          >
            {/* {params.value?.slice(0, 12)}... */}
            {params.value?.length > 8
                ? `${params.value.substring(0, 8)}...`
                : params.value}
          </Tooltip>
        );
      },

    },


    {
      field: "Action",
      headerName: "Action",
      flex: 0.3,
      align: "center",
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      renderCell: (params) => (
        <div>
          <EditTopicButton
            data={params.row}
            setTopic={setTopic}
            setLocation={setLocation}
            setUserEmail={setUserEmail}
            setPrivilege={setPrivilege}
            setTopicid={setTopicid}
            setLocationid={setLocationid}
            setEdit_user_tpc_pmn_modal={setEdit_user_tpc_pmn_modal}
          />
          &nbsp; &nbsp;
          <DeleteTopicButton
            data={params.row}
            setUserEmail={setUserEmail}
            setTopicid={setTopicid}
            setLocationid={setLocationid}
            setPrivilege={setPrivilege}
            setDelete_modal={setDelete_modal}
          />
        </div>
      ),
    },
  ];






  return (
    <div className="container-fluid">
      <div className="mt-4 row text-center" align="center">
        <div className="col-6 Width100">
          <StyledButton1
            onClick={() => {
              history.push("/adminfunctionmodal");
            }}
          >
            Return to Admin Menu
          </StyledButton1>
        </div>
        <div className="col-6 Width100">
          <StyledButton1
            style={{ alignContent: "flex-start" }}
            onClick={() => {
              selection_newtoploc_api_func();
              setAdd_user_modal(true);
            }}
          >
            Add User to Location/Topic
          </StyledButton1>
        </div>
      </div>

      {/* <div className="d-flex justify-content-center">
        <div
          className=" EXTQ FilterS"
          style={{
            marginTop: 20,
            paddingTop: 30,
            paddingLeft: 25,
            border: "black solid 1px",
            borderRadius: 50,
          }}
        >
          <div className="row EXTQ">
            <div className="col-lg-3">
              <input
                placeholder="Filter Location"
                onChange={(e) => setFilter_location(e.target.value)}
                type="text"
              />
            </div>

            <div className="col-lg-3">
              <input
                placeholder="Filter Topic"
                onChange={(e) => setFilter_topic(e.target.value)}
                type="text"
              />
            </div>

            <div className="col-lg-3">
              <input
                placeholder="Filter User Email"
                onChange={(e) => setFilter_email(e.target.value)}
                type="text"
              />
            </div>

            <div className="col-lg-3 text-center">
              <StyledButton
                style={{
                  position: "relative",
                  top: "-40%",
                  width: 90,
                  height: 35,
                }}
                onClick={() => {
                  filter_Function();
                }}
              >
                Search
              </StyledButton>
            </div>
          </div>
        </div>
      </div> */}

      <div>
        {/* add user modal=================== */}
        <ReactModal className="ModalStyle1 Width90" isOpen={add_user_modal}>
          <div>
            {" "}
            <StyledForm>
              {" "}
              <div align="center" className="mb-3">
                <h4>Add User to Location/Topic</h4>
              </div>
              <StyledFormInputDiv>
                <div style={FormDivStyle} align="left">
                  <StyledLabel htmlFor="qdesc">Location</StyledLabel>
                </div>
                <div style={{ width: "100%", alignSelf: "center" }}>
                  <select
                    style={{
                      width: "110%",
                      marginLeft: "-10%",
                      alignItems: "center",
                      border: "1px solid black",
                    }}
                    placeholder="Enter Location"
                    value={locationid}
                    onChange={(e) => {
                      setLocationid(e.target.value);
                    }}
                  >
                    <option value="" selected disabled hidden>
                      Select Location
                    </option>
                    {Eligiblelocation?.sort(sortLocations)?.map((data) => {
                      return (
                        <option value={data.locationid}>
                          {data.locationdesc}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </StyledFormInputDiv>
              {formErrors.locationid_error && (
                <div className="offset-6 mt-1">
                  <p
                    style={{
                      color: "red",
                      fontSize: ".8rem",
                      marginTop: "0px",
                    }}
                  >
                    {formErrors.locationid_error}
                  </p>
                </div>
              )}
              <StyledFormInputDiv>
                <div style={FormDivStyle} align="left">
                  <StyledLabel htmlFor="qdesc">Topic</StyledLabel>
                </div>
                <div style={{ width: "100%", alignSelf: "center" }}>
                  <select
                    style={{
                      width: "110%",
                      marginLeft: "-10%",
                      alignItems: "center",
                      border: "1px solid black",
                    }}
                    placeholder="Enter Topic"
                    value={topicid}
                    onChange={(e) => {
                      setTopicid(e.target.value);
                    }}
                  >
                    <option value="" selected disabled hidden>
                      Select Topic
                    </option>
                    {eligibletopics?.sort(sortTopics)?.map((data) => {
                      return (
                        <option value={data.topicid}>{data.cuzdesc}</option>
                      );
                    })}
                  </select>
                </div>
              </StyledFormInputDiv>
              {formErrors.topicid_error && (
                <div className="offset-6 mt-1">
                  <p
                    style={{
                      color: "red",
                      fontSize: ".8rem",
                      marginTop: "0px",
                    }}
                  >
                    {formErrors.topicid_error}
                  </p>
                </div>
              )}
              <StyledFormInputDiv>
                <div style={FormDivStyle} align="left">
                  <StyledLabel htmlFor="qdesc">User Email</StyledLabel>
                </div>
                <div style={{ width: "100%", alignSelf: "center" }}>
                  <input
                    type="text"
                    style={{
                      width: "110%",
                      marginLeft: "-10%",
                      alignItems: "center",
                      border: "1px solid black",
                    }}
                    placeholder="Enter User Email"
                    value={userEmail}
                    onChange={(e) => {
                      setUserEmail(e.target.value);
                    }}
                  />
                </div>
              </StyledFormInputDiv>
              {formErrors.userEmail_error && (
                <div className="offset-6 mt-1">
                  <p
                    style={{
                      color: "red",
                      fontSize: ".8rem",
                      marginTop: "0px",
                    }}
                  >
                    {formErrors.userEmail_error}
                  </p>
                </div>
              )}
              <StyledFormInputDiv>
                <div style={FormDivStyle} align="left">
                  <StyledLabel htmlFor="qdesc">Privilege</StyledLabel>
                </div>
                <div style={{ width: "100%", alignSelf: "center" }}>
                  <select
                    style={{
                      width: "110%",
                      marginLeft: "-10%",
                      alignItems: "center",
                      border: "1px solid black",
                    }}
                    value={privilege}
                    onChange={(e) => {
                      setPrivilege(e.target.value);
                    }}
                  >
                    <option value="" selected disabled hidden>
                      Select
                    </option>
                    <option value="View">View</option>
                    <option value="Edit">Edit</option>
                  </select>
                </div>
              </StyledFormInputDiv>
              {formErrors.privilege_error && (
                <div className="offset-6 mt-1">
                  <p
                    style={{
                      color: "red",
                      fontSize: ".8rem",
                      marginTop: "0px",
                    }}
                  >
                    {formErrors.privilege_error}
                  </p>
                </div>
              )}
            </StyledForm>
          </div>
          <div align="center">
            <StyledButton
              style={{
                width: "30%",
                height: 30,
                marginTop: 50,
              }}
              onClick={() => {
                if (handleValidation()) {
                  selection_addemail_api_func();
                } else {
                  message.error("Please complete all data");
                }
              }}
            >
              Save
            </StyledButton>
            <StyledButton
              style={{
                width: "30%",
                height: 30,
                backgroundColor: "red",
                marginLeft: 10,
              }}
              onClick={() => {
                setAdd_user_modal(false);
                resetFields();
              }}
            >
              Cancel
            </StyledButton>
          </div>
        </ReactModal>
      </div>

      <div>
        {/* --------------- Edit Modal ------------ */}
        <ReactModal className="ModalStyle1 Width90" isOpen={edit_user_tpc_pmn_modal}>
          <div>
            {" "}
            <StyledForm>
              {" "}
              <div align="center" className="mb-3">
                <h4>Edit User Topic Permissions</h4>
              </div>
              <StyledFormInputDiv>
                <div style={{ width: "70%", marginLeft: 30 }}>
                  <StyledLabel htmlFor="qdesc">Location</StyledLabel>
                </div>
                <div style={{ width: "100%", alignSelf: "center" }}>
                  <input
                    type="text"
                    style={{
                      width: "110%",
                      marginLeft: "-10%",
                      alignItems: "center",
                      border: "1px solid black",
                    }}
                    placeholder="Enter Location"
                    value={location}
                    disabled={true}
                  // onChange={(e) => {
                  //   setLocation(e.target.value)

                  // }}
                  />
                </div>
              </StyledFormInputDiv>
              <StyledFormInputDiv>
                <div style={{ width: "70%", marginLeft: 30 }}>
                  <StyledLabel htmlFor="qdesc">Topic</StyledLabel>
                </div>
                <div style={{ width: "100%", alignSelf: "center" }}>
                  <input
                    type="text"
                    style={{
                      width: "110%",
                      marginLeft: "-10%",
                      alignItems: "center",
                      border: "1px solid black",
                    }}
                    placeholder="Enter Topic"
                    value={topic}
                    disabled={true}
                  // onChange={(e) => {
                  //   setTopic(e.target.value)
                  //   console.log(e.target.value)
                  // }}
                  />
                </div>
              </StyledFormInputDiv>
              <StyledFormInputDiv>
                <div style={{ width: "70%", marginLeft: 30 }}>
                  <StyledLabel htmlFor="qdesc">User Email</StyledLabel>
                </div>
                <div style={{ width: "100%", alignSelf: "center" }}>
                  <input
                    type="text"
                    style={{
                      width: "110%",
                      marginLeft: "-10%",
                      alignItems: "center",
                      border: "1px solid black",
                    }}
                    placeholder="Enter User Email"
                    value={userEmail}
                    disabled={true}
                  />
                </div>
              </StyledFormInputDiv>
              <StyledFormInputDiv>
                <div style={{ width: "70%", marginLeft: 30 }}>
                  <StyledLabel htmlFor="qdesc">Privilege</StyledLabel>
                </div>
                <div style={{ width: "100%", alignSelf: "center" }}>
                  <select
                    style={{
                      width: "110%",
                      marginLeft: "-10%",
                      alignItems: "center",
                      border: "1px solid black",
                    }}
                    value={privilege}
                    onChange={(e) => {
                      setPrivilege(e.target.value);
                    }}
                  >
                    <option value="" selected disabled hidden>
                      Select
                    </option>
                    <option value="View">View</option>
                    <option value="Edit">Edit</option>
                  </select>
                </div>
              </StyledFormInputDiv>
            </StyledForm>
          </div>
          <div align="center">
            <StyledButton
              style={{
                width: "30%",
                height: 30,
                marginTop: 50,
              }}
              onClick={() => {
                // if (handleValidation()) {
                selection_changepriv_api_func();
                // } else {
                //   message.error("Please complete all data");
                // }
              }}
            >
              Save
            </StyledButton>
            <StyledButton
              style={{
                width: "30%",
                height: 30,
                backgroundColor: "red",
                marginLeft: 10,
              }}
              onClick={() => {
                setEdit_user_tpc_pmn_modal(false);
                resetFields();
              }}
            >
              Cancel
            </StyledButton>
          </div>
        </ReactModal>
      </div>

      {/* delete modal ===============================>>>>>>>>>>>>>>> */}

      <ReactModal className="ModalStyle2 Width90" isOpen={delete_modal}>
        <div>
          {" "}
          <StyledForm>
            {" "}
            <div align="center">
              <h4>Are you sure you want to remove the user from this topic?</h4>
            </div>
          </StyledForm>
        </div>
        <div className="d-flex justify-content-evenly mt-2">
          <StyledButton1
            style={{ width: "30%" }}
            onClick={() => {
              selection_deleteuser_api_func();
            }}
          >
            Yes
          </StyledButton1>
          <StyledButton1
            style={{
              width: "30%",
              backgroundColor: "red",
            }}
            onClick={() => {
              setDelete_modal(false);
              resetFields();
            }}
          >
            Cancel
          </StyledButton1>
        </div>
      </ReactModal>


      <div
        style={{ height: '80vh', width: "100%", marginTop: 15 }}
        className={classes.root}
      >
        <DataGrid
          sx={{
            ".MuiDataGrid-columnHeaders": {
              backgroundColor: "#ff8c24",
            },
            "&.MuiDataGrid-root": {
              border: "none",
            },
          }}
          rows={rows1}
          getRowId={(r) => r.id}
          id="_id"
          columns={columnsUsers}
          // initialState={{

          //   pagination: {
          //     pageSize: 10,
          //   },
          // }}
          pageSize={pageSize}
          onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
          rowsPerPageOptions={[ 100]}
          pagination
          hideFooterPagination={ rows1.length <= pageSize}
          //  processRowUpdate={processRowUpdate}
          experimentalFeatures={{ newEditingApi: true }}
        />

      </div>



      {/* <div className="m-4 table-responsive tableFixHeadLarge">
        <StyledTable>
          <thead>
            <ScrollTableHeader>
              <StyledTableHeaderData>
                S.No{" "}
                <a
                  onClick={() => {
                    setSortRev(!sortRev);
                    setSortByTopic(false);
                    setSortByLocation(false);
                    setSortByUserEmail(false);
                    setSortBySno(true);
                  }}
                >
                  <i class="fas fa-sort" />
                </a>
              </StyledTableHeaderData>
              <StyledTableHeaderData style={{ paddingLeft: 15 }}>
                Location{" "}
                <a
                  onClick={() => {
                    setSortRev(!sortRev);
                    setSortByTopic(false);
                    setSortByUserEmail(false);
                    setSortBySno(false);
                    setSortByLocation(true);
                  }}
                >
                  <i class="fas fa-sort" />
                </a>
              </StyledTableHeaderData>
              <StyledTableHeaderData style={{ paddingLeft: 15 }}>
                Topic{" "}
                <a
                  onClick={() => {
                    setSortRev(!sortRev);
                    setSortByLocation(false);
                    setSortByUserEmail(false);
                    setSortBySno(false);
                    setSortByTopic(true);
                  }}
                >
                  <i class="fas fa-sort" />
                </a>
              </StyledTableHeaderData>
              <StyledTableHeaderData style={{ paddingLeft: 15 }}>
                User Email{" "}
                <a
                  onClick={() => {
                    setSortRev(!sortRev);
                    setSortByTopic(false);
                    setSortByLocation(false);
                    setSortBySno(false);
                    setSortByUserEmail(true);
                  }}
                >
                  <i class="fas fa-sort" />
                </a>
              </StyledTableHeaderData>
              <StyledTableHeaderData>Privilege</StyledTableHeaderData>
              <StyledTableHeaderData>Action</StyledTableHeaderData>
            </ScrollTableHeader>
          </thead>
          <tbody>
            {user_topic_data &&
              user_topic_data?.map((data, index) => {
                return (
                  <StyledTableRow>
                    <td style={{ color: "black" }}>{index + 1}</td>
                    <td style={{ color: "black" }}>{data.locationdesc}</td>
                    <td style={{ color: "black" }}>{data.cuzdesc}</td>
                    <td style={{ color: "black" }}>{data.useremail}</td>
                    <td style={{ color: "black" }}>{data.privilege}</td>
                    <td>
                      <a
                        onClick={() => {
                          setTopic(data.cuzdesc);
                          setLocation(data.locationdesc);
                          setUserEmail(data.useremail);
                          setPrivilege(data.privilege);
                          setTopicid(data.topicid);
                          setLocationid(data.locationid);

                          setEdit_user_tpc_pmn_modal(true);
                        }}
                      >
                        <i class="fas fa-pencil-alt" aria-hidden="true"></i>
                      </a>{" "}
                      <a
                        onClick={() => {
                          setDelete_modal(true);
                          setUserEmail(data.useremail);
                          setTopicid(data.topicid);
                          setLocationid(data.locationid);
                          setPrivilege(data.privilege);
                        }}
                      >
                        <i
                          class="fas fa-trash"
                          style={{ color: "red" }}
                          aria-hidden="true"
                        ></i>
                      </a>
                    </td>
                  </StyledTableRow>
                );
              })}
          </tbody>
        </StyledTable>
      </div> */}
    </div>
  );
};

export default User_Topic_Permissions;
