import React, { useEffect, useState } from "react";
import styled from "styled-components";
import ReactModal from "react-modal";
import { BASE_URL } from "../url/baseurl";

import { message } from "antd";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import { useHistory } from "react-router-dom";
import {
  Existing_CustList,
  Edit_Cust_Data,
  Add_new_cust_data,
  receive_homecountry_sectors,
  upload_brandIcon,
  Edit_UserEmail,
  receive_newcust_field,
} from "../services/AdminProgramApi";

// import { Scrollbars } from 'react-custom-scrollbars';
const theme = localStorage.getItem("brandColor");

const StyledDiv = styled.div`
  justify-content: center;
  margin: 0 auto;
 
`;

const StyledForm = styled.form`
  display: flex;
  justify-content: center;
  flex-direction: column;
`;
const FormDivStyle = {
  position: "relative",
  display: "inline",
  width: "100%",
  textAlign: "left",
};

const StyledFormInputDiv = styled.div`
  display: flex;
  margin-top: 4px;
  width: 100%;
`;
const StyledLabel = styled.label`
  flex: 1;
  margin-right: 10px;
  display: inline;
  width: 30%;
`;
const StyledTableRow = styled.tr`
  border-bottom: 1px solid #9d9d9d;
`;
const StyledButton = styled.button`
  background: ${theme};
  width: 330px;
  border-radius: 30px;
  height: 55px;
  text-align: center;
  color: white;
  font-size: 20px !important;
  font-weight: 500;
  border: none;
  margin-top: 25px;
  padding: 5px 10px;
`;
const StyledButton3 = styled.button`
  background: ${theme};
  width: 330px;
  border-radius: 30px;
  height: 55px;
  text-align: center;
  color: white;
  font-size: 16px !important;
  font-weight: 500;
  border: none;
  margin-top: 25px;
  padding: 0px 10px;
`;

const StyledButton2 = styled.button`
  background: ${theme};
  width: 330px;
  border-radius: 30px;
  height: 55px;
  text-align: center;
  color: white;
  font-size: 20px !important;
  font-weight: 500;
  border: none;
   padding: 5px 10px;
`;

const StyledButton1 = styled.button`
  background: ${theme};
  width: 100%;
  border-radius: 30px;
  height: 45px;
  text-align: center;
  color: white;
  font-size: 16px !important;
  font-weight: 500;
  border-color: none;
  border: none;
  margin-top: 25px;
  padding: 5px 10px;
`;

const StyledTable = styled.table`
  width: 100%;
  height: 3%;
`;

const ScrollTableHeader = styled.tr`
  background: ${theme};
  font-weight: bold;
  color: #ffffff;
  font-family: Nunito;
  font-style: normal;
  position: sticky;
  top: 0;
`;

const StyledTableHeaderData = styled.th`
  font-weight: bold;
  padding 5px;
  font-family: Nunito;
  font-style: normal;
  text-align:center;
`;

const ModalStyle = {
  content: {
    width: "500px",
    height: "300px",
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    justifyItem: "center",

    transform: "translate(-50%, -50%)",
    display: "flex",
    justifyContent: "center",
  },
};

const Support_Menu = () => {
  const history = useHistory();

  const [run, setRun] = useState("");
  const [view_demo, setView_demo] = useState(false);
  const [add_new_customer, setAdd_new_customer] = useState(false);
  const [conformModel, setConformModel] = useState(false);
  const [originalmail ,setOrginalmail]= useState("");
  const [customerid, setFCustomerid] = useState("");
  const [userid, setUserid] = useState("");
  const [custName, setCustName] = useState("");
  const [countryCode, setCountryCode] = useState("");
  const [sector, setSector] = useState("");
  const [accountOwnerEmail, setAccountOwnerEmail] = useState("");
  const [homeCountryList, setHomeCountryList] = useState([]);
  const [sectorsList, setSectorsList] = useState([]);
  const [emailReminder, setEmailReminder] = useState("Y");
  const [emailTopic, setEmailTopic] = useState("Y");

  const [emailWelcome, setEmailWelcome] = useState("Y");
  const [active, setActive] = useState("Y");
  const [brandColor, setBrandColor] = useState("#ff8c24");
  const [brandHeader, setBrandHeader] = useState("");
  const [brandIcon, setBrandIcon] = useState("");
  const [adm2fa, setAdm2fa] = useState("N");
  const [entrolledPlan, setEntrolledPlan] = useState("");
  const [entrolledPlanList, setEntrolledPlanList] = useState("");
  const [paymentSchedule, setPaymentSchedule] = useState("");
  const [agreementPrice, setAgreementPrice] = useState("");
  const [planStartDate, setPlanStartDate] = useState("");
  const [planEndDate, setPlanEndDate] = useState("");
  const [currency, setCurrency] = useState("");
  const [currencyList, setCurrencyList] = useState("");
  const [trialPeriod, setTrialPeriod] = useState("N");
  const [trialStartDate, setTrialStartDate] = useState("");
  const [trialEndDate, setTrialEndDate] = useState("");
  const [planStatus, setPlanStatus] = useState("Active");
  const [locationLimit, setLocationLimit] = useState("");
  const [adminLimit, setAdminLimit] = useState("");
  const [userLimit, setUserLimit] = useState("");
  const [invoiceNumber, setInvoiceNumber] = useState("");
  const [planNote, setPlanNote] = useState("");
  const [PLName, setPLName] = useState("");
  const [PLCountry, setPLCountry] = useState("");
  const [PLSector, setPLSector] = useState("");
  const [PLActive, setPLActive] = useState("Y");
  const [PLSectorList, setPLSectorList] = useState("");
  const [PLCountryList, setPLCountryList] = useState("");
  const [formErrors, setFormErrors] = useState("");
  const [editDataList, setEditDataList] = useState([]);
  const [editDataListFilter, setEditDataListFilter] = useState([]);
  const [edit_model, setEdit_customer] = useState(false);
  const [inputstatus, setInputstatus] = useState("");
  const [partnerEmail, setPartnerEmail] = useState("");
  //  --------------Filter -------------------------
  const [fCustomerid, setCustomerid ] = useState("");
  const [fCustdesc, setFCustdesc] = useState("");
  const [fcountrycode, setFCountrycode] = useState("");
  const [femail, setFEmail] = useState("");
  const [fstatus, setFstatus] = useState("");
  const [fplanid, setFplanid] = useState("");

  const filter = () => {
    let filter_data = [];
    // console.log(editDataList);

    if (fCustomerid !== "") {
      filter_data = [
        ...filter_data,
        ...editDataList.filter((item) =>
          String(item.customerid)
            .toLowerCase()
            .includes(fCustomerid?.toLowerCase())
        ),
      ];
    }

    if (fCustdesc !== "") {
      filter_data = [
        ...filter_data,
        ...editDataList.filter((item) =>
          String(item.custdesc).toLowerCase().includes(fCustdesc?.toLowerCase())
        ),
      ];
    }
    if (fcountrycode !== "") {
      filter_data = [
        ...filter_data,
        ...editDataList.filter((item) =>
          String(item.countrycode)
            .toLowerCase()
            .includes(fcountrycode?.toLowerCase())
        ),
      ];
    }

    if (femail !== "") {
      filter_data = [
        ...filter_data,
        ...editDataList.filter((item) =>
          String(item.useremail).toLowerCase().includes(femail?.toLowerCase())
        ),
      ];
    }
    if (fstatus !== "") {
      filter_data = [
        ...filter_data,
        ...editDataList.filter((item) =>
          String(item.inputstatus)
            .toLowerCase()
            .includes(fstatus?.toLowerCase())
        ),
      ];
    }
    if (fplanid !== "") {
      filter_data = [
        ...filter_data,
        ...editDataList.filter((item) =>
          String(item.pymtid).toLowerCase().includes(fplanid?.toLowerCase())
        ),
      ];
    }
    setEditDataList(
      filter_data.length !== 0 ? filter_data : editDataListFilter
    );
  };
  const useStyles = makeStyles((theme) => ({
    container: {
      display: "flex",
      flexWrap: "wrap",
    },
    textField: {
      width: "100%",
      alignItems: "center",
      border: "1px solid black",
    },
  }));
  const classes = useStyles();

  const Existing_CustList_api = async () => {
    Existing_CustList()
      .then((res) => {
        if (res.data.code === 200) {
          setEditDataList(res.data.result);
          // console.log("image ==>", res.data.result);
        } else {
          // message.error(res.data.message);
        }
      })
      .catch((e) => {
        // console.log(e);
      });
  };

  // ----------------------- Validation -------------------------
  const handleValidation = () => {
    let formErrors = {};
    let formIsValid = true;
    if (!custName) {
      formIsValid = false;
      formErrors["custName_error"] = "Customer Name is required.";
    }
    if (!countryCode) {
      formIsValid = false;
      formErrors["countryCode_error"] = "Country Code is required.";
    }
    if (!sector) {
      formIsValid = false;
      formErrors["sector_error"] = "Sector is required.";
    }
    if (!accountOwnerEmail) {
      formIsValid = false;
      formErrors["accountOwnerEmail_error"] =
        "Account Owner Email is required.";
    } else if (!/\S+@\S+\.\S+/.test(accountOwnerEmail)) {
      formIsValid = false;
      formErrors["accountOwnerEmail_error"] = "Invalid Email id.";
    }

    if (!emailReminder) {
      formIsValid = false;
      formErrors["emailReminder_error"] = "Email Reminder is required.";
    }
    if (!emailWelcome) {
      formIsValid = false;
      formErrors["emailWelcome_error"] = "Email Welcome  is required.";
    }
    if (!active) {
      formIsValid = false;
      formErrors["active_error"] = "Active is required.";
    }
    // if (!brandColor) {
    //   formIsValid = false;
    //   formErrors["brandColor_error"] = "Brand Color  is required.";
    // }
    if (edit_model == true) {
    }

    if (!brandIcon) {
      formIsValid = false;
      formErrors["brandIcon_error"] = "Brand Icon  is required.";
    }

    if (!adm2fa) {
      formIsValid = false;
      formErrors["adm2fa_error"] = "Require 2FA for Admins is required.";
    }

    if (!entrolledPlan) {
      formIsValid = false;
      formErrors["entrolledPlan_error"] = "Enrolled Plan is required.";
    }

    if (!paymentSchedule) {
      formIsValid = false;
      formErrors["paymentSchedule_error"] = "Payment Schedule is required.";
    }

    if (!agreementPrice) {
      formIsValid = false;
      formErrors["agreementPrice_error"] = "Agreement Price is required.";
    } else if (agreementPrice > 999999999999) {
      formIsValid = false;
      formErrors["agreementPrice_error"] = "Agreement Price is out of range.";
    }

    if (!planStartDate) {
      formIsValid = false;
      formErrors["planStartDate_error"] = "Payment Schedule is required.";
    }
    if (!planEndDate) {
      formIsValid = false;
      formErrors["planEndDate_error"] = "Plan StartDate is required.";
    }
    if (!currency) {
      formIsValid = false;
      formErrors["currency_error"] = "Currency is required.";
    }
    if (!trialPeriod) {
      formIsValid = false;
      formErrors["trialPeriod_error"] = "Trial Period is required.";
    }
    if (!trialStartDate) {
      formIsValid = false;
      formErrors["trialStartDate_error"] = "Trial StartDate is required.";
    }
    if (!trialEndDate) {
      formIsValid = false;
      formErrors["trialEndDate_error"] = "Trial EndDate is required.";
    }
    if (!planStatus) {
      formIsValid = false;
      formErrors["planStatus_error"] = "Plan Status is required.";
    }
    if (!locationLimit) {
      formIsValid = false;
      formErrors["locationLimit_error"] = "Location Limit is required.";
    } else if (locationLimit > 32767) {
      formIsValid = false;
      formErrors["locationLimit_error"] = "Location Limit is Out of Range.";
    }
    if (!adminLimit) {
      formIsValid = false;
      formErrors["adminLimit_error"] = "Admin Limit is required.";
    } else if (adminLimit > 127) {
      formIsValid = false;
      formErrors["adminLimit_error"] = "Admin Limit is Out of Range.";
    }
    if (!userLimit) {
      formIsValid = false;
      formErrors["userLimit_error"] = "User Limit is required.";
    } else if (userLimit > 32767) {
      formIsValid = false;
      formErrors["userLimit_error"] = "User Limit is Out of Range.";
    }
    // if (!invoiceNumber) {
    //   formIsValid = false;
    //   formErrors["invoiceNumber_error"] = "Invoice Number is required.";
    // }
    // if (!planNote) {
    //   formIsValid = false;
    //   formErrors["planNote_error"] = "Plan Note is required.";
    // }
    if (edit_model == false) {
      // console.log("edit false");
      if (!PLName) {
        formIsValid = false;
        formErrors["PLName_error"] = "Primary Location Name is required.";
      }

      if (!PLCountry) {
        formIsValid = false;
        formErrors["PLCountry_error"] = "Primary Location Country is required.";
      }
      if (!PLSector) {
        formIsValid = false;
        formErrors["PLSector_error"] = "Primary Location Sector is required.";
      }
      if (!PLActive) {
        formIsValid = false;
        formErrors["PLActive_error"] = "Primary Location Sector is required.";
      }
    }

    if (partnerEmail) {
      if (!/\S+@\S+\.\S+/.test(partnerEmail)) {
        formIsValid = false;
        formErrors["partnerEmail_error"] = "Invalid Email ID.";
      } else if (partnerEmail === accountOwnerEmail) {
        formIsValid = false;
        formErrors["partnerEmail_error"] = "The Partner Email cannot be a company user.";
      }
    }

    setFormErrors(formErrors);
    // console.log("validate");
    return formIsValid;
  };

  const ReceiveLocList = async () => {
    receive_homecountry_sectors("homecountry")
      .then((res) => {
        // console.log(res);
        if (res.data.code === 200) {
          setHomeCountryList(res.data.country_list);
        } else {
          message.error(res.data.message);
        }
        // console.log("LocList", res.data.country_list);
      })
      .catch((e) => {
        // console.log(e);
      });
    //console.log("ReceiveLocList");
  };
  const ReceiveSectorList = async () => {
    receive_homecountry_sectors("sectors")
      .then((res) => {
        //console.log(res);
        if (res.data.code === 200) {
          setSectorsList(res.data.sector_list);
        } else {
          message.error(res.data.message);
        }
        //console.log("SectorList", res.data.sector_list);
      })
      .catch((e) => {
        //console.log(e);
      });
  };
  const ReceiveEnrolledPlan = async () => {
    receive_newcust_field("EnrolledPlan")
      .then((res) => {
        //console.log(res);
        if (res.data.code === 200) {
          setEntrolledPlanList(res.data.entrolled_plan_list);
        } else {
          message.error(res.data.message);
        }
        //console.log("EnrolledPlan", res.data.entrolled_plan_list);
      })
      .catch((e) => {
        //console.log(e);
      });
  };
  const ReceiveCurrency = async () => {
    receive_newcust_field("Currency")
      .then((res) => {
        //console.log(res);
        if (res.data.code === 200) {
          setCurrencyList(res.data.currency);
        } else {
          message.error(res.data.message);
        }
        //console.log("Currency", res.data.currency);
      })
      .catch((e) => {
        //console.log(e);
      });
  };

  const ReceivePLCountry = async () => {
    receive_newcust_field("PLCountry")
      .then((res) => {
        //console.log(res);
        if (res.data.code === 200) {
          setPLCountryList(res.data.PLCountry);
        } else {
          message.error(res.data.message);
        }
        //console.log("PLCountry", res.data.PLCountry);
      })
      .catch((e) => {
        //console.log(e);
      });
  };

  const ReceivePLName = async () => {
    receive_newcust_field("PLName")
      .then((res) => {
        //console.log(res);
        if (res.data.code === 200) {
          setPLName(res.data.PLName[0]["Elmvalue"]);
        } else {
          message.error(res.data.message);
        }
        //console.log("PLName", res.data.PLName[0]["Elmvalue"]);
      })
      .catch((e) => {
        //console.log(e);
      });
  };

  const ReceivePLSector = async () => {
    receive_newcust_field("PLSector")
      .then((res) => {
        //console.log(res);
        if (res.data.code === 200) {
          setPLSectorList(res.data.PLSector);
        } else {
          message.error(res.data.message);
        }
        //console.log("PLSector", res.data.PLSector);
      })
      .catch((e) => {
        //console.log(e);
      });
  };

  const cancel = async () => {
    // ReceiveLocList();
    // ReceiveSectorList();
    // ReceiveEnrolledPlan();
    // ReceiveCurrency();
    // ReceivePLName();
    // ReceivePLCountry();
    // ReceivePLSector();
    ReceiveToday();
    setCustName("");
    setCountryCode("");
    setSector("");
    setAccountOwnerEmail("");
    setEmailWelcome("Y");
    setActive("Y");
    setBrandColor("#ff8c24");
    setAgreementPrice("");
    setBrandIcon("");
    setAdm2fa("N");
    setLocationLimit("");
    setEntrolledPlan("");
    setPaymentSchedule("");
    setCurrency("");
    setAdminLimit("");
    setTrialPeriod("N");
    setPlanStatus("Active");
    setAdminLimit("");
    setUserLimit("");
    setInvoiceNumber("");
    setPlanNote("");
    setPLSector("");
    setPLCountry("");
    setPLActive("Y");
    setBrandIcon("");
    setPartnerEmail("");
  };
  const AddCustData = () => {
    Add_new_cust_data({
      custdesc: custName,
      countrycode: countryCode,
      sector: sector,
      accountOwnerEmail: accountOwnerEmail,
      emailReminder: emailReminder,
      emailTopic: emailTopic,
      emailWelcome: emailWelcome,
      active: active,
      brandColor: brandColor,
      brandHeader: brandHeader,
      brandIcon: brandIcon,
      adm2fa: adm2fa,
      entrolledPlan: entrolledPlan,
      paymentSchedule: paymentSchedule,
      agreementPrice: agreementPrice,
      planStartDate: planStartDate,
      planEndDate: planEndDate,
      currency: currency,
      trialPeriod: trialPeriod,
      trialStartDate: trialStartDate,
      trialEndDate: trialEndDate,
      planStatus: planStatus,
      locationLimit: locationLimit,
      adminLimit: adminLimit,
      userLimit: userLimit,
      invoiceNumber: invoiceNumber,
      planNote: planNote,
      PLName: PLName,
      PLCountry: PLCountry,
      PLSector: PLSector,
      PLActive: PLActive,
      PartnerEmail: partnerEmail
    })
      .then((res) => {
        if (res.data.code === 200) {
          message.success("Updated Successfully");
          //console.log("AddCustData");
          cancel();
          window.location.reload();
        } else if (res.data.newemail == "notfound") {
          message.error(
            "The owner email you entered is not an active user for this customer. The user must have logged into this site at least once."
          );
        } else {
          message.error(res.data.message);
        }
      })
      .catch((e) => {
        //console.log(e);
      });
    //console.log("success");
  };
  
 const EditCustData = () => {
  let formIsValid = true;
  Edit_Cust_Data({
    customerid:fCustomerid,
    userid:userid,
    custdesc: custName,
      countrycode: countryCode,
      sector: sector,
      accountOwnerEmail: accountOwnerEmail,
      emailReminder: emailReminder,
      emailTopic: emailTopic,
      emailWelcome: emailWelcome,
      active: active,
      inputstatus:inputstatus,
      brandColor: brandColor,
      brandHeader: brandHeader,
      brandIcon: brandIcon,
      adm2fa: adm2fa,
      entrolledPlan: entrolledPlan,
      paymentSchedule: paymentSchedule,
      agreementPrice: agreementPrice,
      planStartDate: planStartDate,
      planEndDate: planEndDate,
      currency: currency,
      trialPeriod: trialPeriod,
      trialStartDate: trialStartDate,
      trialEndDate: trialEndDate,
      planStatus: planStatus,
      locationLimit: locationLimit,
      adminLimit: adminLimit,
      userLimit: userLimit,
      invoiceNumber: invoiceNumber,
      planNote: planNote
    
  })    .then((res) => {
    if (res.data.code === 200) {
      message.success("Updated Successfully");
      //console.log("formIsValid ==>",formIsValid);
      // window.location.reload();
      setEdit_customer(false);
      setAdd_new_customer(false);
      Existing_CustList_api();
      var load = setTimeout(function() {
        //console.log("redirect");
        Existing_CustList_api();
        cancel();
          }, 2000);
      return formIsValid;
    } else if (res.data.code === 300) {
      //console.log(res.data.email);
      setOrginalmail(res.data.email);
      setConformModel(true);
    } else {
      formIsValid = false;
      message.error(res.data.message);
      return formIsValid;
    }
  })
  .catch((e) => {
    //console.log(e);
  });
 }

  const ReceiveToday = () => {
    const today = new Date();
    const yyyy = today.getFullYear();
    let mm = today.getMonth() + 1; // Months start at 0!
    let dd = today.getDate();

    if (dd < 10) dd = "0" + dd;
    if (mm < 10) mm = "0" + mm;

    const formattedToday = yyyy + "-" + mm + "-" + dd;
    setTrialStartDate(formattedToday);
    setTrialEndDate(formattedToday);
    setPlanStartDate(formattedToday);
    setPlanEndDate(formattedToday);

    //console.log("Today ==> ", formattedToday);
  };

  const FileUpload = (file) => {
    const formData = new FormData();

    // Update the formData object
    formData.append("image", file, file.name);
    upload_brandIcon(formData)
      .then((res) => {
        if (res.data.code === 200) {
          setBrandIcon(res.data.image);
          message.success("Image Uploaded Successfully");
          //console.log("image ==>", file.name);
        } else {
          message.error(res.data.message);
        }
      })
      .catch((e) => {
        //console.log(e);
      });
    //console.log(file);
  };
  const EditUserEmail = () =>{
    // console.log("customerid ===>",fCustomerid);
    Edit_UserEmail({
      customerid:fCustomerid,
      userid:userid,
      accountOwnerEmail: accountOwnerEmail,
      inputstatus:inputstatus,
    }) .then((res) => {
      if (res.data.code === 200) {
        message.success("Updated Successfully");
        //console.log("AddCustData");
        cancel();
        // window.location.reload();
        var load = setTimeout(function() {
          //console.log("redirect");
          Existing_CustList_api();
            }, 2000);
       
      } else {
        
        message.error(res.data.message);
       
      }
    })
    .catch((e) => {
      //console.log(e);
    });
  }
  useEffect(() => {
    if (localStorage.getItem("Name") != "vasanth") {
      history.push("guvrix_admin");
    }
    //console.log("name ==>", localStorage.getItem("Name"));
    ReceiveLocList();
    ReceiveSectorList();
    ReceiveEnrolledPlan();
    ReceiveCurrency();
    ReceivePLName();
    ReceivePLCountry();
    ReceivePLSector();
    ReceiveToday();
  }, []);

  return (
    <>
      <div className="container" style={{ overflow: "none",  }}>
        <ReactModal className="ModalStyle4 Width90" isOpen={add_new_customer}>
          {/* style={{ overflow: "none", paddingTop: 30 }} */}
          <div >
            {" "}
            <StyledForm>
              {" "}
              <div align="center" className="mb-3">
                {edit_model == true ? (
                  <h4>Edit Customer</h4>
                ) : (
                  <h4>Add New Customer</h4>
                )}
              </div>
              {/* input */}
              <StyledFormInputDiv>
                <div style={FormDivStyle}>
                  <StyledLabel htmlFor="qdesc">Customer Name</StyledLabel>
                </div>
                <div style={{ width: "100%", alignSelf: "center" }}>
                  <input
                    type="text"
                    style={{
                      width: "100%",
                      alignItems: "center",
                      border: "1px solid black",
                    }}
                    placeholder="Enter Customer Name"
                    value={custName}
                    onChange={(e) => {
                      setCustName(e.target.value);
                      //console.log(e.target.value);
                    }}
                  />
                </div>
              </StyledFormInputDiv>
              {formErrors.custName_error && (
                <div className="offset-6 mt-2">
                  <p
                    style={{
                      color: "red",
                      fontSize: ".8rem",
                      marginTop: "0px",
                    }}
                  >
                    {formErrors.custName_error}
                  </p>
                </div>
              )}
              {/* selector and dropdown */}
              <StyledFormInputDiv>
                <div style={FormDivStyle}>
                  <StyledLabel htmlFor="qdesc">Home Country</StyledLabel>
                </div>
                <div style={{ width: "100%", alignSelf: "center" }}>
                  <select
                    style={{
                      width: "100%",
                      // marginLeft: "30%",
                      alignItems: "center",
                      border: "1px solid black",
                    }}
                    value={countryCode}
                    onChange={(e) => {
                      setCountryCode(e.target.value);
                      //console.log(e.target.value);
                    }}
                  >
                    <option value="" selected disabled hidden>
                      Select Home Country
                    </option>
                    {homeCountryList &&
                      homeCountryList?.map((data, index) => {
                        return (
                          <option
                            value={data.countrycode}
                            label={data.countryfulldesc}
                          >
                            {data.countryfulldesc}
                          </option>
                        );
                      })}
                  </select>
                </div>
              </StyledFormInputDiv>
              {formErrors.countryCode_error && (
                <div className="offset-6 mt-2">
                  <p
                    style={{
                      color: "red",
                      fontSize: ".8rem",
                      marginTop: "0px",
                    }}
                  >
                    {formErrors.countryCode_error}
                  </p>
                </div>
              )}
              <StyledFormInputDiv>
                <div style={FormDivStyle}>
                  <StyledLabel htmlFor="qdesc">Sector</StyledLabel>
                </div>
                <div style={{ width: "100%", alignSelf: "center" }}>
                  <select
                    style={{
                      width: "100%",
                      // marginLeft: "30%",
                      alignItems: "center",
                      border: "1px solid black",
                    }}
                    value={sector}
                    onChange={(e) => {
                      setSector(e.target.value);
                      //console.log(e.target.value);
                    }}
                  >
                    <option value="" selected disabled hidden>
                      Select Sector
                    </option>
                    {sectorsList &&
                      sectorsList?.map((data, index) => {
                        return (
                          <option value={data.sectorid} label={data.sectordesc}>
                            {data.sectorid}
                          </option>
                        );
                      })}
                  </select>
                </div>
              </StyledFormInputDiv>
              {formErrors.sector_error && (
                <div className="offset-6 mt-2">
                  <p
                    style={{
                      color: "red",
                      fontSize: ".8rem",
                      marginTop: "0px",
                    }}
                  >
                    {formErrors.sector_error}
                  </p>
                </div>
              )}
              <StyledFormInputDiv>
                <div style={FormDivStyle}>
                  <StyledLabel htmlFor="qdesc">Account Owner Email</StyledLabel>
                </div>
                <div style={{ width: "100%", alignSelf: "center" }}>
                  <input
                    type="email"
                    style={{
                      width: "100%",
                      alignItems: "center",
                      border: "1px solid black",
                    }}
                    placeholder="Enter Owner Email"
                    value={accountOwnerEmail}
                    onChange={(e) => {
                      setAccountOwnerEmail(e.target.value);
                    }}
                  />
                </div>
              </StyledFormInputDiv>
              {formErrors.accountOwnerEmail_error && (
                <div className="offset-6 mt-2">
                  <p
                    style={{
                      color: "red",
                      fontSize: ".8rem",
                      marginTop: "0px",
                    }}
                  >
                    {formErrors.accountOwnerEmail_error}
                  </p>
                </div>
              )}
              {/* Yes or No */}
              <StyledFormInputDiv>
                <div style={FormDivStyle}>
                  <StyledLabel htmlFor="qdesc">
                    {" "}
                    Auto email due date reminder
                  </StyledLabel>
                </div>
                <div style={{ width: "100%", alignSelf: "center" }}>
                  <select
                    style={{
                      width: "100%",
                      alignItems: "center",
                      border: "1px solid black",
                    }}
                    value={emailReminder}
                    onChange={(e) => {
                      setEmailReminder(e.target.value);
                      //console.log(e.target.value);
                    }}
                  >
                    <option value="" selected disabled hidden>
                      Select
                    </option>
                    <option value="Y">Yes</option>
                    <option value="N">No</option>
                  </select>
                </div>
              </StyledFormInputDiv>
              {formErrors.emailReminder_error && (
                <div className="offset-6 mt-2">
                  <p
                    style={{
                      color: "red",
                      fontSize: ".8rem",
                      marginTop: "0px",
                    }}
                  >
                    {formErrors.emailReminder_error}
                  </p>
                </div>
              )}
              <StyledFormInputDiv>
                <div style={FormDivStyle}>
                  <StyledLabel htmlFor="qdesc">
                    {" "}
                    Auto email topic reminder
                  </StyledLabel>
                </div>
                <div style={{ width: "100%", alignSelf: "center" }}>
                  <select
                    style={{
                      width: "100%",
                      alignItems: "center",
                      border: "1px solid black",
                    }}
                    value={emailTopic}
                    onChange={(e) => {
                      setEmailTopic(e.target.value);
                      //console.log(e.target.value);
                    }}
                  >
                    <option value="" selected disabled hidden>
                      Select
                    </option>
                    <option value="Y">Yes</option>
                    <option value="N">No</option>
                  </select>
                </div>
              </StyledFormInputDiv>
              {formErrors.emailReminder_error && (
                <div className="offset-6 mt-2">
                  <p
                    style={{
                      color: "red",
                      fontSize: ".8rem",
                      marginTop: "0px",
                    }}
                  >
                    {formErrors.emailReminder_error}
                  </p>
                </div>
              )}
              <StyledFormInputDiv>
                <div style={FormDivStyle}>
                  <StyledLabel htmlFor="qdesc">
                    {" "}
                    Auto email welcome to new users
                  </StyledLabel>
                </div>
                <div style={{ width: "100%", alignSelf: "center" }}>
                  <select
                    style={{
                      width: "100%",
                      alignItems: "center",
                      border: "1px solid black",
                    }}
                    value={emailWelcome}
                    onChange={(e) => {
                      setEmailWelcome(e.target.value);
                    }}
                  >
                    <option value="" selected disabled hidden>
                      Select
                    </option>
                    <option value="Y">Yes</option>
                    <option value="N">No</option>
                  </select>
                </div>
              </StyledFormInputDiv>
              {formErrors.emailWelcome_error && (
                <div className="offset-6 mt-2">
                  <p
                    style={{
                      color: "red",
                      fontSize: ".8rem",
                      marginTop: "0px",
                    }}
                  >
                    {formErrors.emailWelcome_error}
                  </p>
                </div>
              )}
              <StyledFormInputDiv>
                <div style={FormDivStyle}>
                  <StyledLabel htmlFor="qdesc">Active </StyledLabel>
                </div>
                <div style={{ width: "100%", alignSelf: "center" }}>
                  <select
                    style={{
                      width: "100%",
                      alignItems: "center",
                      border: "1px solid black",
                    }}
                    value={active}
                    onChange={(e) => {
                      setActive(e.target.value);
                    }}
                  >
                    <option value="" selected disabled hidden>
                      Select
                    </option>
                    <option value="Y">Yes</option>
                    <option value="N">No</option>
                  </select>
                </div>
              </StyledFormInputDiv>
              {formErrors.active_error && (
                <div className="offset-6 mt-2">
                  <p
                    style={{
                      color: "red",
                      fontSize: ".8rem",
                      marginTop: "0px",
                    }}
                  >
                    {formErrors.active_error}
                  </p>
                </div>
              )}
              {edit_model == true ? (
                <>
                  <StyledFormInputDiv>
                    <div style={FormDivStyle}>
                      <StyledLabel htmlFor="qdesc">Setup Status </StyledLabel>
                    </div>
                    <div style={{ width: "100%", alignSelf: "center" }}>
                      <select
                        style={{
                          width: "100%",
                          alignItems: "center",
                          border: "1px solid black",
                        }}
                        value={inputstatus}
                        onChange={(e) => {
                          setInputstatus(e.target.value);
                        }}
                      >
                        <option value="" selected disabled hidden>
                          Select
                        </option>
                        <option value="waiting">waiting</option>
                        <option value="ownerlogin">ownerlogin</option>
                      </select>
                    </div>
                  </StyledFormInputDiv>
                  {formErrors.active_error && (
                    <div className="offset-6 mt-2">
                      <p
                        style={{
                          color: "red",
                          fontSize: ".8rem",
                          marginTop: "0px",
                        }}
                      >
                        {formErrors.active_error}
                      </p>
                    </div>
                  )}
                </>
              ) : (
                ""
              )}
              <StyledFormInputDiv>
                <div style={FormDivStyle}>
                  <StyledLabel htmlFor="qdesc">
                    Customer Brand Color
                  </StyledLabel>
                </div>
                <div style={{ width: "100%", alignSelf: "center" }}>
                  <input
                    type="color"
                    style={{
                      width: "100%",
                      alignItems: "center",
                      border: "1px solid black",
                    }}
                    value={brandColor}
                    onChange={(e) => {
                      setBrandColor(e.target.value);
                      //console.log(e.target.value);
                    }}
                  />
                </div>
              </StyledFormInputDiv>
              {/* {formErrors.brandColor_error && (
                  <div className="offset-6 mt-2">
                    <p
                      style={{
                        color: "red",
                        fontSize: ".8rem",
                        marginTop: "0px",
                      }}
                    >
                      {formErrors.brandColor_error}
                    </p>
                  </div>
                )} */}
              <StyledFormInputDiv>
                <div style={FormDivStyle}>
                  <StyledLabel htmlFor="qdesc">
                    Customer Brand Header Color
                  </StyledLabel>
                </div>
                <div style={{ width: "100%", alignSelf: "center" }}>
                  <input
                    type="color"
                    style={{
                      width: "100%",
                      alignItems: "center",
                      border: "1px solid black",
                    }}
                    value={brandHeader}
                    onChange={(e) => {
                      setBrandHeader(e.target.value);
                      //console.log(e.target.value);
                    }}
                  />
                </div>
              </StyledFormInputDiv>
              <StyledFormInputDiv>
                <div style={FormDivStyle}>
                  <StyledLabel htmlFor="qdesc">Customer Brand Icon</StyledLabel>
                </div>
                <div style={{ width: "100%", alignSelf: "center" }}>
                  <input
                    type="file"
                    style={{
                      width: "89px",
                      alignItems: "center",
                      border: "1px solid black",
                    }}
                    onChange={(e) => {
                      FileUpload(e.target.files[0]);
                    }}
                  />
                  {brandIcon != "" && (
                    <img src={BASE_URL + "/uploads/" + brandIcon} width={85} />
                  )}
                </div>
              </StyledFormInputDiv>
              {formErrors.brandIcon_error && (
                <div className="offset-6 mt-2">
                  <p
                    style={{
                      color: "red",
                      fontSize: ".8rem",
                      marginTop: "0px",
                    }}
                  >
                    {formErrors.brandIcon_error}
                  </p>
                </div>
              )}
              <StyledFormInputDiv>
                <div style={FormDivStyle}>
                  <StyledLabel htmlFor="qdesc">
                    {" "}
                    Require 2FA for Admins
                  </StyledLabel>
                </div>
                <div style={{ width: "100%", alignSelf: "center" }}>
                  <select
                    style={{
                      width: "100%",
                      alignItems: "center",
                      border: "1px solid black",
                    }}
                    value={adm2fa}
                    onChange={(e) => {
                      setAdm2fa(e.target.value);
                    }}
                  >
                    <option value="" selected disabled hidden>
                      Select
                    </option>
                    <option value="Y">Yes</option>
                    <option value="N">No</option>
                  </select>
                </div>
              </StyledFormInputDiv>
              {formErrors.adm2fa_error && (
                <div className="offset-6 mt-2">
                  <p
                    style={{
                      color: "red",
                      fontSize: ".8rem",
                      marginTop: "0px",
                    }}
                  >
                    {formErrors.adm2fa_error}
                  </p>
                </div>
              )}
              <StyledFormInputDiv>
                <div style={FormDivStyle}>
                  <StyledLabel htmlFor="qdesc">Enrolled Plan</StyledLabel>
                </div>
                <div style={{ width: "100%", alignSelf: "center" }}>
                  <select
                    style={{
                      width: "100%",
                      // marginLeft: "30%",
                      alignItems: "center",
                      border: "1px solid black",
                    }}
                    value={entrolledPlan}
                    onChange={(e) => {
                      setEntrolledPlan(e.target.value);
                      //console.log(e.target.value);
                    }}
                  >
                    <option value="" selected disabled hidden>
                      Select Enrolled Plan
                    </option>
                    {entrolledPlanList &&
                      entrolledPlanList?.map((data, index) => {
                        return (
                          <option value={data.pymtid} label={data.pymtdesc}>
                            {data.pymtdesc}
                          </option>
                        );
                      })}
                  </select>
                </div>
              </StyledFormInputDiv>
              {formErrors.entrolledPlan_error && (
                <div className="offset-6 mt-2">
                  <p
                    style={{
                      color: "red",
                      fontSize: ".8rem",
                      marginTop: "0px",
                    }}
                  >
                    {formErrors.entrolledPlan_error}
                  </p>
                </div>
              )}
              <StyledFormInputDiv>
                <div style={FormDivStyle}>
                  <StyledLabel htmlFor="qdesc">Payment Schedule</StyledLabel>
                </div>
                {/* <div style={{ width: "100%", alignSelf: "center" }}>
                    <input
                      type="text"
                      style={{
                        width: "100%",
                        alignItems: "center",
                        border: "1px solid black",
                      }}
                      placeholder="Year or Contract"
                      value={paymentSchedule}
                      onChange={(e) => {
                        setPaymentSchedule(e.target.value);
                        //console.log(e.target.value);
                      }}
                    />
                  </div> */}
                <div style={{ width: "100%", alignSelf: "center" }}>
                  <select
                    style={{
                      width: "100%",
                      alignItems: "center",
                      border: "1px solid black",
                    }}
                    value={paymentSchedule}
                    onChange={(e) => {
                      setPaymentSchedule(e.target.value);
                    }}
                  >
                    <option value="" selected disabled hidden>
                      Select
                    </option>
                    <option value="year">year</option>
                    <option value="Contract">Contract</option>
                  </select>
                </div>
              </StyledFormInputDiv>
              {formErrors.paymentSchedule_error && (
                <div className="offset-6 mt-2">
                  <p
                    style={{
                      color: "red",
                      fontSize: ".8rem",
                      marginTop: "0px",
                    }}
                  >
                    {formErrors.paymentSchedule_error}
                  </p>
                </div>
              )}
              <StyledFormInputDiv>
                <div style={FormDivStyle}>
                  <StyledLabel htmlFor="qdesc">Agreement Price</StyledLabel>
                </div>
                <div style={{ width: "100%", alignSelf: "center" }}>
                  <input
                    type="number"
                    style={{
                      width: "100%",
                      alignItems: "center",
                      border: "1px solid black",
                    }}
                    placeholder="Enter Agreement Price"
                    value={agreementPrice}
                    onChange={(e) => {
                      setAgreementPrice(e.target.value);
                      //console.log(e.target.value);
                    }}
                  />
                </div>
              </StyledFormInputDiv>
              {formErrors.agreementPrice_error && (
                <div className="offset-6 mt-2">
                  <p
                    style={{
                      color: "red",
                      fontSize: ".8rem",
                      marginTop: "0px",
                    }}
                  >
                    {formErrors.agreementPrice_error}
                  </p>
                </div>
              )}
              <StyledFormInputDiv>
                <div style={FormDivStyle}>
                  <StyledLabel htmlFor="qdesc">Plan Start Date</StyledLabel>
                </div>
                <div style={{ width: "100%", alignSelf: "center" }}>
                  {/* <input
                    type="text"
                    style={{
                      width: "100%",
                      alignItems: "center",
                      border: "1px solid black",
                    }}
                    placeholder="Enter Plan Start Date"
                    value={planStartDate}
                    onChange={(e) => {
                      setPlanStartDate(e.target.value);
                      //console.log(e.target.value);
                    }}
                
                  // /> */}
                  <TextField
                    style={{
                      width: "100%",
                      alignItems: "center",
                      border: "1px solid black",
                    }}
                    id="date"
                    // inputFormat=" MMM dd yyyy "
                    value={planStartDate}
                    type="date"
                    // defaultValue={planStartDate}
                    // defaultValue="2017-05-24"
                    className={classes.textField}
                    onChange={(e) => {
                      setPlanStartDate(e.target.value);
                      //console.log(e.target.value);
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </div>
              </StyledFormInputDiv>
              {formErrors.planStartDate_error && (
                <div className="offset-6 mt-2">
                  <p
                    style={{
                      color: "red",
                      fontSize: ".8rem",
                      marginTop: "0px",
                    }}
                  >
                    {formErrors.planStartDate_error}
                  </p>
                </div>
              )}
              <StyledFormInputDiv>
                <div style={FormDivStyle}>
                  <StyledLabel htmlFor="qdesc">Plan End Date</StyledLabel>
                </div>
                <div style={{ width: "100%", alignSelf: "center" }}>
                  <TextField
                    style={{
                      width: "100%",
                      alignItems: "center",
                      border: "1px solid black",
                    }}
                    id="date"
                    type="date"
                    value={planEndDate}
                    className={classes.textField}
                    onChange={(e) => {
                      setPlanEndDate(e.target.value);
                      //console.log(e.target.value);
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </div>
              </StyledFormInputDiv>
              {formErrors.planEndDate_error && (
                <div className="offset-6 mt-2">
                  <p
                    style={{
                      color: "red",
                      fontSize: ".8rem",
                      marginTop: "0px",
                    }}
                  >
                    {formErrors.planEndDate_error}
                  </p>
                </div>
              )}
              <StyledFormInputDiv>
                <div style={FormDivStyle}>
                  <StyledLabel htmlFor="qdesc">Currency</StyledLabel>
                </div>
                <div style={{ width: "100%", alignSelf: "center" }}>
                  <select
                    style={{
                      width: "100%",
                      // marginLeft: "30%",
                      alignItems: "center",
                      border: "1px solid black",
                    }}
                    value={currency}
                    onChange={(e) => {
                      setCurrency(e.target.value);
                      //console.log(e.target.value);
                    }}
                  >
                    <option value="" selected disabled hidden>
                      Select Currency
                    </option>
                    {currencyList &&
                      currencyList?.map((data, index) => {
                        return (
                          <option value={data.currency} label={data.currency}>
                            {data.currency}
                          </option>
                        );
                      })}
                  </select>
                </div>
              </StyledFormInputDiv>
              {formErrors.currency_error && (
                <div className="offset-6 mt-2">
                  <p
                    style={{
                      color: "red",
                      fontSize: ".8rem",
                      marginTop: "0px",
                    }}
                  >
                    {formErrors.currency_error}
                  </p>
                </div>
              )}
              <StyledFormInputDiv>
                <div style={FormDivStyle}>
                  <StyledLabel htmlFor="qdesc"> Trial Period</StyledLabel>
                </div>
                <div style={{ width: "100%", alignSelf: "center" }}>
                  <select
                    style={{
                      width: "100%",
                      alignItems: "center",
                      border: "1px solid black",
                    }}
                    value={trialPeriod}
                    onChange={(e) => {
                      setTrialPeriod(e.target.value);
                    }}
                  >
                    <option value="" selected disabled hidden>
                      Select
                    </option>
                    <option value="Y">Yes</option>
                    <option value="N">No</option>
                  </select>
                </div>
              </StyledFormInputDiv>
              {formErrors.trialPeriod_error && (
                <div className="offset-6 mt-2">
                  <p
                    style={{
                      color: "red",
                      fontSize: ".8rem",
                      marginTop: "0px",
                    }}
                  >
                    {formErrors.trialPeriod_error}
                  </p>
                </div>
              )}
              <StyledFormInputDiv>
                <div style={FormDivStyle}>
                  <StyledLabel htmlFor="qdesc">Trial Start Date</StyledLabel>
                </div>
                <div style={{ width: "100%", alignSelf: "center" }}>
                  {/* <input
                    type="text"
                    style={{
                      width: "100%",
                      alignItems: "center",
                      border: "1px solid black",
                    }}
                    placeholder="Enter Trial Start Date"
                    value={trialStartDate}
                    onChange={(e) => {
                      setTrialStartDate(e.target.value);
                      //console.log(e.target.value);
                    }}
                
                  /> */}

                  <TextField
                    style={{
                      width: "100%",
                      alignItems: "center",
                      border: "1px solid black",
                    }}
                    id="date"
                    type="date"
                    value={trialStartDate}
                    className={classes.textField}
                    onChange={(e) => {
                      setTrialStartDate(e.target.value);
                      //console.log(e.target.value);
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </div>
              </StyledFormInputDiv>
              {formErrors.trialStartDate_error && (
                <div className="offset-6 mt-2">
                  <p
                    style={{
                      color: "red",
                      fontSize: ".8rem",
                      marginTop: "0px",
                    }}
                  >
                    {formErrors.trialStartDate_error}
                  </p>
                </div>
              )}
              <StyledFormInputDiv>
                <div style={FormDivStyle}>
                  <StyledLabel htmlFor="qdesc">Trial End Date</StyledLabel>
                </div>
                <div style={{ width: "100%", alignSelf: "center" }}>
                  {/* <input
                    type            = "text"
                    style           = {{
                      width         : "100%",
                      alignItems    : "center",
                      border        : "1px solid black",
                    }}
                    placeholder     = "Enter Trial End Date"
                    value           = {trialEndDate}
                    onChange        = {(e) => {
                      setTrialEndDate(e.target.value);
                      //console.log(e.target.value);
                    }}
                
                  /> */}

                  <TextField
                    style={{
                      width: "100%",
                      alignItems: "center",
                      border: "1px solid black",
                    }}
                    id="date"
                    type="date"
                    value={trialEndDate}
                    className={classes.textField}
                    onChange={(e) => {
                      setTrialEndDate(e.target.value);
                      //console.log(e.target.value);
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </div>
              </StyledFormInputDiv>
              <StyledFormInputDiv>
                <div style={FormDivStyle}>
                  <StyledLabel htmlFor="qdesc"> Plan Status</StyledLabel>
                </div>
                <div style={{ width: "100%", alignSelf: "center" }}>
                  <select
                    style={{
                      width: "100%",
                      alignItems: "center",
                      border: "1px solid black",
                    }}
                    value={planStatus}
                    onChange={(e) => {
                      setPlanStatus(e.target.value);
                    }}
                  >
                    <option value="" selected disabled hidden>
                      Select
                    </option>
                    <option value="Active">Active</option>
                    <option value="Closed">Closed</option>
                  </select>
                </div>
              </StyledFormInputDiv>
              {formErrors.planStatus_error && (
                <div className="offset-6 mt-2">
                  <p
                    style={{
                      color: "red",
                      fontSize: ".8rem",
                      marginTop: "0px",
                    }}
                  >
                    {formErrors.planStatus_error}
                  </p>
                </div>
              )}
              <StyledFormInputDiv>
                <div style={FormDivStyle}>
                  <StyledLabel htmlFor="qdesc">Location Limit</StyledLabel>
                </div>
                <div style={{ width: "100%", alignSelf: "center" }}>
                  <input
                    type="number"
                    style={{
                      width: "100%",
                      alignItems: "center",
                      border: "1px solid black",
                    }}
                    placeholder="Enter Location Limit"
                    value={locationLimit}
                    onChange={(e) => {
                      setLocationLimit(e.target.value);
                      //console.log(e.target.value);
                    }}
                  />
                </div>
              </StyledFormInputDiv>
              {formErrors.locationLimit_error && (
                <div className="offset-6 mt-2">
                  <p
                    style={{
                      color: "red",
                      fontSize: ".8rem",
                      marginTop: "0px",
                    }}
                  >
                    {formErrors.locationLimit_error}
                  </p>
                </div>
              )}
              <StyledFormInputDiv>
                <div style={FormDivStyle}>
                  <StyledLabel htmlFor="qdesc">Admin Limit</StyledLabel>
                </div>
                <div style={{ width: "100%", alignSelf: "center" }}>
                  <input
                    type="number"
                    style={{
                      width: "100%",
                      alignItems: "center",
                      border: "1px solid black",
                    }}
                    placeholder="Enter Admin Limit"
                    value={adminLimit}
                    onChange={(e) => {
                      setAdminLimit(e.target.value);
                      //console.log(e.target.value);
                    }}
                  />
                </div>
              </StyledFormInputDiv>
              {formErrors.adminLimit_error && (
                <div className="offset-6 mt-2">
                  <p
                    style={{
                      color: "red",
                      fontSize: ".8rem",
                      marginTop: "0px",
                    }}
                  >
                    {formErrors.adminLimit_error}
                  </p>
                </div>
              )}
              <StyledFormInputDiv>
                <div style={FormDivStyle}>
                  <StyledLabel htmlFor="qdesc">User Limit</StyledLabel>
                </div>
                <div style={{ width: "100%", alignSelf: "center" }}>
                  <input
                    type="number"
                    style={{
                      width: "100%",
                      alignItems: "center",
                      border: "1px solid black",
                    }}
                    placeholder="Enter User Limit"
                    value={userLimit}
                    onChange={(e) => {
                      setUserLimit(e.target.value);
                      //console.log(e.target.value);
                    }}
                  />
                </div>
              </StyledFormInputDiv>
              {formErrors.userLimit_error && (
                <div className="offset-6 mt-2">
                  <p
                    style={{
                      color: "red",
                      fontSize: ".8rem",
                      marginTop: "0px",
                    }}
                  >
                    {formErrors.userLimit_error}
                  </p>
                </div>
              )}
              <StyledFormInputDiv>
                <div style={FormDivStyle}>
                  <StyledLabel htmlFor="qdesc">Invoice Number</StyledLabel>
                </div>
                <div style={{ width: "100%", alignSelf: "center" }}>
                  <input
                    type="text"
                    style={{
                      width: "100%",
                      alignItems: "center",
                      border: "1px solid black",
                    }}
                    placeholder="Enter Invoice Number"
                    value={invoiceNumber}
                    onChange={(e) => {
                      setInvoiceNumber(e.target.value);
                      //console.log(e.target.value);
                    }}
                  />
                </div>
              </StyledFormInputDiv>
              {/* {formErrors.invoiceNumber_error && (
                  <div className="offset-6 mt-2">
                    <p
                      style={{
                        color: "red",
                        fontSize: ".8rem",
                        marginTop: "0px",
                      }}
                    >
                      {formErrors.invoiceNumber_error}
                    </p>
                  </div>
                )} */}
              <StyledFormInputDiv>
                <div style={FormDivStyle}>
                  <StyledLabel htmlFor="qdesc">Plan Note</StyledLabel>
                </div>
                <div style={{ width: "100%", alignSelf: "center" }}>
                  <input
                    type="text"
                    style={{
                      width: "100%",
                      alignItems: "center",
                      border: "1px solid black",
                    }}
                    placeholder="Enter Plan Note"
                    value={planNote}
                    onChange={(e) => {
                      setPlanNote(e.target.value);
                      //console.log(e.target.value);
                    }}
                  />
                </div>
              </StyledFormInputDiv>
              {/* {formErrors.planNote_error && (
                  <div className="offset-6 mt-2">
                    <p
                      style={{
                        color: "red",
                        fontSize: ".8rem",
                        marginTop: "0px",
                      }}
                    >
                      {formErrors.planNote_error}
                    </p>
                  </div>
                )} */}
              {edit_model == false ? (
                <>
                  <StyledFormInputDiv>
                    <div style={FormDivStyle}>
                      <StyledLabel htmlFor="qdesc">
                        Primary Location Name
                      </StyledLabel>
                    </div>
                    <div style={{ width: "100%", alignSelf: "center" }}>
                      <input
                        type="text"
                        style={{
                          width: "100%",
                          alignItems: "center",
                          border: "1px solid black",
                        }}
                        placeholder="Enter Primary Location Name"
                        value={PLName}
                        onChange={(e) => {
                          setPLName(e.target.value);
                          //console.log(e.target.value);
                        }}
                      />
                    </div>
                  </StyledFormInputDiv>
                  {formErrors.PLName_error && (
                    <div className="offset-6 mt-2">
                      <p
                        style={{
                          color: "red",
                          fontSize: ".8rem",
                          marginTop: "0px",
                        }}
                      >
                        {formErrors.PLName_error}
                      </p>
                    </div>
                  )}
                  <StyledFormInputDiv>
                    <div style={FormDivStyle}>
                      <StyledLabel htmlFor="qdesc">
                        Primary Location Country
                      </StyledLabel>
                    </div>
                    <div style={{ width: "100%", alignSelf: "center" }}>
                      <select
                        style={{
                          width: "100%",
                          // marginLeft: "30%",
                          alignItems: "center",
                          border: "1px solid black",
                        }}
                        value={PLCountry}
                        onChange={(e) => {
                          setPLCountry(e.target.value);
                          //console.log(e.target.value);
                        }}
                      >
                        <option value="" selected disabled hidden>
                          Select Primary Location Country
                        </option>
                        {PLCountryList &&
                          PLCountryList?.map((data, index) => {
                            return (
                              <option
                                value={data.countrycode}
                                label={data.countryfulldesc}
                              >
                                {data.countryfulldesc}
                              </option>
                            );
                          })}
                      </select>
                    </div>
                  </StyledFormInputDiv>
                  {formErrors.PLCountry_error && (
                    <div className="offset-6 mt-2">
                      <p
                        style={{
                          color: "red",
                          fontSize: ".8rem",
                          marginTop: "0px",
                        }}
                      >
                        {formErrors.PLCountry_error}
                      </p>
                    </div>
                  )}
                  <StyledFormInputDiv>
                    <div style={FormDivStyle}>
                      <StyledLabel htmlFor="qdesc">
                        Primary Location Sector
                      </StyledLabel>
                    </div>
                    <div style={{ width: "100%", alignSelf: "center" }}>
                      <select
                        style={{
                          width: "100%",
                          // marginLeft: "30%",
                          alignItems: "center",
                          border: "1px solid black",
                        }}
                        value={PLSector}
                        onChange={(e) => {
                          setPLSector(e.target.value);
                          //console.log(e.target.value);
                        }}
                      >
                        <option value="" selected disabled hidden>
                          Select Primary Location Sector
                        </option>
                        {PLSectorList &&
                          PLSectorList?.map((data, index) => {
                            return (
                              <option
                                value={data.sectorid}
                                label={data.sectordesc}
                              >
                                {data.sectordesc}
                              </option>
                            );
                          })}
                      </select>
                    </div>
                  </StyledFormInputDiv>
                  {formErrors.PLSector_error && (
                    <div className="offset-6 mt-2">
                      <p
                        style={{
                          color: "red",
                          fontSize: ".8rem",
                          marginTop: "0px",
                        }}
                      >
                        {formErrors.PLSector_error}
                      </p>
                    </div>
                  )}
                  <StyledFormInputDiv>
                    <div style={FormDivStyle}>
                      <StyledLabel htmlFor="qdesc">
                        {" "}
                        Primary Location Active
                      </StyledLabel>
                    </div>
                    <div style={{ width: "100%", alignSelf: "center" }}>
                      <select
                        style={{
                          width: "100%",
                          alignItems: "center",
                          border: "1px solid black",
                        }}
                        value={PLActive}
                        onChange={(e) => {
                          setPLActive(e.target.value);
                        }}
                      >
                        <option value="" selected disabled hidden>
                          Select
                        </option>
                        <option value="Y">Yes</option>
                        <option value="N">No</option>
                      </select>
                    </div>
                  </StyledFormInputDiv>
                  {formErrors.PLActive_error && (
                    <div className="offset-6 mt-2">
                      <p
                        style={{
                          color: "red",
                          fontSize: ".8rem",
                          marginTop: "0px",
                        }}
                      >
                        {formErrors.PLActive_error}
                      </p>
                    </div>
                  )}

              <StyledFormInputDiv>
                <div style={FormDivStyle}>
                  <StyledLabel htmlFor="qdesc">Partner Email</StyledLabel>
                </div>
                <div style={{ width: "100%", alignSelf: "center" }}>
                  <input
                    type="text"
                    style={{
                      width: "100%",
                      alignItems: "center",
                      border: "1px solid black",
                    }}
                    placeholder="Enter Partner Email"
                    value={partnerEmail}
                    onChange={(e) => {
                      setPartnerEmail(e.target.value);
                      //console.log(e.target.value);
                    }}
                  />
                </div>
              </StyledFormInputDiv>
              {formErrors.partnerEmail_error && (
                    <div className="offset-6 mt-2">
                      <p
                        style={{
                          color: "red",
                          fontSize: ".8rem",
                          marginTop: "0px",
                        }}
                      >
                        {formErrors.partnerEmail_error}
                      </p>
                    </div>
                  )}
                
                </>
              ) : (
                ""
              )}
              
              <div align="center" >
                {edit_model == false ? (
                  <>
                    <StyledButton3
                      style={{
                        width: "30%",
                        height: 30,
                        marginTop: 50,
                       
                      }}
                      onClick={(e) => {
                        //console.log("save");
                        if (handleValidation()) {
                          AddCustData();
                          //console.log("success");
                          e.preventDefault();
                        } else {
                          e.preventDefault();
                        }
                      }}
                    >
                      Save
                    </StyledButton3>
                  </>
                ) : (
                  <>
                    <StyledButton3
                      style={{
                        width: "30%",
                        height: 30,
                        marginTop: 50,
                        
                      }}
                      onClick={(e) => {
                        //console.log("save");
                        if (handleValidation()) {
                          EditCustData();
                          e.preventDefault();
                        } else {
                          e.preventDefault();
                        }
                      }}
                    >
                      Save Customer Data
                    </StyledButton3>
                  </>
                )}

                <StyledButton3
                  style={{
                    width: "30%",
                    height: 30,
                    backgroundColor: "red",
                    marginLeft: 10,
                   
                  }}
                  onClick={() => {
                    cancel();
                    setAdd_new_customer(false);
                    setEdit_customer(false);
                    // useEffect();
                  }}
                >
                  Cancel
                </StyledButton3>
              </div>
              <br />
            </StyledForm>
          </div>
        </ReactModal>
        <ReactModal className="ModalStyle1 Width90" isOpen={conformModel}>
          <p>
            An active owner email is already assigned. Do you really want to
            assign a new owner to this customer?
          </p>
          <div  align="center">
               
                    <StyledButton1
                      style={{
                        width: "30%",
                        height: 30,
                        marginTop: 50,
                      }}
                      onClick={(e) => {
                       EditUserEmail();
                       Existing_CustList_api();
                       var load = setTimeout(function() {
                        //console.log("redirect");
                        Existing_CustList_api();
                          }, 2000);
                       setConformModel(false);
                       setAdd_new_customer(false);
                    
                      }}
                    >
                      Yes
                 </StyledButton1>
                <StyledButton1
                  style={{
                    width: "30%",
                    height: 30,
                    backgroundColor: "red",
                    marginLeft: 10,
                  }}
                  onClick={() => {
                    setAccountOwnerEmail(originalmail);
                  setConformModel(false);
                  }}
                >
                  No
                </StyledButton1>
              </div>
        </ReactModal>
        {run == "" ? (
          <>
            <div className="text-center">
              <a>
                <h4 style={{ paddingTop: "2%" }}>Support Menu</h4>
              </a>
            </div>

            {/* --------------- ADD Modal ------------ */}
            <div></div>

            <div className="text-center">
              <StyledButton
                onClick={() => {
                  setAdd_new_customer(true);
                  //console.log("add");
                }}
              >
                Add New Customer
              </StyledButton>
            </div>
            <div className="text-center">
              <StyledButton
                onClick={() => {
                  console.log("Edit");
                  Existing_CustList_api();
                  setRun("edit_customer");
                }}
              >
                Edit Existing Customers
              </StyledButton>
            </div>
            
            <div className="text-center">
              <StyledButton
                onClick={() => {
                  // console.log("Customer Batching");
                  history.push("cuzbatching");
                
                }}
              >
                Customer Batching
              </StyledButton>
            </div>
            
            <div className="text-center">
              <StyledButton onClick={() =>{history.push("demosetup");
                
              }}>
                Demo Setup
              </StyledButton>
            </div>
           
           
          </>
        ) : run == "edit_customer" ? (
          <>
            <div className=" mt-3">
              <div align="center">
                <StyledButton2
                  onClick={() => {
                    setRun("");
                  }}
                >
                  Exit
                </StyledButton2>
              </div>
              <div
                className="EXTQ  FilterS"
                style={{
                  width: "100%",
                  marginTop: 20,
                  padding: 20,
                  border: "black solid 1px",
                  borderRadius: 50,
                }}
              >
                <div className="EXTQ">
                  <div className="row filter-row">
                    <div
                      style={{ padding: " 0 10px 4px" }}
                      className="col-md-4 filter-col"
                    >
                      <input
                        style={{ width: "100%" }}
                        placeholder="Filter Customerid"
                        onChange={(e) =>  setFCustomerid(e.target.value)}
                        type="text"
                      />
                    </div>
                    <div
                      style={{ padding: " 0 10px 4px" }}
                      className="col-md-4 filter-col"
                    >
                      <input
                        style={{ width: "100%" }}
                        placeholder="Filter Customer Name"
                        onChange={(e) => setFCustdesc(e.target.value)}
                        type="text"
                      />
                    </div>
                    <div
                      style={{ padding: " 0 10px 4px" }}
                      className="col-md-4 filter-col"
                    >
                      <input
                        style={{ width: "100%" }}
                        placeholder="Filter Countrycode"
                        onChange={(e) => setFCountrycode(e.target.value)}
                        type="text"
                      />
                    </div>

                    <div
                      style={{ padding: " 0 10px 4px" }}
                      className="col-md-4 filter-col"
                    >
                      <input
                        style={{ width: "100%" }}
                        placeholder="Filter Email"
                        onChange={(e) => setFEmail(e.target.value)}
                        type="text"
                      />
                    </div>

                    <div
                      style={{ padding: " 0 10px 4px" }}
                      className="col-md-4 filter-col"
                    >
                      <input
                        style={{ width: "100%" }}
                        placeholder="Filter Setup Status"
                        onChange={(e) => setFstatus(e.target.value)}
                        type="text"
                      />
                    </div>
                    <div
                      style={{ padding: " 0 10px 4px" }}
                      className="col-md-4 filter-col"
                    >
                      <input
                        style={{ width: "100%" }}
                        placeholder="Filter Plan Type ID"
                        onChange={(e) => setFplanid(e.target.value)}
                        type="text"
                      />
                    </div>
                  </div>
                  <div className="col" align="center">
                    <StyledButton1
                      style={{
                        width: "18%",
                        height: 30,
                        marginLeft: 10,
                      }}
                      onClick={filter}
                    >
                      Search
                    </StyledButton1>
                    <StyledButton1
                      style={{
                        width: "18%",
                        height: 30,
                        marginLeft: 10,
                      }}
                      onClick={Existing_CustList_api}
                    >
                      Reset
                    </StyledButton1>
                  </div>
                </div>
              </div>

              <div className="m-4 table-responsive tableFixHead">
              <StyledDiv>
                <StyledTable>
                  <thead>
                    <ScrollTableHeader>
                      <StyledTableHeaderData>S.No</StyledTableHeaderData>
                      <StyledTableHeaderData>
                        {" "}
                        Customer Id
                      </StyledTableHeaderData>
                      <StyledTableHeaderData>
                        Customer Name
                      </StyledTableHeaderData>
                      <StyledTableHeaderData>Countrycode</StyledTableHeaderData>
                      <StyledTableHeaderData>Email</StyledTableHeaderData>
                      <StyledTableHeaderData>
                        Setup Status
                      </StyledTableHeaderData>
                      <StyledTableHeaderData>
                        Plan Type ID
                      </StyledTableHeaderData>
                      <StyledTableHeaderData></StyledTableHeaderData>
                    </ScrollTableHeader>
                  </thead>
                  <tbody>
                  {editDataList &&
                    editDataList?.map((data, index) => {
                      return (
                        <>
                        <StyledTableRow>

                        
                          {/* <tr key={index}> */}
                            <td align="center">{index + 1}</td>
                            <td align="center">{data.customerid}</td>
                            <td align="center">{data.custdesc}</td>
                            <td align="center">{data.countrycode}</td>
                            <td align="center">{data.useremail}</td>
                            <td align="center">{data.inputstatus}</td>
                            <td align="center">{data.pymtid}</td>
                            <td align="center">
                              <a
                                onClick={() => {
                                  console.log("data =>", data.customerid);
                                  setCustomerid(data.customerid);
                                  setUserid(data.userid);
                                  setCustName(data.custdesc);
                                  setCountryCode(data.countrycode);

                                  setSector(data.sectorid);
                                  setAccountOwnerEmail(data.useremail);
                                  setEmailReminder(data.emailsugg);
                                  setEmailTopic(data.emailtopic);
                                  setEmailWelcome(data.emailuser);
                                  setActive(data.activec);
                                  setInputstatus(data.inputstatus);
                                  setBrandColor(data.brandcolor);
                                  setBrandHeader(data.brandheader);
                                  setBrandIcon(data.brandicon);

                                  setEntrolledPlan(data.pymtid);
                                  setPaymentSchedule(data.pymtsched);
                                  setAgreementPrice(data.priceagree);
                                  setPlanStartDate(data.startdate);
                                  console.log("startdate =>", data.startdate);
                                  setPlanEndDate(data.enddate);
                                  setCurrency(data.currency);
                                  setTrialPeriod(data.trialprd);
                                  setTrialStartDate(data.trialstart);
                                  setTrialEndDate(data.trialend);
                                  setPlanStatus(data.planstatus);
                                  setLocationLimit(data.loclimit);
                                  setAdminLimit(data.admlimit);
                                  setUserLimit(data.userlimit);
                                  setInvoiceNumber(data.invoiceid);
                                  setPlanNote(data.plannote);
                                  setPartnerEmail(data.partner_email);
                                  // model open
                                  setAdd_new_customer(true);
                                  setEdit_customer(true);
                                }}
                              >
                                <i
                                  class="fas fa-pencil-alt"
                                  aria-hidden="true"
                                ></i>
                              </a>
                            </td>
              
                          {/* </tr> */}
                          </StyledTableRow>
                        </>
                      );
                    })}
                  </tbody>
                </StyledTable>
                </StyledDiv>
              </div>
            </div>
          </>
        ) : (
          ""
        )}
      </div>
    </>
  );
};
export default Support_Menu;
