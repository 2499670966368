import * as React from 'react';
import { Route, Redirect } from "react-router-dom";
import "./App.css";
import AfterGetStarted from "./pages/AfterGetStarted";
import Priority from "./pages/Priority";
import QuestionTable from "./pages/QuestionTable";
import CuzTopicUI from "./pages/CuzTopicUI";
import Welcome from "./pages/Welcome";
import CuzTopicUIQuestionTable from "./components/CuzTopicUIQuestionTable";
import ExtQuestion from "./pages/extquestion";
import "antd/dist/antd.css";
import Guidance from "./pages/Guidance";
import ReportCategory from "./pages/ReportCategory";
import GetStartedCuzDrive from "./pages/CuzDrive/GetStartedCuzDrive";
import CuzDriveTest from "./pages/CuzDrive/CuzDriveTest";
import TopicDevelopment from "./components/TopicDevelopment";
import Welcome2 from "./pages/curzdrive-phase-2/Welcome2";
import ExtQuestion2 from "./pages/curzdrive-phase-2/Extquestion2";

import Suggestion_report from "./pages/curzdrive-phase-2/Suggestion_report";
import Cuzphase2_test from "./pages/curzdrive-phase-2/Cuzphase2_test";
import Edittopic from "./pages/curzdrive-phase-2/Edittopic";
import Esuggest from "./pages/curzdrive-phase-2/Esuggest";
import ViewCustomerReport from "./pages/curzdrive-phase-2/ViewCustomerReport";
import Ecompleted_report from "./pages/curzdrive-phase-2/Ecompleted_report";
import Topic_History_Cmp_Report from "./pages/curzdrive-phase-2/Topic_History_Cmp_Report";
import ClientFunction from "./pages/curzdrive-phase-2/ClientFunction";
import ManageClient from "./pages/curzdrive-phase-2/Manage_client";
import TopicAssignment from "./pages/curzdrive-phase-2/Topic_Assignment";
import ClientTopicCategories from "./pages/curzdrive-phase-2/Client_Topic_Categories";
import Customer_Data from "./pages/curzdrive-phase-2/Admin Function/Customer_Data";
import Location_List from "./pages/curzdrive-phase-2/Admin Function/Location_List";
import Admin_User from "./pages/curzdrive-phase-2/Admin Function/Admin_User";
import Admin_User_Location from "./pages/curzdrive-phase-2/Admin Function/Admin_User_Location";
import Standard_Topics from "./pages/curzdrive-phase-2/Admin Function/Standard_Topics";
import Topic_Locations from "./pages/curzdrive-phase-2/Admin Function/Topic_Locations";
import User_Topic_Permissions from "./pages/curzdrive-phase-2/Admin Function/User_Topic_Permissions";
import Custom_Topic_Data from "./pages/curzdrive-phase-2/Admin Function/Custom_Topic_Data";
import AI_Assistant from "./pages/curzdrive-phase-2/Admin Function/AI_Assistant";
import AI_Assistant_list from "./pages/curzdrive-phase-2/Admin Function/AI_Assistant_list";
import AI_Assistant_Question_Results_list from "./pages/curzdrive-phase-2/Admin Function/AI_Assistant_Question_Results_list";
import AI_Assistant_Question_Results_list_Edit from "./pages/curzdrive-phase-2/Admin Function/AI_Assistant_Question_Results_list_Edit";
import AdminFunctionModal from "./pages/curzdrive-phase-2/Admin Function/AdminFunctionModal";
import EnrollUI from "./pages/curzdrive-phase-2/Admin Function/EnrollUI";
import SubPaymentProgram from "./pages/curzdrive-phase-2/Admin Function/SubPaymentProgram";
import Partner_Details from "./pages/curzdrive-phase-2/Admin Function/Partner_Details";
import Partner_Access from "./pages/curzdrive-phase-2/Partner_Access";

import Cuz_Login from "./pages/curzdrive-phase-2/Cuz_Login";
import Cuz_Signup from "./pages/curzdrive-phase-2/Cuz_Signup";
import Edit_Profile from "./pages/curzdrive-phase-2/Edit_Profile";
import Topics_Program from "./pages/curzdrive-phase-2/Topics_Program";
import Dashboard from "./pages/curzdrive-phase-2/Dashboard";
import Quiz_Program from "./pages/curzdrive-phase-2/Quiz_Program";
import Suggestion_Program from "./pages/curzdrive-phase-2/Suggestion_Program";
import MainPage from "./pages/curzdrive-phase-2/Payment/MainPage";
import ForgotPassword from "./pages/curzdrive-phase-2/ForgotPassword";
import Header from "./pages/curzdrive-phase-2/Header";
import { message } from "antd";
import ChangePassword from "./pages/curzdrive-phase-2/ChangePassword";
import { useEffect, useState } from "react";
import ReNewPayment from "./pages/curzdrive-phase-2/ReNewPayment";
import GuvrixAdmin from "./pages/GuvrixAdmin";
import Support_Menu from "./pages/Support_menu";
import CuzbatchMenu from "./pages/guvrix-admin/Cuzbatch_menu";
import Cuzbatching from "./pages/guvrix-admin/Cuzbatching";
import Copydemo from "./pages/demo/copydemo";
import Demosetup from "./pages/demo/demosetup";
import Reporting_group from './pages/curzdrive-phase-2/Admin Function/Reporting_group';


message.config({
  top: 60,
});



function App() {
 
   
    
  const [token, setToken] = useState();
  const [value, setvalue] = useState("");
  const setpath = async () => {
    const currentURL = window.location.href // returns the absolute URL of a page
    console.log("currentURL==>",currentURL);
    
const pathname1 = window.location.pathname //returns the current url minus the domain name
console.log("path==>", pathname1);
 if(pathname1 == '/guvrix_admin'){
  console.log("true");
  setvalue("true");
 }else{
  console.log("false");
  setvalue("false");
 }
 console.log("value ==>",value);

// console.log(this.props.location);
// console.log(this.props.match);
// console.log(this.props.history);
  }
  useEffect(() => {
   
    setToken(localStorage.getItem("token"));
    console.log("token==>", localStorage.getItem("token"));
    setpath();
   
  }, []);

  console.log("router")

  return (
    <>
    <div>
      {/* {value == 'false' ? 
      (
        <Header />
      ):("")} */}
   
      <Header />
      <Route path="/" exact>
        {token === null ? (
          <Topics_Program />
        ) : localStorage.getItem("PlanID") == 1 ? (
          <Dashboard />
        ) : (
          <ExtQuestion2 />
        )}
      </Route>

      <Route path="/welcome">
        <Welcome />
      </Route>
      <Route path="/getstarted">
        <AfterGetStarted />
      </Route>
      <Route path="/questiontable">
        <QuestionTable />
      </Route>
      <Route path="/priority">
        <Priority />
      </Route>
      <Route path="/cuztopicUI">
        <CuzTopicUI />
      </Route>
      <Route path="/CuzTopicUIQuestionTable/:id">
        <CuzTopicUIQuestionTable />
      </Route>
      <Route path="/ExtQuestion/:id">
        <ExtQuestion />
      </Route>
      <Route path="/topicdevelopment/:customerid/:id">
        <TopicDevelopment />
      </Route>
      <Route path="/guidance/:id">
        <Guidance />
      </Route>
      <Route path="/viewcustomerreport">
        <ViewCustomerReport />
      </Route>
      <Route path="/report_category/:id">
        <ReportCategory />
      </Route>

      <Route path="/getstartedcuzdrive">
        <GetStartedCuzDrive />
      </Route>

      <Route path="/test/:topicid/:selection">
        <CuzDriveTest />
      </Route>
      <Route path="/Welcome2">
        <Welcome2 />
      </Route>

      <Route exact path="/Extquestion2">
        <ExtQuestion2 />
      </Route>

      <Route path="/partner_access/:customerid" component={Partner_Access}>
        
      </Route>

      <Route
        path="/clientfunction/en/:selection/:customerid"
        component={ClientFunction}
      >
        {/* <ClientFunction /> */}
      </Route>

      <Route
        path="/clientfunction/mn/:selection/:customerid"
        component={ManageClient}
      ></Route>

      <Route
        path="/clientfunction/ta/:selection/:customerid"
        component={TopicAssignment}
      ></Route>

      <Route
        path="/clientfunction/ctc/:selection/:customerid"
        component={ClientTopicCategories}
      ></Route>

      <Route
        path="/adminfunction/customer_data/:customerid"
        component={Customer_Data}
      ></Route>

      <Route
        path="/adminfunction/location-list/:customerid"
        component={Location_List}
      ></Route>

<Route
        path="/reporting_group/:customerid"
        component={Reporting_group}
      ></Route>

      <Route
        path="/adminfunction/admin_user/:customerid"
        component={Admin_User}
      ></Route>

      <Route
        path="/adminfunction/standard_topics/:customerid"
        component={Standard_Topics}
      ></Route>

      <Route
        path="/adminfunction/admin_user_locations/:customerid"
        component={Admin_User_Location}
      ></Route>

      <Route
        path="/adminfunction/topic_location/:customerid"
        component={Topic_Locations}
      ></Route>

      <Route
        path="/adminfunction/user_topic_permissions/:customerid"
        component={User_Topic_Permissions}
      ></Route>

      <Route
        path="/adminfunction/custom_topic_data/:customerid"
        component={Custom_Topic_Data}
      ></Route>

<Route
        path="/adminfunction/ai_assistant/:customerid"
        component={AI_Assistant}
      ></Route>

<Route
        path="/adminfunction/ai_result_list/:customerid"
        component={AI_Assistant_list}
      ></Route>
<Route
        path="/adminfunction/ai_result/:Query_Code"
        component={AI_Assistant_Question_Results_list}
      ></Route>
      <Route
        path="/adminfunction/edit_ai_result/:Query_Code/:QR_Code"
        component={AI_Assistant_Question_Results_list_Edit}
      ></Route>
      <Route
        path="/adminfunction/partner_details/:customerid"
        component={Partner_Details}
      ></Route>

      
      
      <Route path="/adminfunctionmodal" component={AdminFunctionModal}></Route>

      <Route path="/enroll_ui" component={EnrollUI}></Route>

      <Route path="/login" component={Cuz_Login}></Route>


      <Route path="/renew_payment" component={ReNewPayment}></Route>

      <Route
        path="/cuzdrive/public/api/verify/:token"
        component={ForgotPassword}
      ></Route>

      <Route path="/signup" component={Cuz_Signup}></Route>

      <Route path="/edit_profile" component={Edit_Profile}></Route>

      <Route path="/change_password" component={ChangePassword}></Route>

      <Route path="/topics_program" component={Topics_Program}></Route>

      <Route path="/dashboard" component={Dashboard}></Route>

      <Route path="/freetest/:topicid/:selection">
        <Quiz_Program />
      </Route>

      <Route
        path="/suggestion_program/:topicid"
        component={Suggestion_Program}
      ></Route>

      <Route path="/subpymt_program" component={SubPaymentProgram}></Route>

      <Route path="/payment" component={MainPage}></Route>

      <Route path="/Extquestion2/:back" component={ExtQuestion2}></Route>

      <Route
        path="/start_test/:quizmsg"
        component={Cuzphase2_test}
      />
      <Route exact path="/suggestion_report" component={Suggestion_report} />
      <Route exact path="/ecompleted_report" component={Ecompleted_report} />
      <Route
        exact
        path="/Topic_History_Cmp_Report"
        component={Topic_History_Cmp_Report}
      />
      <Route exact path="/edittopic" component={Edittopic} />
      <Route
        exact
        path="/esuggest"
        component={Esuggest}
      />

          <Route path="/guvrix_admin" component={GuvrixAdmin}></Route>
          <Route path="/support_menu" component={Support_Menu}></Route>
          <Route path="/cuzbatching" component={Cuzbatching}></Route> 
          <Route path="/cuzbatch_menu" component={CuzbatchMenu}></Route> 
          
          <Route path="/demosetup" component={Demosetup}></Route>
          <Route path="/copydemo" component={Copydemo}></Route>
    </div>
    </>
  );
}

export default App;
