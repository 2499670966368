import React, { useState, useEffect } from "react";
import axios from "axios";
import ReactModal from "react-modal";
import { useHistory } from "react-router-dom";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import { message } from "antd";
import { DataGrid, GridColumns, GridRowsProp } from "@mui/x-data-grid";
import { makeStyles } from "@material-ui/styles";
import { Tooltip } from "antd";
import {getCustomerPartner_api} from "../../services/PartnerAccessApi";
import { BASE_URL } from "../../url/baseurl";
const theme = localStorage.getItem("brandColor");
const StyledForm = styled.form`
  display: flex;
  justify-content: center;
  flex-direction: column;
`;

const StyledLabel = styled.label`
  flex: 1;
  margin-right: 10px;
  display: inline;
  width: 30%;
`;

const StyledFormInputDiv = styled.div`
  display: flex;
  margin-top: 4px;
  width: 100%;
`;

const FormDivStyle3 = {
  position: "relative",
  display: "inline",
  width: "100%",
  textAlign: "left",
};

const FormDivStyle = {
  position: "relative",
  display: "inline",
  width: "30%",
};

const FormDivStyleTwo = {
  position: "relative",
  display: "inline",
  width: "70%",
};

const InputStyle = {
  width: "125%",
  marginLeft: "-20%",
  alignItems: "center",
  border: "1px solid black",
  backgroundColor: "#EEEEEE",
};

const StyledInput = styled.input`
  display: inline;
  width: 100%;

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;

const StyledButton = styled.button`
  background: ${theme};
  width: 100%;
  border-radius: 30px;
  height: 45px;
  text-align: center;
  color: white;
  font-size: 16px !important;
  font-weight: 500;
  border-color: none;
  border: none;
  margin-top: 25px;
  padding: 5px 10px;
`;

const StyledTable = styled.table`
  width: 100%;
`;

const StyledTableHeader = styled.tr`
  background: ${theme};
  font-weight: bold;
  color: #ffffff;
  font-family: Nunito;
  font-style: normal;
`;

const StyledTableHeaderData = styled.th`
  font-weight: bold;
  padding 5px;
  font-family: Nunito;
  font-style: normal;
`;

const ScrollTableHeader = styled.tr`
  background: ${theme};
  font-weight: bold;
  color: #ffffff;
  font-family: Nunito;
  font-style: normal;
  position: sticky;
  top: 0;
`;
const StyledTableRow = styled.tr`
  border-bottom: 1px solid #9d9d9d;
`;

const StyledButton1 = styled.button`
  background: ${theme};
  width: 150px;
  border-radius: 20px;
  height: 35px;
  text-align: center;
  font-size: 18px !important;

  color: white;
  font-weight: 500;
  border-color: none;
  border: none;
  margin: 0px 15px;
  padding: 5px 10px;
`;

const StyledButtonE = styled.button`
  background: ${theme};
  width: 200px;
  border-radius: 30px;
  height: 45px;
  text-align: center;
  color: white;
  font-size: 16px !important;
  font-weight: 500;
  border-color: none;
  border: none;
  margin-top: 15px;
  padding: 5px 10px;
`;

const useStyles = makeStyles({
  root: {
    height: "400px",
    "& .super-app-theme--header": {
      backgroundColor: "#ff8c24",
      border: "1px solid #ff8c24",
      color: "#FFFFFF",
      fontWeight: "600",
    },
  },
});

const Partner_Access = ({match}) => {
    const customerid = useParams();
    const [partnerDetails, setPartnerDetails] = useState([])
    const [pageSize, setPageSize] = useState(100);
    const classes = useStyles();
    const history = useHistory();
    const [userEmail, setUserEmail] = useState("");
    useEffect(() => {
        getCustomerPartner_api_func(customerid);
      }, []);
    const getCustomerPartner_api_func = async () =>{
        try{
           let useremail = localStorage.getItem("useremail");
           setUserEmail(useremail);
           console.log("userEmail",useremail);
           let res = await getCustomerPartner_api(customerid.customerid, useremail)
           if(res.data.code == 200){
            console.log("getCustomerPartner",res.data);
            setPartnerDetails(res.data.result);
           }
        }
        catch(err){
            console.log("Error : getCustomerPartner_api_func => ",err)
        }
    }

    const columnsUsers: GridColumns = [
      {
        field: "custdesc",
        headerName: "Partner Account",
        flex: 1,
        align: "center",
        headerAlign: "center",
        headerClassName: "super-app-theme--header",
        renderCell: (params) => {
          return <Tooltip title={params.value}>{params.value}</Tooltip>;
        },
      },
      {
        field: "Access",
        headerName: "Access",
        flex: 0.5,
        align: "center",
        headerClassName: "super-app-theme--header",
        headerAlign: "center",
        renderCell: (params) => {
          return (
            <StyledButton1
              onClick={async () => {
                try {
                  // Save original values with prefix "original_"
                  [
                    "UserID",
                    "CrID",
                    "EmailUser",
                    "PlanID",
                    "brandIcon",
                    "brandColor",
                    "brandHeader",
                    "token",
                    "CustData",
                  ].forEach((key) => {
                    const value = localStorage.getItem(key);
                    if (value !== null) {
                      localStorage.setItem(`original_${key}`, value);
                    }
                  });
    
                  // Call the API
                  const response = await axios.get(`${BASE_URL}/api/partner_access_login`, {
                    params: {
                      useremail: params.row.useremail,
                      orginalemail : localStorage.getItem("useremail")
                    },
                  });
    
                  if (response.data.code === 200) {
                    const res = response.data;

                    // Save new values in local storage
                    localStorage.setItem("UserID", res.new_record[0].userid);
                    localStorage.setItem("CrID", res.userdata[0].customerid);
                    localStorage.setItem("EmailUser", res.new_record[0].email);
                    localStorage.setItem("PlanID", res.userdata[0].selpymtid);
                    localStorage.setItem("brandIcon", res.userdata[0].brandicon);
                    localStorage.setItem("brandColor", res.userdata[0].brandcolor);
                    localStorage.setItem("brandHeader", res.userdata[0].brandheader);
                    localStorage.setItem("token", res.token);
                    localStorage.setItem("CustData", JSON.stringify(res.new_record[0]));
    
                    // Save partner details
                    localStorage.setItem("partner", "y");
                    localStorage.setItem("partner_userid", params.row.owner);
                    localStorage.setItem("partner_useremail", params.row.useremail);
    
                    // history.push("/Extquestion2");
                    window.location.href = "/Extquestion2";
                  } else {
                    console.error("API Error: ", response.data.message);
                  }
                } catch (error) {
                  console.error("Error calling partner_access API: ", error);
                }
              }}
            >
              Select
            </StyledButton1>
          );
        },
      },
    ];
    
      const rows = partnerDetails.map((data, index) => ({
        ...data,
        SNo: index + 1,
        id: index + 1,
      }));

      return(
        <>
        <div className="container">
        <div align="center">
          <h4 style={{ paddingTop: "3%" }}>Partner Access</h4>
        </div>
        <div
       className="col-sm-12 text-center">
        <StyledButtonE
        onClick={() => {
          history.push(
            `/Extquestion2`
          );
        }}
        >
          Return to Main Menu
        </StyledButtonE>
      </div>
        <div
                  className={classes.root}
                  style={{
                    height: "78vh",
                    width: "100%",
                    marginTop: 30,
                    overflow: "hidden",
                  }}
                >
                  <DataGrid
                    sx={{
                      ".MuiDataGrid-columnHeaders": {
                        backgroundColor: "#ff8c24",
                      },
                      "&.MuiDataGrid-root": {
                        border: "none",
                      },
                    }}
                    rows={rows}
                    columns={columnsUsers}
                    pageSize={pageSize}
                    onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                    rowsPerPageOptions={[100]}
                    pagination
                    hideFooterPagination={rows.length <= pageSize}
                  />
                </div>
        </div>
        </>
      )
    
};

export default Partner_Access;