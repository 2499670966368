import React, { useState, useEffect } from "react";
// import logo from "../../assets/logo.png";
import logo from "../../assets/Guvrix-Logo.png";
import { Navbar, Container, Nav, NavDropdown } from "react-bootstrap";
import { useHistory, useLocation } from "react-router-dom";
import styled from "styled-components";
import ReactModal from "react-modal";
import { message } from "antd";
import {
  cancel_subscription_api,
  subscription_api,
  free_add_custadmin_api
} from "../../services/NewUI_API";
import{logout_partner_access} from "../../services/PartnerAccessApi"
import { BASE_URL } from "../../url/baseurl";
const theme = localStorage.getItem('brandColor');
const theme1 = localStorage.getItem('brandHeader');

const StyledButton = styled.button`
  background: ${theme};
  width: 100%;
  border-radius: 30px;
  height: 45px;
  text-align: center;
  color: white;
  font-size: 16px !important;
  font-weight: 500;
  border-color: none;
  border: none;
  margin-top: 25px;
  padding: 5px 10px;
`;

 
const Header = () => {
  const history = useHistory();
  const location = useLocation();

  const [manage_sub_modal, setManage_sub_modal] = useState(false);

  const [token, setToken] = useState();
  const [brandColor, setBrandColor] = useState('');
  const [brandIcon, setBrandIcon] = useState('');
  const [brandHeader, setBrandHeader] = useState('');
  const [pathValue, setPathValue] = useState('');

  useEffect(() => {
    if(localStorage.getItem("brandColor") == null || localStorage.getItem("brandColor") == '' ){
      localStorage.setItem("brandColor","#ff8c24");  console.log("vasanth==>", );
    }
    if(localStorage.getItem("brandHeader") == null || localStorage.getItem("brandHeader") == ''){
      localStorage.setItem("brandHeader","#212529");
    }
    if(localStorage.getItem("brandIcon") == null || localStorage.getItem("brandIcon") == ''){
      localStorage.setItem("brandIcon","customer/guvrix_logo.png");
    }
    if(localStorage.getItem("customerid") == null || localStorage.getItem("customerid") == ''){
      localStorage.setItem("customerid",null);
    }
    setToken(localStorage.getItem("token"));
    setBrandColor(localStorage.getItem("brandColor"));
    setBrandHeader(localStorage.getItem("brandHeader"));
    setBrandIcon(localStorage.getItem("brandIcon"));
    setPathValue(location.pathname);
    
    //console.log("token==>", localStorage.getItem("token"));
    console.log("brandColor==>", localStorage.getItem("brandColor"));
    //console.log("brandHeader==>", localStorage.getItem("brandHeader"));
    //console.log("brandIcon==>", localStorage.getItem("brandIcon"));
    //console.log("pathname==>", location.pathname);
  }, []);

  const DeleteSub_Function = () => {
    cancel_subscription_api({
      userid: localStorage.getItem("UserID"),
    })
      .then((res) => {
        if (res.data.code === 200) {
          //console.log(res);

          subscription_api({
            userid: localStorage.getItem("UserID"),
            subscription: res.data.subscription,
            customer: JSON.parse(localStorage.getItem("CustData")),
            plan_id: localStorage.getItem("PlanID"),
          })
            .then((res1) => {
              if (res1.data.code == 200) {
                //console.log(res1);
                setManage_sub_modal(false);
                message.success("Subscription deleted successfully");
                localStorage.clear()
                window.location.href = "/login"
              }
            })
            .catch((err1) => {
              //console.log(err1);
            });
        } else {
          message.error(res.data.message);
        }
      })
      .catch((err) => {
        //console.log(err);
      });
  };

  const logout_partner_access_api_fun = async () => {
    let userId = localStorage.getItem("UserID");
    await logout_partner_access({userid: userId})
    .then((res) => {
        console.log("partner loggout");
    })
    .catch((err) => {
        console.log("Error: logout_partner_access_api_fun =>", err)
    })
  }

  // const UpgradeFreePlan = async () => {
  //   let data = {
  //     userid: parseInt(localStorage.getItem("E_Userid"))
  //     ||
  //     parseInt(localStorage.getItem("UserID"))
  //   }
  //   free_add_custadmin_api(data)
  //   .then((res) => {
  //     //console.log(res);
  //     if (res.data.code === 200) {
  //       history.push("/enroll_ui")
  //     }
  //   })
  //   .catch((err) => {
  //     //console.log(err);
  //   })
  // }
  //console.log(brandColor, "brandColor")
  //console.log(brandHeader,"brandHeader")

  return (
    <>
    {brandColor == '' || !brandColor ?
      <Navbar bg="dark"   sticky="top" expand="lg">
        <Container>
          <Navbar.Brand>
            <img src={logo} width="30" height="40" className="" alt="Logo" />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse
            className="justify-content-end"
            id="basic-navbar-nav"
          >
            <Nav id="Nav_Header" hidden={token == null || location.pathname == "/login" || location.pathname == "/guvrix_admin" ? true : false}>
              <NavDropdown  hidden={ location.pathname == "/Support_menu" || location.pathname == "/cuzbatching" ? true : false}
                title="Profile"
                id="basic-nav-dropdown"
                style={{ color: "white", fontWeight: "bold", fontSize: 15 }}
              >
                <NavDropdown.Item
                  className="Active"
                  onClick={() => history.push("/edit_profile")}
                >
                  Update Profile
                </NavDropdown.Item>
                <NavDropdown.Item
                  className="Active"
                  onClick={() => history.push("/change_password")}
                >
                  Change Password
                </NavDropdown.Item>
                <NavDropdown.Item
                  hidden={localStorage.getItem("PlanID") != 1 ? true : false}
                  style={{ background: "#ff8c24", color: "#fff" }}
                  onClick={() => history.push("enroll_ui")}
                  // onClick={() => UpgradeFreePlan()}
                >
                  Upgrade to a Subscription
                </NavDropdown.Item>
              </NavDropdown>
              {/* <Nav.Link
                className="Active"
                style={{ color: "white", fontWeight: "bold", fontSize: 15 }}
                onClick={() => {
                  setManage_sub_modal(true);
                }}
                hidden={localStorage.getItem("PlanID") == 1 ? true : false}
              >
                Delete Subscription
              </Nav.Link> */}
                 {token !== null && pathValue == "/Support_menu" || pathValue == "/cuzbatching" ? (
               
               <Nav.Link
                 className="Active"
                 style={{ color: "white", fontWeight: "bold", fontSize: 15 }}
                 onClick={() => {
                   localStorage.clear();
                   window.location.href = "/guvrix_admin";
                 }}
               >
                 Logout
               </Nav.Link>
             ) : (
               <Nav.Link
               className="Active"
               style={{ color: "white", fontWeight: "bold", fontSize: 15 }}
               
               onClick={async () => {
                if (localStorage.getItem("partner") === "y") {

                  await logout_partner_access_api_fun();
                  // Restore original values
                  [
                    "UserID",
                    "CrID",
                    "EmailUser",
                    "PlanID",
                    "brandIcon",
                    "brandColor",
                    "brandHeader",
                    "token",
                    "CustData",
                  ].forEach((key) => {
                    const originalValue = localStorage.getItem(`original_${key}`);
                    if (originalValue !== null) {
                      localStorage.setItem(key, originalValue);
                      localStorage.removeItem(`original_${key}`); // Remove backup after restoring
                    }
                  });
                  
                  // Remove partner-specific values
                  localStorage.removeItem("partner");
                  localStorage.removeItem("partner_userid");
                  localStorage.removeItem("partner_useremail");
                  
                  // Redirect to /Extquestion2
                  window.location.href = "/Extquestion2";
                } else {
                  // Clear all local storage and redirect to login
                  localStorage.clear();
                  window.location.href = "/login";
                }
               }}
             >
               Logout
             </Nav.Link>
             )}
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      :

      <Navbar sticky="top" expand="lg"  style={{background:brandHeader}}>
      <Container>
        <Navbar.Brand>
          <img src={BASE_URL+'/uploads/'+brandIcon} width="30" height="40" className="" alt="Logo" />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse
          className="justify-content-end"
          id="basic-navbar-nav"
        >
          <Nav id="Nav_Header" hidden={token == null || location.pathname == "/login"  || location.pathname == "/guvrix_admin" ? true : false}>
            <NavDropdown hidden={ location.pathname == "/Support_menu" || location.pathname == "/cuzbatching"? true : false}
              title="Profile"
              id="basic-nav-dropdown"
              style={{ color: "white", fontWeight: "bold", fontSize: 15 }}
            >
              <NavDropdown.Item
                className="Active"
                onClick={() => history.push("/edit_profile")}
              >
                Update Profile
              </NavDropdown.Item>
              <NavDropdown.Item
                className="Active"
                onClick={() => history.push("/change_password")}
              >
                Change Password
              </NavDropdown.Item>
              <NavDropdown.Item
                hidden={localStorage.getItem("PlanID") != 1 ? true : false}
                style={{ background: "#ff8c24", color: "#fff" }}
                onClick={() => history.push("enroll_ui")}
                // onClick={() => UpgradeFreePlan()}
              >
                Upgrade to a Subscription
              </NavDropdown.Item>
            </NavDropdown>
            {/* <Nav.Link
              className="Active"
              style={{ color: "white", fontWeight: "bold", fontSize: 15 }}
              onClick={() => {
                setManage_sub_modal(true);
              }}
              hidden={localStorage.getItem("PlanID") == 1 ? true : false}
            >
              Delete Subscription
            </Nav.Link> */}
            {token !== null && pathValue == "/Support_menu" || pathValue == "/cuzbatching" ? (
                   
                   <Nav.Link
                     className="Active"
                     style={{ color: "white", fontWeight: "bold", fontSize: 15 }}
                     onClick={() => {
                       localStorage.clear();
                       window.location.href = "/guvrix_admin";
                     }}
                   >
                     Logout
                   </Nav.Link>
                 ) : (
                   <Nav.Link
                   className="Active"
                   style={{ color: "white", fontWeight: "bold", fontSize: 15 }}
                   onClick={async () => {
                    if (localStorage.getItem("partner") === "y") {

                      await logout_partner_access_api_fun();
                      // Restore original values
                      [
                        "UserID",
                        "CrID",
                        "EmailUser",
                        "PlanID",
                        "brandIcon",
                        "brandColor",
                        "brandHeader",
                        "token",
                        "CustData",
                      ].forEach((key) => {
                        const originalValue = localStorage.getItem(`original_${key}`);
                        if (originalValue !== null) {
                          localStorage.setItem(key, originalValue);
                          localStorage.removeItem(`original_${key}`); // Remove backup after restoring
                        }
                      });
                
                      // Remove partner-specific values
                      localStorage.removeItem("partner");
                      localStorage.removeItem("partner_userid");
                      localStorage.removeItem("partner_useremail");
                      
                      // Redirect to /Extquestion2
                      window.location.href = "/Extquestion2";
                    } else {
                      // Clear all local storage and redirect to login
                      localStorage.clear();
                      window.location.href = "/login";
                    }
                   }}
                 >
                   Logout
                 </Nav.Link>
                 )}
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
       }
    

      {/* --------------- Manage Sub Button Modal ------------ */}
      <ReactModal className="ModalStyle1 Width90" isOpen={manage_sub_modal}>
        <div>
          <div align="center">
            <h4 className="mt-2">
              Are you sure you want to Delete Subscription?
            </h4>
            <StyledButton
              style={{ width: "30%", height: 30, marginTop: 50 }}
              onClick={() => {
                DeleteSub_Function();
              }}
            >
              Delete
            </StyledButton>
            <StyledButton
              style={{
                width: "30%",
                height: 30,
                backgroundColor: "red",
                marginLeft: 10,
              }}
              onClick={() => {
                setManage_sub_modal(false);
              }}
            >
              Cancel
            </StyledButton>
          </div>
        </div>
      </ReactModal>
    </>
  );
};

export default Header;
