import axios from "axios";
import { BASE_URL } from "../url/baseurl";

// ------------------------------ Common API -----------------------------

export const user_session_data_admin_api = async (data) => {
    console.log(data);
    let response = axios.get(`${BASE_URL}/api/user_session_data_admin?userid=${data}`);
    return response;
};

export const feature_program_api = async (data) => {
    console.log(data);
    let response = axios.post(`${BASE_URL}/api/feature_program`, data);
    return response;
};

// ------------------------------ Customer Data API -----------------------------

export const receive_custdata = async ({customerid, userid}) => {
    console.log("c_id, u_id", customerid, userid);
    let response = axios.get(`${BASE_URL}/api/receive_custdata?customerid=${customerid}&userid=${userid}`);
    return response;
};

export const update_custdata = async (data) => {
    console.log(data);
    let response = axios.put(`${BASE_URL}/api/selection_savecust`, data);
    return response;
};

export const receive_homecountry_sectors = async (needlist) => {
    console.log(needlist);
    let response = axios.get(`${BASE_URL}/api/receive_homecountry_sectors?needlist=${needlist}`);
    return response;
};

export const upload_brandIcon = async (data) => {
    console.log(data);
    let response = axios.post(`${BASE_URL}/api/upload_brandIcon`, data);
    return response;
};

// ------------------------------ LocationList API -----------------------------

export const sectionselect_locationlist_api = async (data) => {
    console.log(data);
    let response = axios.get(`${BASE_URL}/api/sectionselect_locationlist?customerid=${data}`);
    return response;
};

export const needlist_loccountry_locsectors_api = async (data) => {
    console.log(data);
    let response = axios.get(`${BASE_URL}/api/needlist_loccountry_locsectors?needlist=${data}`);
    return response;
};

export const add_new_loc = async (data) => {
    console.log(data);
    let response = axios.post(`${BASE_URL}/api/selection_saveloc`, data);
    return response;
};

export const update_location = async (data) => {
    console.log(data);
    let response = axios.get(`${BASE_URL}/api/selection_locchange`, {params: data});
    return response;
};

// ------------------------------ Admin/User API -----------------------------

export const admin_user_data = async (data) => {
    console.log(data);
    let response = axios.post(`${BASE_URL}/api/sectionselect_adminuser`, data);
    return response;
};

export const add_new_admin_user = async (data) => {
    console.log(data);
    let response = axios.post(`${BASE_URL}/api/add_change_delete_admin`, data);
    return response;
};

export const update_change_admin_user = async (data) => {
    console.log(data);
    let response = axios.post(`${BASE_URL}/api/add_change_delete_admin`, data);
    return response;
};

export const delete_admin_user = async (data) => {
    console.log(data);
    let response = axios.post(`${BASE_URL}/api/add_change_delete_admin`, data);
    return response;
};

// ------------------------------ Admin/User Location API -----------------------------

export const admin_user_loc_data = async (data) => {
    console.log(data);
    let response = axios.post(`${BASE_URL}/api/sectionselect_admuserloc`, data);
    return response;
};

export const add_new_loc_list = async (data) => {
    console.log(data);
    let response = axios.post(`${BASE_URL}/api/section_newuserloc`, data);
    return response;
};

export const add_admin_user_location = async (data) => {
    console.log(data);
    let response = axios.post(`${BASE_URL}/api/add_del_adminloc`, data);
    return response;
};

export const delete_admin_user_location = async (data) => {
    console.log(data);
    let response = axios.post(`${BASE_URL}/api/add_del_adminloc`, data);
    return response;
};

// ------------------------------ Custom Topic Data API -----------------------------

export const receive_custom_topic_data = async ({customerid}) => {
    console.log("customer_id", customerid);
    let response = axios.get(`${BASE_URL}/api/sectionselect_customtopic?customerid=${customerid}`);
    return response;
};

export const update_custom_topic_data = async (data) => {
    console.log(data);
    let response = axios.put(`${BASE_URL}/api/selection_changecuztop`, data);
    return response;
};

export const receive_custom_topic_status = async () => {
    let response = axios.get(`${BASE_URL}/api/selection_viewcuzstatus`);
    return response;
};

export const update_custom_topic_status = async (data) => {
    console.log(data);
    let response = axios.put(`${BASE_URL}/api/selection_cuztopchange`, data);
    return response;
};

export const receive_status_info = async () => {
    let response = axios.get(`${BASE_URL}/api/selection_statusinfo2`);
    return response;
};

export const add_new_custom_topic = async (data) => {
    console.log(data);
    let response = axios.put(`${BASE_URL}/api/selection_addcuztop`, data);
    return response;
};

// ---------------------------- Standard Topic api -------------------------

export const sectionselect_stdtopics_api = async (data) => {
    console.log(data);
    let response = axios.get(`${BASE_URL}/api/sectionselect_stdtopics?customerid=${data}`,{customerid:data});
    return response;
};

export const selection_newtopic_api = async (data) => {
    console.log(data);
    let response = axios.post(`${BASE_URL}/api/selection_newtopic`,data);
    return response;
};

export const selection_viewstatus_api = async () => {
  
    let response = axios.get(`${BASE_URL}/api/selection_viewstatus`);
    return response;
};

export const selection_topicstatus_api = async (data) => {
    console.log(data);
    let response = axios.put(`${BASE_URL}/api/selection_topicstatus`,data);
    return response;
};

export const selection_bulk_topicstatus_api = async (data) => {
    console.log(data);
    let response = axios.put(`${BASE_URL}/api/selection_bulk_topicstatus`,data);
    return response;
};

export const selection_statusinfo_api = async () => {
  
    let response = axios.get(`${BASE_URL}/api/selection_statusinfo`);
    return response;
};

// ------------------------Activetoploc apis--------------------

export const sectionselect_topiclocs_api = async (data) => {
    console.log(data);
    let response = axios.get(`${BASE_URL}/api/sectionselect_topiclocs?customerid=${data}`,{customerid:data});
    return response;
};

export const selection_statustoploc_api = async (data) => {
    console.log(data);
    let response = axios.put(`${BASE_URL}/api/selection_statustoploc`,data);
    return response;
};

export const selection_newtoploc_api = async (data) => {
    console.log(data);
    let response = axios.post(`${BASE_URL}/api/selection_newtoploc`,data);
    return response;
};

export const selection_addtopicloc_api = async (data) => {
    console.log(data);
    let response = axios.post(`${BASE_URL}/api/selection_addtopicloc`,data);
    return response;
};

// ---------------------user topic permision api-------------------------------

export const sectionselect_usertopperm_api = async (data) => {
    console.log(data);
    let response = axios.post(`${BASE_URL}/api/sectionselect_usertopperm`,data);
    return response;
};

export const selection_changepriv_api = async (data) => {
    console.log(data);
    let response = axios.put(`${BASE_URL}/api/selection_changepriv`,data);
    return response;
};

export const selection_deleteuser_api = async (data) => {
    console.log(data);
    let response = axios.post(`${BASE_URL}/api/selection_deleteuser`,data);
    return response;
};

export const getAdminPDF_Api = async () => {
    let response = axios.get(`${BASE_URL}/api/getpdf?type=Admin`);
    console.log("getpdfA_api",response)
    return response;
};
export const getClientPDF_Api = async () => {
    let response = axios.get(`${BASE_URL}/api/getpdf?type=Client`);
    console.log("getpdfC_api",response)
    return response;
};

export const getPDF = async (type) => {
    let response = axios.get(`${BASE_URL}/api/getpdf?type=${type}`);
    console.log("getPDF",response)
    return response;
};

export const Add_User_to_LT = async (data) => {
let res = await axios.post(BASE_URL + "/api/selection_addemail", data, {
    headers: { Authorization: "Bearer " + localStorage.getItem("token") },
});
return res;
};

//  support menu
export const receive_newcust_field = async (needlist) => {
    console.log(needlist);
    let response = axios.get(`${BASE_URL}/api/receive_newcust_field?needlist=${needlist}`);
    return response;
};

export const Add_new_cust_data = async (data) => {
    console.log(data);
    let response = axios.put(`${BASE_URL}/api/Add_new_cust_data`, data);
    return response;
};

export const Existing_CustList = async () => {
    console.log("data");
    let response = axios.get(`${BASE_URL}/api/Existing_CustList`);
    return response;
};
export const Edit_Cust_Data = async (data) => {
    console.log(data);
    let response = axios.put(`${BASE_URL}/api/Edit_cust_data`, data);
    return response;
};
export const Edit_UserEmail = async (data) => {
    console.log(data);
    let response = axios.put(`${BASE_URL}/api/Edit_UserEmail`, data);
    return response;
};

export const cuzbatch_filter = async (data) => {
    console.log(data);
    let response = axios.get(`${BASE_URL}/api/cuzbatch_filter`, data);
    return response;
};
//   Add Location 
export const Add_Location_Data = async (data) => {
    console.log(data);
    let response = axios.put(`${BASE_URL}/api/Add_Location_Data`, data);
    return response;
};

export const Get_Topic_Location = async (data) => {
    console.log(data);
    let response = axios.put(`${BASE_URL}/api/Get_Topic_Location`, data);
    return response;
};

export const Get_Location = async (data) => {
    console.log(data);
    let response = axios.put(`${BASE_URL}/api/Get_Location`, data);
    return response;
};

export const Get_Topic_User_Location = async (data) => {
    console.log(data);
    let response = axios.put(`${BASE_URL}/api/Get_Topic_User_Location`, data);
    return response;
};

export const Add_User_Topic_Location_Data = async (data) => {
    console.log(data);
    let response = axios.put(`${BASE_URL}/api/Add_User_Topic_Location_Data`, data);
    return response;
};

export const Add_Topic_Location_Data = async (data) => {
    console.log(data);
    let response = axios.put(`${BASE_URL}/api/Add_Topic_Location_Data`, data);
    return response;
};
export const Assign_Users_Locations = async (data) => {
    console.log(data);
    let response = axios.put(`${BASE_URL}/api/Assign_Users_Locations`, data);
    return response;
};

export const Assign_Users_Locations1 = async (data) => {
    //console.log(data);
    let response = axios.put(`${BASE_URL}/api/Assign_Users_Locations1`, data);
    return response;
};

export const Validate_UserEmail = async (data) => {
    console.log(data);
    let response = axios.put(`${BASE_URL}/api/Validate_UserEmail`, data);
    return response;
};

export const Get_customer_custlocid = async (data) => {
    console.log(data);
    let response = axios.put(`${BASE_URL}/api/Get_customer_custlocid`, data);
    return response;
};

export const Add_Users_Data = async (data) => {
    console.log(data);
    let response = axios.put(`${BASE_URL}/api/Add_Users_Data`, data);
    return response;
};

export const Get_custloc_combinations = async (data) => {
    console.log(data);
    let response = axios.put(`${BASE_URL}/api/Get_custloc_combinations`, data);
    return response;
};


export const Add_User_Location = async (data) => {
    console.log(data);
    let response = axios.put(`${BASE_URL}/api/Add_User_Location`, data);
    return response;
};

export const Demo_weclcome_email = async (data) => {
    console.log(data);
    let response = axios.put(`${BASE_URL}/api/Demo_weclcome_email`, data);
    return response;
};

export const sectionselect_reportinglist_api = async (data) => {
    console.log(data);
    let response = axios.get(`${BASE_URL}/api/sectionselect_reportinglist?customerid=${data}`);
    return response;
};

export const sectionselect_custlocidlist_api = async (data) => {
    console.log(data);
    let response = axios.get(`${BASE_URL}/api/sectionselect_custlocidlist?customerid=${data}`);
    return response;
};

export const add_grouping_api = async (data) => {
    console.log(data);
    let response = axios.post(`${BASE_URL}/api/Add_grouping`,data);
    return response;
};

export const edit_grouping_api = async (data) => {
    console.log(data);
    let response = axios.put(`${BASE_URL}/api/Edit_grouping`,data);
    return response;
};

export const delete_grouping_api = async (data) => {
    console.log(data);
    let response = axios.post(`${BASE_URL}/api/delete_grouping`,data);
    return response;
};

export const Get_customer_Custgroup = async (data) => {
    console.log(data);
    let response = axios.put(`${BASE_URL}/api/Get_customer_Custgroup`, data);
    return response;
};

// ----------------------------------------------------------------------------

// add topics to location

export const report_group_topic_location_api = async ({customerid}) => {
    let response = axios.get(`${BASE_URL}/api/report_group_topic_location_desc?customerid=${customerid}`);
    return response;
}


// ------------------------------ get Ai Templates -----------------------------

export const get_ai_templates_data = async ({customerid}) => {
    console.log("customer_id", customerid);
    let response = axios.get(`${BASE_URL}/api/get_ai_templates?customerid=${customerid}`);
    return response;
};

export const get_ai_templates_parameters = async ({templ_typeid}) => {
    let response = axios.get(`${BASE_URL}/api/get_ai_templates_parameters?templ_typeid=${templ_typeid}`);
    return response;
};

export const update_ai_query_parameters = async (data) => {   
    let response = axios.post(`${BASE_URL}/api/update_ai_query_parameters`, data);
    return response;
};

export const get_Ai_Query_Results = async ({customerid}) => {
    let response = axios.get(`${BASE_URL}/api/get_ai_query_results?customerid=${customerid}`);
    return response;
};

export const get_ai_query_detailed_results = async ({Query_Code}) => {
    let response = axios.get(`${BASE_URL}/api/get_ai_query_detailed_results?Query_Code=${Query_Code}`);
    return response;
};

export const get_AI_Assistant_Question_Results_list_Edit = async ({Query_Code, QR_Code}) => {    
    let response = axios.get(`${BASE_URL}/api/get_AI_Assistant_Question_Results_list_Edit?Query_Code=${Query_Code}&QR_Code=${QR_Code}`);
    return response;
};

export const update_AI_Assistant_Question_Result = async (data) => { 
    console.log(data);   
    let response = axios.put(`${BASE_URL}/api/update_AI_Assistant_Question_Result`,data);
    return response;
};

