import React, { useEffect, useRef, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import styled from "styled-components";
import "../../App.css";
import axios from "axios";
import { BASE_URL } from "../../url/baseurl";
import ReactModal from "react-modal";
import { message } from "antd";
import { DataGrid, GridColumns, GridRowsProp } from "@mui/x-data-grid";
import { makeStyles } from "@material-ui/styles";
import { Tooltip } from "antd";
const theme = localStorage.getItem('brandColor');

const StyledButton = styled.button`
  background: ${theme};
  width: auto;
  border-radius: 20px;
  height: 25px;
  text-align: center;
  color: white;
  padding: 0px 20px;
  font-weight: 500;
  border-color: none;
  border: none;
  margin: 5px;
`;

const ModalStyle = {
  content: {
    width: "400px",
    height: "100px",
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    display: "flex",
    justifyContent: "center",
  },
};

const StyledForm = styled.form`
  display: flex;
  /* justify-content: center; */
  //   margin-left: 20%;
  flex-direction: column;
`;

const StyledButtonE = styled.button`
  background: ${theme};
  width: 200px;
  border-radius: 30px;
  height: 45px;
  text-align: center;
  color: white;
  font-size: 16px !important;
  font-weight: 500;
  border-color: none;
  border: none;
  margin-top: 25px;
  padding: 5px 10px;
`;

const StyledTable = styled.table`
  width: 100%;
`;

const StyledTableHeader = styled.tr`
  background: ${theme};
  font-weight: bold;
  color: #ffffff;
  font-family: Nunito;
  font-style: normal;
  position: sticky;
  top: 0;
`;
const StyledTableRow = styled.tr`
  font-weight: bold;
  font-family: Nunito;
  font-style: normal;
  border-bottom: 1px solid #9d9d9d;
`;

const StyledTableHeaderData = styled.th`
  font-weight: bold;
  padding-left: 5px;
  font-family: Nunito;
  font-style: normal;
`;


const useStyles = makeStyles({
  root: {
    "& .super-app-theme--cell": {
      backgroundColor: "rgba(224, 183, 60, 0.55)",
      color: "#1a3e72",
      fontWeight: "600",
    },
    "& .super-app.negative": {
      backgroundColor: "rgba(157, 255, 118, 0.49)",
      color: "#1a3e72",
      fontWeight: "600",
    },
    "& .super-app.positive": {
      // backgroundColor: '#d47483',
      border: "1px solid #ff8c24",
      color: "#1a3e72",
      fontWeight: "600",
    },
    "& .super-app-theme--header": {
      backgroundColor: "#ff8c24",
      border: "1px solid #ff8c24",
      color: "#FFFFFF",
      fontWeight: "600",
    },
  },
});





function Suggestion_report() {
  let history = useHistory();
  const classes = useStyles();

  const [data1, setData1] = useState();
  const [edit_modal, setEdit_modal] = useState(false);

  const [user_Id, setUser_Id] = useState("");
  const [topic_Id, setTopic_Id] = useState("");
  const [topic_Code, setTopic_Code] = useState("");
  const [cuz_Topic, setCuz_Topic] = useState("");
  const [cuz_Desc, setCuz_Desc] = useState("");
  const [loc_Id, setLoc_Id] = useState("");
  const [loc_Desc, setLoc_Desc] = useState("");
  const [period_Id, setPeriod_Id] = useState("");
  const [period_Desc, setPeriod_Desc] = useState("");
  const [country_Code, setCountry_Code] = useState("");
  const [sugg_priv, setSugg_priv] = useState("");
  const [pageSize, setPageSize] = useState(100);

  const [sugg_msg, setSugg_msg] = useState("");

  const Edit_topic_data = async () => {
    let userid = localStorage.getItem("UserID");
    let res = axios
      .get(`${BASE_URL}/api/user_session_data?userid=${userid}`, {
        userid: localStorage.getItem("UserID"),
      })
      .then((res) => {
        if (res.data.code == 200) {
          localStorage.setItem("customerid", res.data.userdata[0]?.customerid);
          localStorage.setItem("useremail", res.data.userdata[0]?.useremail);
          let P_data = {
            list: res.data.list,
            userdata: res.data.userdata,
            customerid: res.data.userdata[0].customerid,
            useremail: res.data.userdata[0].useremail,
            userid: userid,
          };
          axios
            .post(`${BASE_URL}/api/suggestreport`, P_data)
            .then((res) => {
              console.log("data===>", res.data.suggestion_information);

              setData2_sugg(res.data.suggestion_information);
            })
            .catch((e) => {
              console.log(e);
            });
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  useEffect(() => {
    Edit_topic_data();
  }, []);

  // --------- filter hooks for sugg-------=-=-=-=-=-=-=-=-=-=-==

  const [topic_idsugg, setTopic_idsugg] = useState("");
  const [cuz_descsugg, setCuz_descsugg] = useState("");
  const [filter_locsugg, setFilter_locsugg] = useState("");
  const [filter_countrysugg, setFilter_countrysugg] = useState("");
  const [filter_loc_dessugg, setFilter_loc_dessugg] = useState("");
  const [filter_suggprivilege, setFilter_suggprivilege] = useState("");

  const [data2_sugg, setData2_sugg] = useState([]);
  const [data2_suggfilter, setData2_suggfilter] = useState([]);

  const [sugg_modal, setSugg_modal] = useState(false);

  // -----filter func for sugg------------------------------------------

  const filtersugg = () => {
    let filter_data = [];

    console.log(data2_sugg);

    if (topic_idsugg !== "") {
      filter_data = [
        ...filter_data,
        ...data2_sugg.filter((item) =>
          String(item.topicid)
            .toLowerCase()
            .includes(topic_idsugg?.toLowerCase())
        ),
      ];
    }

    if (cuz_descsugg !== "") {
      filter_data = [
        ...filter_data,
        ...data2_sugg.filter((item) =>
          String(item.cuzdesc)
            .toLowerCase()
            .includes(cuz_descsugg?.toLowerCase())
        ),
      ];
    }

    if (filter_locsugg !== "") {
      filter_data = [
        ...filter_data,
        ...data2_sugg.filter((item) =>
          String(item.locationdesc)
            .toLowerCase()
            .includes(filter_locsugg?.toLowerCase())
        ),
      ];
    }

    if (filter_countrysugg !== "") {
      filter_data = [
        ...filter_data,
        ...data2_sugg.filter((item) =>
          String(item.countrycode)
            .toLowerCase()
            .includes(filter_countrysugg?.toLowerCase())
        ),
      ];
    }

    if (filter_suggprivilege !== "") {
      filter_data = [
        ...filter_data,
        ...data2_sugg.filter((item) =>
          String(item.Suggprivilege)
            .toLowerCase()
            .includes(filter_suggprivilege?.toLowerCase())
        ),
      ];
    }

    if (filter_loc_dessugg !== "") {
      filter_data = [
        ...filter_data,
        ...data2_sugg.filter((item) =>
          String(item.perioddesc)
            .toLowerCase()
            .includes(filter_loc_dessugg?.toLowerCase())
        ),
      ];
    }

    setData2_sugg(filter_data.length !== 0 ? filter_data : data2_suggfilter);
  };

  const submit_res = async (e) => {
    e.preventDefault();
    let data = {
      userid: localStorage.getItem("UserID"),
      topicid: topic_Id,
      topiccodes: topic_Code,
      cuztopic: cuz_Topic,
      cuzdesc: cuz_Desc,
      locationid: loc_Id,
      locationdesc: loc_Desc,
      periodcode: period_Id,
      perioddesc: period_Desc,
      countrycode: country_Code,
      suggprivilege: sugg_priv,
      suggmessage: sugg_msg,
    };
    let res = await axios
      .put(`${BASE_URL}/api/suggestedit`, data)
      .then((res) => {
        if (res.data.code == 200) {
          console.log("data===>", res);
          alert("Updated Sucessfully !");
          setEdit_modal(false);
          Edit_topic_data();
        } else alert("Something Went Wrong!");
      })
      .catch((e) => {
        console.log(e);
      });
  };


  const SelectButton = ({ data}) => {
    const handleSelectClick = () => {
      
      history.push(`/esuggest`);
                        
      localStorage.setItem("R_CuzDesc", data.cuzdesc)
      localStorage.setItem("R_CuzTopic", data.cuztopic)
      localStorage.setItem("R_TopicId", data.topicid)
      localStorage.setItem("R_LocationId", data.locationid)
      localStorage.setItem("R_LocationDesc", data.locationdesc)
      localStorage.setItem("R_TopicCodes", data.topiccodes)
      localStorage.setItem("R_PeriodDesc", data.perioddesc)
      localStorage.setItem("R_PeriodCode", data.periodcode)
      localStorage.setItem("R_CountryCode", data.countrycode)
      localStorage.setItem("R_SuggPrivilege", data.Suggprivilege)
    };

    return (
      <StyledButton onClick={handleSelectClick} style={{ width: 100 }}>
        Select
      </StyledButton>
    );
  };




  let SNo = 1;
  const rowsWithSerialNumber = data2_sugg?.map((data2_sugg) => {
    return {
      ...data2_sugg,
      SNo: SNo++,
    };
  });


  const rows1 = rowsWithSerialNumber;


  const columnsUsers: GridColumns = [

    // {
    //   field: "SNo",
    //   headerName: "S.No",
    //   // minwidth: 200,
    //   flex: 0.2,
    //   // type: "email",
    //   align: "center",
    //   headerAlign: "center",
    //   headerClassName: "super-app-theme--header",
    //   // renderCell: (params) => (
    //   //   <div>{params.value + 1}</div>
    //   // ),
    // },

    {
      field: "cuzdesc",
      headerName: "Topic Description",
      // minwidth: 200,
      flex: 1,
      // type: "email",
      align: "start",
      headerAlign: "center",
      headerClassName: "super-app-theme--header",
      renderCell: (params) => {
        return (
          <Tooltip
           
            title={params.value}
          >
            <div style={{
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
              width: "100%"
            }}>
              {params.value}
            </div>
            {/* {params.value?.slice(0, 12)}... */}
            {/* {params.value?.length > 25
                ? `${params.value.substring(0, 25)}...`
                : params.value} */}
          </Tooltip>
        );
      },

    },
    {
      field: "locationdesc",
      headerName: "Location",
      // width: 200,
      // type: "number",
      minwidth: 200,
      flex: 0.6,
      align: "start",
      headerAlign: "center",
      headerClassName: "super-app-theme--header",
      renderCell: (params) => {
        return (
          <Tooltip
           
            title={params.value}
          >
            <div style={{
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
              width: "100%"
            }}>
              {params.value}
            </div>
            {/* {params.value?.slice(0, 12)}... */}
            {/* {params.value?.length > 25
                ? `${params.value.substring(0, 25)}...`
                : params.value} */}
          </Tooltip>
        );
      },
    },
    {
      field: "countrycode",
      headerName: "Country",
      headerClassName: "super-app-theme--header",
      flex: 0.5,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => {
        return (
          <Tooltip
           
            title={params.value}
          >
            {/* {params.value?.slice(0, 12)}... */}
            {params.value?.length > 6
                ? `${params.value.substring(0, 6)}...`
                : params.value}
          </Tooltip>
        );
      },
    },
    {
      field: "perioddesc",
      headerName: "Period",
      type: "text",
      headerClassName: "super-app-theme--header",
      // width: 200,
      flex: 0.5,

      align: "center",
      headerAlign: "center",
      renderCell: (params) => {
        return (
          <Tooltip
           
            title={params.value}
          >
            {/* {params.value?.slice(0, 12)}... */}
            {params.value?.length > 10
                ? `${params.value.substring(0, 10)}...`
                : params.value}
          </Tooltip>
        );
      },

    },

    {
      field: "Suggprivilege",
      headerName: "Privilege",
      type: "text",
      headerClassName: "super-app-theme--header",
      // width: 200,
      flex: 0.5,

      align: "center",
      headerAlign: "center",
      renderCell: (params) => {
        return (
          <Tooltip
           
            title={params.value}
          >
            {/* {params.value?.slice(0, 12)}... */}
            {params.value?.length > 10
                ? `${params.value.substring(0, 10)}...`
                : params.value}
          </Tooltip>
        );
      },

    },


    {
      field: "Action",
      headerName: " ",
      flex: 0.5,
      align: "center",
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      renderCell: (params) => (
        <div>
          <SelectButton
            data={params.row}
            
          />
          
        </div>
      ),
    },
  ];





  return (
    <div className="container mt-3">
      <div align="center">
        <StyledButtonE
          onClick={() => {
            history.push("/Extquestion2");
          }}
        >
          Exit
        </StyledButtonE>
      </div>

      {/* <div
        className=" EXTQ FilterS"
        style={{
          marginTop: 20,
          padding: 20,
          border: "black solid 1px",
          borderRadius: 50,
        }}
      >
        <div className="row EXTQ">
          <div className="col-lg-2">
            <input
              placeholder="Filter Topic Description"
              onChange={(e) => setCuz_descsugg(e.target.value)}
              type="text"
            />
          </div>
          <div className="col-lg-2">
            <input
              placeholder="Filter Location"
              onChange={(e) => setFilter_locsugg(e.target.value)}
              type="text"
            />
          </div>
          <div className="col-lg-2">
            <input
              placeholder="Filter Country"
              onChange={(e) => setFilter_countrysugg(e.target.value)}
              type="text"
            />
          </div>
          <div className="col-lg-2">
            <input
              placeholder="Filter Period"
              onChange={(e) => setFilter_loc_dessugg(e.target.value)}
              type="text"
            />
          </div>
          <div className="col-lg-2">
            <input
              placeholder="Filter Privilege"
              onChange={(e) => setFilter_suggprivilege(e.target.value)}
              type="text"
            />
          </div>
          <div className="col" align="center">
            <StyledButton
              style={{ padding: 5, width: 80, height: 25 }}
              onClick={filtersugg}
            >
              Search
            </StyledButton>
          </div>
        </div>
      </div> */}
      {/* <div className="m-4 table-responsive tableFixHead">
        <StyledTable>
          <thead>
            <StyledTableHeader>
              <StyledTableHeaderData>S.No</StyledTableHeaderData>

              <StyledTableHeaderData>Topic Description</StyledTableHeaderData>

              <StyledTableHeaderData>Location</StyledTableHeaderData>
              <StyledTableHeaderData>Country</StyledTableHeaderData>
              <StyledTableHeaderData>Period</StyledTableHeaderData>

              <StyledTableHeaderData>Privilege</StyledTableHeaderData>
              <StyledTableHeaderData></StyledTableHeaderData>
            </StyledTableHeader>
          </thead>
          <tbody>
            {data2_sugg?.map((data, index) => {
              return (
                <StyledTableRow>
                  <td>{index + 1}</td>

                  <td>{data.cuzdesc}</td>

                  <td>{data.locationdesc}</td>
                  <td>{data.countrycode}</td>
                  <td>{data.perioddesc}</td>

                  <td>{data.Suggprivilege}</td>

                  <td>
                    <StyledButton
                      onClick={() => {
                        history.push(`/esuggest`);
                        
                        localStorage.setItem("R_CuzDesc", data.cuzdesc)
                        localStorage.setItem("R_CuzTopic", data.cuztopic)
                        localStorage.setItem("R_TopicId", data.topicid)
                        localStorage.setItem("R_LocationId", data.locationid)
                        localStorage.setItem("R_LocationDesc", data.locationdesc)
                        localStorage.setItem("R_TopicCodes", data.topiccodes)
                        localStorage.setItem("R_PeriodDesc", data.perioddesc)
                        localStorage.setItem("R_PeriodCode", data.periodcode)
                        localStorage.setItem("R_CountryCode", data.countrycode)
                        localStorage.setItem("R_SuggPrivilege", data.Suggprivilege)
                      }}
                      style={{ width: 100 }}
                    >
                      Select
                    </StyledButton>
                  </td>
                </StyledTableRow>
              );
            })}
          </tbody>
        </StyledTable>
      </div> */}



<div
        style={{ height: '76vh', width: "100%", marginTop: 15 }}
        className={classes.root}
      >
        <DataGrid
          sx={{
            ".MuiDataGrid-columnHeaders": {
              backgroundColor: "#ff8c24",
            },
            "&.MuiDataGrid-root": {
              border: "none",
            },
          }}
          rows={rows1}
          getRowId={(r) => r.id}
          id="_id"
          columns={columnsUsers}
          // initialState={{

          //   pagination: {
          //     pageSize: 10,
          //   },
          // }}
          pageSize={pageSize}
          onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
          //rowsPerPageOptions={[50, 100]}
          pagination
          hideFooterPagination={rows1.length <= pageSize}
          //  processRowUpdate={processRowUpdate}
          experimentalFeatures={{ newEditingApi: true }}
        />

      </div>


      <ReactModal style={ModalStyle} isOpen={sugg_modal}>
        <StyledForm>
          <div align="center">
            {sugg_priv == "Edit" ? (
              <StyledButton
                style={{
                  width: 200,
                  backgroundColor: theme,
                  color: "white",
                }}
                onClick={() => {
                }}
              >
                Edit
              </StyledButton>
            ) : (
              <StyledButton
                style={{
                  width: 200,
                  backgroundColor: "${theme}",
                  color: "white",
                }}
                onClick={() => {
                  setSugg_msg("suggesview");
                }}
              >
                View
              </StyledButton>
            )}
          </div>
        </StyledForm>
      </ReactModal>
    </div>
  );
}

export default Suggestion_report;
