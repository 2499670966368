import axios from "axios";
import { BASE_URL } from "../url/baseurl";

export const get_Customer_Partner_Details = async ({customerid}) => {
    console.log(customerid);
    let response = axios.get(`${BASE_URL}/api/get_customer_partner_details?customerid=${customerid}`);
    return response;
};

export const add_partner_details_api = async (data) => {
    console.log(data);
    let response = axios.post(`${BASE_URL}/api/add_partner_details`, data);
    return response;
};

export const edit_partner_details_api = async (data) => {
    console.log(data);
    let response = axios.post(`${BASE_URL}/api/edit_partner_details`, data);
    return response;
};

export const delete_partner_details_api = async (data) => {
    console.log(data);
    let response = axios.post(`${BASE_URL}/api/delete_partner_details`, data);
    return response;
};

export const check_partner_access_api = async (userEmail) => {
    console.log("useremail",userEmail);
    let response = axios.get(`${BASE_URL}/api/check_partner_access?useremail=${userEmail}`);
    return response;
};

export const getCustomerPartner_api = async (customerid,userEmail) => {
    console.log("customerid",customerid);
    console.log("useremail",userEmail);
    let response = axios.get(`${BASE_URL}/api/get_customer_partner?customerid=${customerid}&useremail=${userEmail}`);
    return response;
};

export const logout_partner_access = async (data) => {
    console.log("data",data);
    let response = axios.post(`${BASE_URL}/api/logout_partner_access`, data);
    return response;
};