import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import ReCAPTCHA from "react-google-recaptcha";
import { message } from "antd";
import ReactModal from "react-modal";
import styled from "styled-components";
import {
  login_program_api,
  after_successful_login_api,
  custfeature_api,
  user_session_data_access_api,
  forgot_password_api,
  planendcheck_api,
  two_factor_api,
  code_check_api,
} from "../../services/NewUI_API";
import { BASE_URL } from "../../url/baseurl";
import { getPDF } from "../../services/AdminProgramApi";
const theme = localStorage.getItem('brandColor');
const theme1 = localStorage.getItem('brandHeader');
const StyledButton = styled.button`
  background: #ff8c24;
  width: 100%;
  border-radius: 30px;
  height: 45px;
  text-align: center;
  color: white;
  font-size: 16px !important;
  font-weight: 500;
  border-color: none;
  border: none;
  margin-top: 25px;
  padding: 5px 10px;
`;

const Cuz_Login = () => {
  const history = useHistory();

  const [forgot_password_modal, setForgot_password_modal] = useState(false);
  const [renew_subscription_modal, setRenew_subscription_modal] =
    useState(false);
  const [cancel_check_modal, setCancel_check_modal] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [f_email, setF_email] = useState("");
  const [code, setCode] = useState("");
  const [code1, setCode1] = useState("");
  const [twoFa, setTwoFa] = useState("");
  const [message1, setMessage1] = useState("");
  const [hide, setHide] = useState(true);
  const [hide1, setHide1] = useState(false);

  const [formErrors, setFormErrors] = useState([]);

  const [loginPDF, setLoginPDF] = useState("");

  useEffect(() => {
    getLoginPDF();
  }, []);

  const getLoginPDF = async () => {
    getPDF('Login').then((res) => {
      setLoginPDF(res.data.new_record[0].pdf_link);
    })
      .catch((err) => {
        console.log(err);
      });
  };

  const twoFact = () => {
    if (twoFa == "Y") {
      setHide(false);
    } else {
      setHide(true);
    }
  }

  const handleValidation = () => {
    let formErrors = {};
    let formIsValid = true;
    if (!email) {
      formIsValid = false;
      formErrors["email_error"] = "Email is required.";
    } else if (!/\S+@\S+\.\S+/.test(email)) {
      formIsValid = false;
      formErrors["email_error"] = "Invalid Email id.";
    }
    if (!password) {
      formIsValid = false;
      formErrors["password_error"] = "Password is required.";
    }
    // if(twoFa == "Y"){
    //     if (code != code1) {
    //       formIsValid = false;
    //       formErrors["code_error"] = "This Security Code does not match the code sent to your email account.";
    //     }
    //   }
    setFormErrors(formErrors);
    return formIsValid;
  };

// ------- Reset Fields Function --------
const resetFields = () => {
  setCode1("");
  setEmail("");
setPassword("");
}


  const handleValidation1 = () => {
    let formErrors = {};
    let formIsValid = true;
    if (!code1) {
      formIsValid = false;
      formErrors["code_error"] = "Security Code is required.";
    }
   else if (code != code1) {
      formIsValid = false;
      formErrors["code_error"] = "This Security Code does not match the code sent to your email account.";
  }else if(code == code1){
    window.location.href = "/extquestion2";
  }
  setFormErrors(formErrors);
  return formIsValid;
}

  const Login_Function = async () => {
    let data = {
      email: email,
      password: password,
    };

    planendcheck_api(data)
      .then((response) => {
        console.log(response);

        if (
          response?.data?.message ==
          "Your Plan has been expired. Please renew your plan. or Contact to your Contractor"
        ) {
          setRenew_subscription_modal(true);
          localStorage.setItem("Renew_Plan", "Yes");
          localStorage.setItem(
            "Renew_Plan_UserId",
            response?.data?.data?.subuserid
          );
        } else {
          login_program_api(data)
            .then((res) => {
              if (res.data.code == 200) {
                console.log("login res data==>", res.data);
                localStorage.setItem("UserID", res.data.new_record[0].userid);
                localStorage.setItem("CrID", res.data.userdata[0].customerid);
                localStorage.setItem("EmailUser", res.data.new_record[0].email);
                localStorage.setItem("PlanID", res.data.userdata[0].selpymtid);
                localStorage.setItem("brandIcon", res.data.userdata[0].brandicon);
                localStorage.setItem("brandColor", res.data.userdata[0].brandcolor);
                localStorage.setItem("brandHeader", res.data.userdata[0].brandheader);
                localStorage.setItem("token", res.data.token);
                localStorage.setItem(
                  "CustData",
                  JSON.stringify(res.data.new_record[0])
                );
                console.log("Login");

                // return

                if (res?.data?.userdata[0]?.selpymtid == null) {
                  if (response?.data?.data?.webhookid == "canceled") {
                    setCancel_check_modal(true);
                  } else {
                    if (res?.data?.userdata[0]?.selpymtid == 1) {
                      window.location.href = "/dashboard";
                    } else {

                      let data1 = {
                        email: email,
                      };
                      two_factor_api(data1)
                        .then((res1) => {
                          if (res1.data.code == 200) {
                            let twofa = res1.data.user[0]['twofa'];
                            console.log("after login res1 data==>", res1.data);
                            if (twofa == "Y") {
                              setTwoFa("Y");
                              setHide(false);
                            } else {
                              setTwoFa("N");
                              setHide(true);
                            }


                            let data2 = {
                              userid: parseInt(localStorage.getItem("UserID")),
                            };

                            after_successful_login_api(data2)
                              .then((res2) => {
                                if (res2.data.code == 200) {
                                  console.log("after login res2 data==>", res2.data);
                                  if(twofa == "N"){
                                    message.success("Successful");
                                    }

                                  let data3 = {
                                    userid: parseInt(localStorage.getItem("UserID")),
                                  };

                                  custfeature_api(data3)
                                    .then((res3) => {
                                      console.log(res3);
                                      if (res3.data.code === 200) {
                                        console.log(
                                          "cust feature res3 data==>",
                                          res3.data
                                        );

                                        user_session_data_access_api(
                                          parseInt(localStorage.getItem("UserID"))
                                        )
                                          .then((res4) => {
                                            console.log(res4);
                                            if (res4.data.code === 200) {
                                              console.log(
                                                "user session res4 data==>",
                                                res4.data
                                              );
                                              if (twofa == "N") {
                                                window.location.href = "/extquestion2";
                                              } else {
                                                if (twofa == "Y") {
                                                  setHide1(true);
                                                }
                                              }
                                            } else {
                                              message.error(res4.data.message);
                                              console.log(res4.data.message);
                                            }
                                          })
                                          .catch((err) => {
                                            console.log("error usd--->>", err);
                                          });
                                      } else {
                                        message.error(res3.data.message);
                                        console.log(res3.data.message);
                                      }
                                    })
                                    .catch((err) => {
                                      console.log("error cf--->>", err);
                                    });
                                } else if (res2.data.code == 300) {
                                  message.error("Status Updated, Login one more time.");
                                  console.log(res2.data.message);
                                }
                                else {
                                  message.error(res2.data.message);
                                  console.log(res2.data);
                                }
                              })
                              .catch((err) => {
                                console.log("error al--->>", err);
                              });
                          } else {
                            message.error(res1.data.message);
                            console.log(res1.data.message);
                          }
                        })
                        .catch((err) => {
                          console.log("error al--->>", err);
                        });
                    }
                  }
                } else {
                  if (res?.data?.userdata[0]?.selpymtid == 1) {
                    window.location.href = "/dashboard";
                  } else {

                    let data1 = {
                      email: email,
                    };
                    two_factor_api(data1)
                      .then((res1) => {
                        if (res1.data.code == 200) {
                          let twofa = res1.data.user[0]['twofa'];
                          let code11 = res1.data.code2;
                          console.log("after login res1 data==>", res1.data);
                          if (twofa == "Y") {
                            setTwoFa("Y");
                            setHide(false);
                            setCode(code11);
                          } else {
                            setTwoFa("N");
                            setHide(true);
                          }

                          //console.log("code",code);
                          console.log("twoFa", twoFa);
                          let data2 = {
                            userid: parseInt(localStorage.getItem("UserID")),
                          };

                          after_successful_login_api(data2)
                            .then((res2) => {
                              if (res2.data.code == 200) {
                                console.log("after login res2 data==>", res2.data);
                                if(twofa == "N"){
                                message.success("Successful");
                                }
                                let data3 = {
                                  userid: parseInt(localStorage.getItem("UserID")),
                                };

                                custfeature_api(data3)
                                  .then((res3) => {
                                    console.log(res3);
                                    if (res3.data.code === 200) {
                                      console.log(
                                        "cust feature res3 data==>",
                                        res3.data
                                      );

                                      user_session_data_access_api(
                                        parseInt(localStorage.getItem("UserID"))
                                      )
                                        .then((res4) => {
                                          console.log(res4);
                                          if (res4.data.code === 200) {
                                            console.log(
                                              "user session res4 data==>",
                                              res4.data
                                            );
                                            if (twofa == "N") {
                                              window.location.href = "/extquestion2";
                                            } else {
                                              if (twofa == "Y") {
                                                setHide1(true);
                                              }
                                            }
                                          } else {
                                            message.error(res4.data.message);
                                            console.log(res4.data.message);
                                          }
                                        })
                                        .catch((err) => {
                                          console.log("error usd--->>", err);
                                        });
                                    } else {
                                      message.error(res3.data.message);
                                      console.log(res3.data.message);
                                    }
                                  })
                                  .catch((err) => {
                                    console.log("error cf--->>", err);
                                  });
                              } else {
                                message.error(res2.data.message);
                                console.log(res2.data.message);
                              }
                            })
                            .catch((err) => {
                              console.log("error al--->>", err);
                            });
                        } else {
                          message.error(res1.data.message);
                          console.log(res1.data.message);
                        }
                      })
                      .catch((err) => {
                        console.log("error al--->>", err);
                      });
                  }
                }
              } else {
                message.error(res.data.message);
                console.log(res.data.message);
              }
            })
            .catch((err) => {
              console.log("error login===>", err);
            });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };


  const codecheck = () => {
    code_check_api({
      userid: parseInt(localStorage.getItem("UserID")),
      email: email,
      code: code1,
    })
      .then((res) => {
        console.log(res);
        if (res.data.code === 200) {
          message.success(res.data.message);
          window.location.href = "/extquestion2";
        }else if(res.data.code === 400){
          message.error(res.data.message);
        }else if(res.data.code === 350){
          message.error(res.data.message);}
        else if(res.data.code === 300){
          message.error(res.data.message);
          setTimeout(() => {
            setHide1(false);
        }, 3000);
          //window.location.href = "/login";
          resetFields();
          //setHide1(false);
        }
         else {
          message.error("Something went wrong");
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  // function Captcha_F(value) {
  //   console.log("Captcha value:", value);
  // }

  const forgetPasswordF = () => {
    forgot_password_api({
      email: f_email,
    })
      .then((res) => {
        console.log(res);
        if (res.data.code === 200) {
          message.success(
            "Check your spam folder if an email is not received.",
            7
          );
          setForgot_password_modal(false);
        } else {
          message.error("Something went wrong");
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  function myFunction() {
    var x = document.getElementById("myInput");
    if (x.type === "password") {
      x.type = "text";
    } else {
      x.type = "password";
    }
  }

  return (
    <>
      <div hidden={hide1} className="container">
        <div
          className="mt-5"
          style={{ textAlign: "center", fontSize: 20, fontWeight: "bold" }}
        >
          <p>
            Never logged in? &nbsp;
            <a
              style={{ color: "#ff8c24" }}
              onClick={() => {
                history.push("/signup");
              }}
            >
              Sign up
            </a>
          </p>
        </div>
        <div className="text-center">
          <a
            onClick={() => {
              window.open(
                `${BASE_URL}${JSON.parse(
                  loginPDF
                )}`,
                "_blank"
              );
            }}
          >
            <i
              style={{ color: "#ff8c24", fontSize: "25px" }}
              class="fas fa-info-circle"
            ></i>
          </a>
        </div>
        <div
          class="card mx-auto mt-3 Width100 shadow"
          style={{ width: "40%", borderRadius: 20 }}
        >
          <div
            class="card-header pt-3 pb-3 text-center"
            style={{
              background: "#ff8c24",
              color: "white",
              fontSize: 20,
              fontWeight: "bold",
              borderRadius: "20px 20px 0px 0px",
            }}
          >
            <i class="fas fa-sign-in-alt"></i>&nbsp; Sign in
          </div>

          <div class="card-body">
            <div
              class="input-group mb-3"
              style={{ border: "1px solid #ff8c24" }}
            >
              <span
                class="input-group-text"
                style={{ background: "white", border: "none" }}
              >
                <i style={{ color: "#ff8c24" }} class="far fa-envelope"></i>
              </span>
              <input
                type="email"
                class="form-control"
                placeholder="Email"
                style={{ border: "none" }}
                onChange={(e) => {
                  setEmail(e.target.value);

                }}
                value={email}
              />
            </div>
            {formErrors.email_error && (
              <div>
                <p
                  style={{
                    color: "red",
                    fontSize: ".8rem",
                    marginTop: "0px",
                  }}
                >
                  {formErrors.email_error}
                </p>
              </div>
            )}

            <div
              class="input-group mb-3"
              style={{ border: "1px solid #ff8c24" }}
            >
              <span
                class="input-group-text"
                style={{ background: "white", border: "none" }}
              >
                <i style={{ color: "#ff8c24" }} class="fas fa-key"></i>
              </span>
              <input
                type="password"
                id="myInput"
                class="form-control"
                placeholder="Password"
                style={{ border: "none" }}
                onChange={(e) => {
                  setPassword(e.target.value);
                }}
                value={password}
              />
              <span
                class="input-group-text"
                style={{ background: "white", border: "none" }}
              >
                {"|"}&nbsp;
                <a onClick={() => myFunction()}>
                  <i class="fas fa-eye"></i>
                </a>
              </span>
            </div>
            {formErrors.password_error && (
              <div>
                <p
                  style={{
                    color: "red",
                    fontSize: ".8rem",
                    marginTop: "0px",
                  }}
                >
                  {formErrors.password_error}
                </p>
              </div>
            )}

            <div class="form-check mb-3">
              <input
                class="form-check-input"
                type="checkbox"
                value=""
                id="flexCheckDefault"
                style={{ color: "black" }}
              />
              <label class="form-check-label">Remember me</label>
            </div>


            {/* <div className="mb-3">
                        <ReCAPTCHA
                            sitekey="Enter site key"
                            onChange={Captcha_F}
                        />
                        </div> */}
            <div className="text-center">
              <button
                type="button"
                className="mobileButton"
                style={{
                  width: "40%",
                  paddingTop: 5,
                  paddingBottom: 5,
                  color: "#ff8c24",
                  background: "white",
                  fontSize: 20,
                  fontWeight: "bold",
                  borderRadius: "25px",
                  border: "1px solid #ff8c24",
                }}
                onClick={() => {
                  if (handleValidation()) {
                    Login_Function();
                    console.log("twoFa", twoFa)
                    //twoFact();
                  } else {
                    message.info("Please complete all data");
                  }
                }}
              >
                Sign in
              </button>
            </div>
          </div>
        </div>

        <div
          className="mt-3"
          style={{ textAlign: "center", fontSize: 20, fontWeight: "bold" }}
        >
          <a
            onClick={() => {
              setForgot_password_modal(true);
            }}
          >
            Forgot your password?
          </a>
        </div>
      </div>


      <div hidden={!hide1} className="container">
        <div
          class="card mx-auto mt-5 Width100 shadow otpcard"
          style={{ width: "40%", borderRadius: 20 }}
        >
          <div
            class="card-header pt-3 pb-3 text-center"
            style={{
              background: "#ff8c24",
              color: "white",
              fontSize: 20,
              fontWeight: "bold",
              borderRadius: "20px 20px 0px 0px",
            }}
          >
             <div className="verify">
               Verify One-Time Security Code
              </div>
          </div>
          <div class="card-body">
           
             
                 <p className="inst">We've sent a verification code to your Sign in email.</p>
              <div
                class="input-group mb-3"
                style={{ border: "1px solid #ff8c24" }}
              >
                {/* <span
                  class="input-group-text"
                  style={{ background: "white", border: "none" }}
                > */}

                  <input
                  class="form-control"
                  
                  style={{ border: "none" }}
                   placeholder="Security code from email" 
                   onChange={(e) => {
                    setCode1(e.target.value);
                  }}
                  value={code1}
                   />
                {/* </span> */}
              </div>
              {formErrors.code_error && (
              <div>
                <p
                  style={{
                    color: "red",
                    fontSize: ".8rem",
                    marginTop: "0px",
                  }}
                >
                  {formErrors.code_error}
                </p>
              </div>
            )}

              <div className="text-center">
                <button type="button"

                  style={{
                    width: "40%",
                    paddingTop: 5,
                    paddingBottom: 5,
                    color: "#ff8c24",
                  background: "white",
                    fontSize: 20,
                    fontWeight: "bold",
                    borderRadius: "25px",
                    border: "1px solid #ff8c24",
                  }}
                  onClick={() => {
                    //handleValidation1();
                    codecheck();
                    console.log("code",code);
                  }}
                >SUBMIT</button>
              </div>
            </div>
          </div>
        </div>
        
     

      {/* --------------- Recover Password Modal ------------ */}
      <ReactModal
        className="ModalStyle1 Width90"
        isOpen={forgot_password_modal}
      >
        <div>
          <div align="center">
            <h2 class="text-center">Recover Password</h2>
            <div class="form-group row ">
              <div class="col-4 ">
                <label style={{ color: "black", fontSize: "18px" }} for="email">
                  Email
                </label>
              </div>
              <div class="col-8 ">
                <input
                  type="email"
                  style={{ width: "80%", border: "1px solid black" }}
                  placeholder="Enter Email"
                  onChange={(e) => {
                    setF_email(e.target.value);
                  }}
                />
              </div>
            </div>
            <StyledButton
              style={{ width: "30%", height: 30, marginTop: 50 }}
              onClick={() => {
                forgetPasswordF();
              }}
            >
              Proceed
            </StyledButton>
            <StyledButton
              style={{
                width: "30%",
                height: 30,
                backgroundColor: "red",
                marginLeft: 10,
              }}
              onClick={() => {
                setForgot_password_modal(false);
              }}
            >
              Cancel
            </StyledButton>
          </div>
        </div>
      </ReactModal>

      {/* --------------- Renew Subscription Modal ------------ */}
      <ReactModal
        className="ModalStyle1 Width90"
        isOpen={renew_subscription_modal}
      >
        <div>
          <div align="center">
            <h2 class="text-center">
              Your subscription has been cancelled or expired.
               Please 'Renew Plan' to activate the Subscription.
            </h2>

            <StyledButton
              style={{ width: "30%", height: 30, marginTop: 50 }}
              onClick={() => {
                history.push("/renew_payment");
              }}
            >
              Renew Plan
            </StyledButton>
            <StyledButton
              style={{
                width: "30%",
                height: 30,
                backgroundColor: "red",
                marginLeft: 10,
              }}
              onClick={() => {
                setRenew_subscription_modal(false);
              }}
            >
              Cancel
            </StyledButton>
          </div>
        </div>
      </ReactModal>

      {/* --------------- Cancel Check Modal ------------ */}
      <ReactModal className="ModalStyle1 Width90" isOpen={cancel_check_modal}>
        <div>
          <div align="center">
            <h2 class="text-center">
              Your Plan was canceled, Please click on proceed button to
              continue.
            </h2>

            <StyledButton
              style={{ width: "30%", height: 30, marginTop: 50 }}
              onClick={() => {
                history.push("/enroll_ui");
              }}
            >
              Proceed
            </StyledButton>
            <StyledButton
              style={{
                width: "30%",
                height: 30,
                backgroundColor: "red",
                marginLeft: 10,
              }}
              onClick={() => {
                setCancel_check_modal(false);
              }}
            >
              Cancel
            </StyledButton>
          </div>
        </div>
      </ReactModal>
    </>
  );
};

export default Cuz_Login;
