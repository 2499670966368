import React, { useState, useEffect } from "react";
import ReactModal from "react-modal";
import { useHistory } from "react-router-dom";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import Form from "react-bootstrap/Form";
import { message } from "antd";
import { DataGrid, GridColumns, GridRowsProp } from "@mui/x-data-grid";
import { makeStyles } from "@material-ui/styles";
import { Tooltip } from "antd";
import {
  get_Customer_Partner_Details,
  add_partner_details_api,
  edit_partner_details_api,
  delete_partner_details_api,
} from "../../../services/PartnerAccessApi";
import { BASE_URL } from "../../../url/baseurl";
const theme = localStorage.getItem("brandColor");

const StyledForm = styled.form`
  display: flex;
  justify-content: center;
  flex-direction: column;
`;

const StyledLabel = styled.label`
  flex: 1;
  margin-right: 10px;
  display: inline;
  width: 30%;
`;

const StyledFormInputDiv = styled.div`
  display: flex;
  margin-top: 4px;
  width: 100%;
`;

const FormDivStyle3 = {
  position: "relative",
  display: "inline",
  width: "100%",
  textAlign: "left",
};

const FormDivStyle = {
  position: "relative",
  display: "inline",
  width: "30%",
};

const FormDivStyleTwo = {
  position: "relative",
  display: "inline",
  width: "70%",
};

const InputStyle = {
  width: "125%",
  marginLeft: "-20%",
  alignItems: "center",
  border: "1px solid black",
  // backgroundColor: "#EEEEEE",
};

const StyledInput = styled.input`
  display: inline;
  width: 100%;

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;

const StyledButton = styled.button`
  background: ${theme};
  width: 100%;
  border-radius: 30px;
  height: 45px;
  text-align: center;
  color: white;
  font-size: 16px !important;
  font-weight: 500;
  border-color: none;
  border: none;
  margin-top: 25px;
  padding: 5px 10px;
`;

const StyledTable = styled.table`
  width: 100%;
`;

const StyledTableHeader = styled.tr`
  background: ${theme};
  font-weight: bold;
  color: #ffffff;
  font-family: Nunito;
  font-style: normal;
`;

const StyledTableHeaderData = styled.th`
  font-weight: bold;
  padding 5px;
  font-family: Nunito;
  font-style: normal;
`;

const ScrollTableHeader = styled.tr`
  background: ${theme};
  font-weight: bold;
  color: #ffffff;
  font-family: Nunito;
  font-style: normal;
  position: sticky;
  top: 0;
`;
const StyledTableRow = styled.tr`
  border-bottom: 1px solid #9d9d9d;
`;

const StyledButton1 = styled.button`
  background: ${theme};
  width: 250px;
  border-radius: 20px;
  height: 35px;
  text-align: center;
  font-size: 18px !important;

  color: white;
  font-weight: 500;
  border-color: none;
  border: none;
  margin-top: 15px;
  padding: 5px 10px;
`;

const useStyles = makeStyles({
  root: {
    height: "400px",
    "& .super-app-theme--header": {
      backgroundColor: "#ff8c24",
      border: "1px solid #ff8c24",
      color: "#FFFFFF",
      fontWeight: "600",
    },
  },
});

const Partner_Details = ({ match }) => {
  const [partnerDetails, setPartnerDetails] = useState([]);
  const [add_partner_details_modal, setAdd_partner_details_modal] =
    useState(false);
  const [edit_partner_details_modal, setEdit_partner_details_modal] =
    useState(false);
  const [delete_partner_details_modal, setDelete_partner_details_modal] =
    useState(false);
  const [formErrors, setFormErrors] = useState([]);
  const customerid = useParams();
  const history = useHistory();
  const classes = useStyles();
  const [pageSize, setPageSize] = useState(100);
  const [active, setActive] = useState("Y");
  const [partnerID, setPartnerID] = useState("");
  const [partnerEmailError, setPartnerEmailError] = useState("");
  const [partnerEmail, setPartnerEmail] = useState("");
  useEffect(() => {
    getCustomerPartnerDetails(customerid);
  }, []);
  const getCustomerPartnerDetails = async (customerid) => {
    try {
      console.log("customerid", customerid);
      const res = await get_Customer_Partner_Details(customerid);
      if (res.data.code === 200) {
        console.log(res.data);
        setPartnerDetails(res.data.custPartner);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const add_partner_details_api_func = async () => {
    let data = {
      customerid: customerid.customerid,
      partner_email: partnerEmail,
      activecp: active,
    };
    add_partner_details_api(data)
      .then((res) => {
        if (res.data.code == 200 && res.data.response == "Added") {
          console.log("add partner success");
          setAdd_partner_details_modal(false);
          setPartnerEmail("");
          setPartnerEmailError("");
          setActive("Y");
          getCustomerPartnerDetails(customerid);
          message.success(res.data.message);
        } else if (res.data.response == "Exists") {
          console.log("add partner error");
          message.error(res.data.message);
        } 
        // else if (res.data.response == "NoUser") {
        //   message.error(res.data.message);
        // } 
        else if (res.data.response == "partnerExists") {
          message.error(res.data.message);
        } else {
          console.log("Something Wrong");
          message.error(res.data.message);
        }
      })
      .catch((err) => {
        console.log("add_partner_details_api error==>", err);
      });
  };

  const edit_partner_details_api_func = async () => {
    let data = {
      customerid: customerid.customerid,
      partner_email: partnerEmail,
      activecp: active,
    };

    edit_partner_details_api(data)
      .then((res) => {
        if (res.data.code == 200) {
          message.success(res.data.message);
          setEdit_partner_details_modal(false);
          setPartnerEmail("");
          setPartnerEmailError("");
          setActive("Y");
          getCustomerPartnerDetails(customerid);
        } else {
          message.error(res.data.message);
        }
      })
      .catch((err) => {
        console.log("edit_partner_details_api error==>", err);
      });
  };

  const delete_partner_details_api_func = async () => {
    let data = {
      id: partnerID,
      partner_email: partnerEmail,
    };

    delete_partner_details_api(data)
      .then((res) => {
        if (res.data.code == 200) {
          message.success(res.data.message);
          setDelete_partner_details_modal(false);
          setPartnerEmail("");
          setPartnerEmailError("");
          setActive("Y");
          getCustomerPartnerDetails(customerid);
        } else {
          message.error(res.data.message);
        }
      })
      .catch((err) => {
        console.log("delete_partner_details_api error==>", err);
      });
  };

  const ActionButtons = ({ data }) => {
    const handleEdit = () => {
      setActive(data.activecp);
      setPartnerEmail(data.partner_email);
      setEdit_partner_details_modal(true);
    };
    const handleDelete = () => {
      console.log("dataid", data.id);
      setPartnerID(data.id);
      setPartnerEmail(data.partner_email);
      setDelete_partner_details_modal(true);
    };
    return (
      <div className="w-100 d-flex justify-content-around">
        <a onClick={handleEdit}>
          <i className="fas fa-pencil-alt" aria-hidden="true"></i>
        </a>
        <a onClick={handleDelete}>
          <i className="fas fa-trash-alt" aria-hidden="true"></i>
        </a>
      </div>
    );
  };

  const handleValidation = () => {
    let isValid = true;

    if (!partnerEmail) {
      setPartnerEmailError("Email is required.");
      isValid = false;
    } else if (!validateEmail(partnerEmail)) {
      setPartnerEmailError("Must be a valid email format.");
      isValid = false;
    } else {
      setPartnerEmailError("");
    }
    console.log("partnerEmail", partnerEmail);
    console.log("active", active);
    return isValid;
  };

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const columnsUsers: GridColumns = [
    {
      field: "partner_email",
      headerName: "Partner Email",
      flex: 1,
      align: "center",
      headerAlign: "center",
      headerClassName: "super-app-theme--header",
      renderCell: (params) => {
        return <Tooltip title={params.value}>{params.value}</Tooltip>;
      },
    },

    {
      field: "activecp",
      headerName: "Active",
      type: "text",
      headerClassName: "super-app-theme--header",
      flex: 0.5,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => {
        return <Tooltip title={params.value}>{params.value}</Tooltip>;
      },
    },

    {
      field: "Action",
      headerName: "Action",
      flex: 0.5,
      align: "center",
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      renderCell: (params) => <ActionButtons data={params.row} />,
    },
  ];

  const rows = partnerDetails.map((data, index) => ({
    ...data,
    SNo: index + 1,
  }));

  return (
    <>
      <div className="container">
        <div align="center">
          <h4 style={{ paddingTop: "3%" }}>Partner Access</h4>
        </div>
        <div className="mt-4 row text-center" align="center">
          <div className="col-6 Width100">
            <StyledButton1
              onClick={() => {
                history.push("/adminfunctionmodal");
              }}
            >
              Return to Admin Menu
            </StyledButton1>
          </div>
          <div className="col-6 Width100">
            <StyledButton1
              onClick={() => {
                setAdd_partner_details_modal(true);
              }}
            >
              Add Partner
            </StyledButton1>
          </div>
        </div>

        <div>
          {/* --------------- Add Modal ------------ */}
          <ReactModal
            className="ModalStyle1 Width90"
            isOpen={add_partner_details_modal}
          >
            <div>
              {" "}
              <StyledForm>
                <div align="center" className="mb-3">
                  <h4>Add Partner Details</h4>
                </div>
                <StyledFormInputDiv>
                  <div style={FormDivStyle3}>
                    <StyledLabel htmlFor="userid"> Partner Email </StyledLabel>
                  </div>
                  <div style={{ width: "100%", alignSelf: "center" }}>
                    <StyledInput
                      style={InputStyle}
                      type="email"
                      id="userid"
                      value={partnerEmail}
                      onChange={(e) => setPartnerEmail(e.target.value)}
                    />
                  </div>
                </StyledFormInputDiv>
                {partnerEmailError && (
                  <p
                    style={{
                      color: "red",
                      fontSize: ".8rem",
                      textAlign: "center",
                      paddingTop: "10px",
                    }}
                  >
                    {partnerEmailError}
                  </p>
                )}
                <StyledFormInputDiv>
                  <div style={FormDivStyle3} align="center">
                    <StyledLabel htmlFor="qdesc">Active Status</StyledLabel>
                  </div>
                  <div style={{ width: "100%", alignSelf: "center" }}>
                    <select
                      style={{
                        width: "125%",
                        marginLeft: "-20%",
                        alignItems: "center",
                        border: "1px solid black",
                      }}
                      value={active}
                      onChange={(e) => {
                        setActive(e.target.value);
                      }}
                    >
                      <option value="Y">Yes</option>
                      <option value="N">No</option>
                    </select>
                  </div>
                </StyledFormInputDiv>
              </StyledForm>
            </div>
            <div align="center">
              <StyledButton
                style={{
                  width: "50%",
                  height: 30,
                  marginTop: 50,
                }}
                onClick={() => {
                  if (handleValidation()) {
                    console.log("success");
                    add_partner_details_api_func();
                  }
                }}
              >
                Save Partner Details
              </StyledButton>
              <StyledButton
                style={{
                  width: "40%",
                  height: 30,
                  backgroundColor: "red",
                  marginLeft: 10,
                }}
                onClick={() => {
                  setAdd_partner_details_modal(false);
                  setPartnerEmail("");
                  setPartnerEmailError("");
                  setActive("Y");
                  history.push(
                    `/adminfunction/customer_data/${customerid.customerid}`
                  );
                }}
              >
                Cancel
              </StyledButton>
            </div>
          </ReactModal>
        </div>

        <div>
          {/* --------------- Edit Modal ------------ */}
          <ReactModal
            className="ModalStyle1 Width90"
            isOpen={edit_partner_details_modal}
          >
            <div>
              {" "}
              <StyledForm>
                <div align="center" className="mb-3">
                  <h4>Edit Partner Details</h4>
                </div>
                <StyledFormInputDiv>
                  <div style={FormDivStyle3}>
                    <StyledLabel htmlFor="userid"> Partner Email </StyledLabel>
                  </div>
                  <div style={{ width: "100%", alignSelf: "center" }}>
                    <StyledInput
                      style={InputStyle}
                      type="email"
                      id="userid"
                      value={partnerEmail}
                      disabled
                    />
                  </div>
                </StyledFormInputDiv>
                {partnerEmailError && (
                  <p
                    style={{
                      color: "red",
                      fontSize: ".8rem",
                      textAlign: "center",
                      paddingTop: "10px",
                    }}
                  >
                    {partnerEmailError}
                  </p>
                )}
                <StyledFormInputDiv>
                  <div style={FormDivStyle3} align="center">
                    <StyledLabel htmlFor="qdesc">Active Status</StyledLabel>
                  </div>
                  <div style={{ width: "100%", alignSelf: "center" }}>
                    <select
                      style={{
                        width: "125%",
                        marginLeft: "-20%",
                        alignItems: "center",
                        border: "1px solid black",
                      }}
                      value={active}
                      onChange={(e) => {
                        setActive(e.target.value);
                      }}
                    >
                      <option value="Y" selected={active == "Y" ? true : false}>
                        Yes
                      </option>
                      <option value="N" selected={active == "N" ? true : false}>
                        No
                      </option>
                    </select>
                  </div>
                </StyledFormInputDiv>
              </StyledForm>
            </div>
            <div align="center">
              <StyledButton
                style={{
                  width: "50%",
                  height: 30,
                  marginTop: 50,
                }}
                onClick={() => {
                  edit_partner_details_api_func();
                }}
              >
                Save Partner Details
              </StyledButton>
              <StyledButton
                style={{
                  width: "40%",
                  height: 30,
                  backgroundColor: "red",
                  marginLeft: 10,
                }}
                onClick={() => {
                  setEdit_partner_details_modal(false);
                  setPartnerEmail("");
                  setPartnerEmailError("");
                  setActive("Y");
                }}
              >
                Cancel
              </StyledButton>
            </div>
          </ReactModal>
        </div>

        <div>
          {/* --------------- Delete Details Modal ------------ */}
          <ReactModal
            className="ModalStyle2 Width90"
            isOpen={delete_partner_details_modal}
          >
            <div>
              <div align="center" className="mb-3">
                <h4>Are you sure you want to Delete this partner?</h4>
                <StyledButton
                  style={{ width: "30%", height: 30, marginTop: 50 }}
                  onClick={() => {
                    delete_partner_details_api_func();
                  }}
                >
                  Delete Partner
                </StyledButton>
                <StyledButton
                  style={{
                    width: "30%",
                    height: 30,
                    backgroundColor: "red",
                    marginLeft: 10,
                  }}
                  onClick={() => {
                    setDelete_partner_details_modal(false);
                  }}
                >
                  Cancel
                </StyledButton>
              </div>
            </div>
          </ReactModal>
        </div>

        <div
          className={classes.root}
          style={{
            height: "78vh",
            width: "100%",
            marginTop: 30,
            overflow: "hidden",
          }}
        >
          <DataGrid
            sx={{
              ".MuiDataGrid-columnHeaders": {
                backgroundColor: "#ff8c24",
              },
              "&.MuiDataGrid-root": {
                border: "none",
              },
            }}
            rows={rows}
            columns={columnsUsers}
            pageSize={pageSize}
            onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
            rowsPerPageOptions={[100]}
            pagination
            hideFooterPagination={rows.length <= pageSize}
          />
        </div>
      </div>
    </>
  );
};

export default Partner_Details;
