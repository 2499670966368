import axios from "axios";
import { BASE_URL } from '../url/baseurl'

export const cuz_drive_start = async (data) => {
    let resposne = axios.get(`${BASE_URL}/api/adminProgram`, data);
    return resposne;
}

export const check_status_massage = async (data) => {
    let resposne = axios.post(`${BASE_URL}/api/check_test_availablity`, data);
    return resposne;
}

export const delete_test = async (data) => {
    let resposne = axios.post(`${BASE_URL}/api/delete_test`, data);
    return resposne;
}

export const initial_step = async (data) => {
    console.log("initial_step",data);
    let resposne = axios.post(`${BASE_URL}/api/initialStep`, data);
    console.log(resposne,"=======++-+-+-+---+-+-")
    return resposne;
}

export const response_solution = async (data) => {
    console.log(data)
    let resposne = axios.post(`${BASE_URL}/api/response`, data);
    
    return resposne;
}

export const reverse_response = async (data) => {
    console.log(data)
    let resposne = axios.post(`${BASE_URL}/api/revresponse`, data);

    return resposne;
}